import React, { useContext, useEffect, useRef } from "react";
import FlowsProvider from "../../../../context/FlowsContext";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import { validateGroupByRow } from "../../utils";
import { findTypeByColumn, formatRow } from "../../../summary/utils";
import DeleteForever from "../../../icons/DeleteForever";
import Select from "../../../forms/Select";
import AddButton from "../../../buttons/AddButton";
import {
  CATEGORIC_FUNCTIONS,
  NUMERIC_FUNCTIONS,
} from "../../../summary/constants";
import {
  selectMultiStyles,
  selectStyles,
} from "../../../../styles/select.styles";
import { formatColumns } from "../../../fileColumns/utils";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import { useStore } from "../../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
interface GroupByRowSelectsProps {
  columns?: any;
  setMessage?: any;
}
const selector = (store: any) => ({
  groupByRows: store.groupByRows,
  updateGroupByRows: store.updateGroupByRows
});
const GroupByRowSelects = ({ columns, setMessage }: GroupByRowSelectsProps) => {
  const store = useStore(selector, shallow);
  const  groupByRows  = store.groupByRows;

  const columnSelectRef: any = useRef(null);
  const functionSelectRef: any = useRef(null);

  const onColumnChange = () => {
    setFunctionSelectedOption(undefined);
    functionSelectRef.current?.clearValue();
  };

 /* const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionsChangeHandler,
  ] = useSingleSelect(
    () => onColumnChange(),
    () => onColumnChange()
  );*/

  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionsChangeHandler,
  ] = useMultiSelect(
    
  );

  const [
    functionSelectedOption,
    setFunctionSelectedOption,
    functionOptionsChangeHandler,
  ] = useSingleSelect();

  const cleanSelects = () => {
    setColumnSelectedOption(undefined);
    setFunctionSelectedOption(undefined);
    columnSelectRef.current?.clearValue();
    functionSelectRef.current?.clearValue();
  };

  useEffect(() => {
    if (columns?.length === 0) {
      cleanSelects();
    }
  }, [columns]);

  const handleAddGroupByRow = () => {
    if (
      validateGroupByRow(
        columnSelectedOption,
        functionSelectedOption,
        groupByRows,
        setMessage
      )
    ) {
      const newRows = formatRow(columnSelectedOption, functionSelectedOption, true);
      const newGroupByRows = groupByRows ? groupByRows.concat(newRows) : newRows;
      store.updateGroupByRows(newGroupByRows)
      setMessage("");
      cleanSelects();
    }
  };

  const handleDeleteAllRows = () => {
    store.updateGroupByRows([])
    setMessage("");
    cleanSelects();
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <DeleteForever
            tooltipPlacement="bottom"
            onClick={handleDeleteAllRows}
          />
        </Stack>
      </Grid>
      <Grid item xs={5.5}>
        <Select
          reference={columnSelectRef}
          styles={selectStyles(columnSelectedOption)}
          options={formatColumns(columns)}
          onChange={columnOptionsChangeHandler}
          closeMenuOnSelect
          placeholder="Elegir columna..."
          isClearable
          isMulti
          isDisabled={
            columns === undefined || columns === null || columns.length === 0
          }
        />
      </Grid>
      <Grid item xs={5}>
        <Select
          id="function"
          reference={functionSelectRef}
          styles={selectMultiStyles(functionSelectedOption)}
          options={
            findTypeByColumn(columnSelectedOption, columns) === "CATEGORICA"
              ? CATEGORIC_FUNCTIONS
              : NUMERIC_FUNCTIONS
          }
          className="cc_select"
          name="function"
          onChange={functionOptionsChangeHandler}
          closeMenuOnSelect
          placeholder="Elegir función..."
          isDisabled={
            columns === undefined ||
            columns === null ||
            columns.length === 0 ||
            columnSelectedOption === undefined
          }
          isClearable
        />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
        item
        xs={1}
      >
        <AddButton align={true} onClick={() => handleAddGroupByRow()} />
      </Grid>
    </>
  );
};

export default GroupByRowSelects;
