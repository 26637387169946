import { Stack, Typography } from "@mui/material";
import Dialog from "../../dialog/Dialog";
import Button from "../../buttons/Button";

interface EditPipelineWarningModalProps {
  open: boolean;
  handleAccept: () => void;
  handleClose: () => void;
}

const EditPipelineWarningModal = ({
  open,
  handleAccept,
  handleClose
}: EditPipelineWarningModalProps) => {
  return (
    <Dialog open={open} handleClose={handleClose} maxWidth="xl" title={"No es posible editar el pipeline sin deshacer todo"}>
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: "10px",
            color: "#172D40",
            marginTop: "30px",
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          Para comenzar a editar el pipeline es necesario deshacer las transformaciones previas primero.
        </Typography>
        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Deshacer todo"
            type="button"
            onClick={handleAccept}
            color="blue-greeny"
          />
                <Button
            title="Cancelar"
            type="button"
            onClick={handleClose}
            color="grey"
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default EditPipelineWarningModal;
