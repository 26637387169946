import { useContext, useEffect, useRef, useState } from "react";
import BaseModal from "./BaseModal";
import useApi from "../../../hooks/useApi";
import { FLOW_ITEMS, GET_FLOW_URL, NEW_GET_COLUMNS_FLOW_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import Stack from "@mui/material/Stack";
import useDialog from "../../../hooks/useDialog";
import { InputLabel, TextField, Tooltip, Typography } from "@mui/material";
import ShadowBox from "./ShadowBox";
import { form_label } from "../../../styles/app-styles";
import {
  checkIfColumnNotExists,
  checkIfAllColumnNotExists,
  filterIdFromColumns,
  isColumnNameRepeated,
  isColumnNameValid,
  setInitialFlowNodes,
  setInitialFlowEdges,
  setErrorFlowNodes,
  checkIfNodeHasPendingEdges,
} from "../utils";
import CheckmarkSelect from "../../forms/CheckmarkSelect";
import InfoIcon from "@mui/icons-material/Info";
import { useStore } from "../FlowDiagram/store/store";
import shallow from "zustand/shallow";
import { getBackendId } from "../../../utils/util";

interface DesdynamizeRowsProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  id: string;
  backendId?: any;
  parentIds?: any[];
  sourceItemId: string;
  nodeData?: any;
}
const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
  setInitialNodes: store.setInitialNodes,
  setInitialEdges: store.setInitialEdges,
  updateIsFlowLoading: store.updateIsFlowLoading
});

const DesdynamizeRows = ({
  open,
  handleClose,
  dataItem,
  id,
  backendId,
  sourceItemId,
  nodeData,
}: DesdynamizeRowsProps) => {
  const [comment, setComment] = useState<any>("");
  const store = useStore(selector, shallow);
  const nodesRef = useRef<any[]>([]);
  nodesRef.current = store.nodes;
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();

  const [selectedIndexColumns, setSelectedIndexColumns] = useState<number[]>(
    []
  );
  const [selectedDesdynamizeColumns, setSelectedDesdynamizeColumns] = useState<
    number[]
  >([]);
  const [categoricColumnHeader, setCategoricColumnHeader] = useState("");
  const [numericColumnHeader, setNumericColumnHeader] = useState("");

  /*********************************** CARGA DE DATOS ****************************************/
  const {
    data: dataColumns,
    error: errorColumnsFont,
    isLoading: isLoadingColumnsFont,
    callApi: getColumnsFont,
  } = useApi(
    undefined,
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if (open) {
      getColumnsFont(
        NEW_GET_COLUMNS_FLOW_URL(
          store.flow.id,
          getBackendId(sourceItemId, store.nodes) as number
        )
      );
    }
  }, [open]);

  useEffect(() => {
    if (dataItem && open) {
      const data = dataItem.detalle_item;
      setComment(dataItem.comentario);
      setCategoricColumnHeader(data.encabezado_categoria);
      setNumericColumnHeader(data.encabezado_valor);
      setSelectedIndexColumns(
        data.columnas_indice?.map((item: any) => {
          return { id: item.id, label: item.columna };
        })
      );
      setSelectedDesdynamizeColumns(
        data.columnas_nuevas?.map((item: any) => {
          return { id: item.id, label: item.columna };
        })
      );
    }
  }, [dataItem, open]);

  /******************************* POSTEAR ITEM *********************************************/

  const onSuccessGetFlow = (data: any) => {
    store.setInitialNodes(setErrorFlowNodes(nodesRef.current , data.items));
  };

  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  useEffect(() => {
    store.updateIsFlowLoading(isLoadingFlow)
  }, [isLoadingFlow]);

  const handleConfirmDynamizeRows = () => {
    toggleConfirmationModal();
    const completedInfo = {
      tipo: "desdinamizar",
      calcular: true,
      editar_detalle_item: true,
      parent_ids: [getBackendId(sourceItemId, store.nodes)],
      comentario: comment,
      id: backendId ? backendId : undefined,
      columnas_indice: selectedIndexColumns
        .filter((col: any) => {
          return col.id !== "todas";
        })
        .map((col: any) => {
          return col.label;
        }),
      columnas_nuevas: selectedDesdynamizeColumns
        .filter((col: any) => {
          return col.id !== "todas";
        })
        .map((col: any) => {
          return col.label;
        }),
      encabezado_categoria: categoricColumnHeader,
      encabezado_valor: numericColumnHeader,
      ...nodeData.position,
    };
    if (dataItem) {
      putDesdynamizeRows(undefined, [completedInfo]);
    } else {
      postDesdynamizeRows(undefined, completedInfo);
    }
    cleanAndClose();
  };

  // Actualizamos el nodo insertandole el id del backend que es el que vamos a utilizar
  // cuando enviemos datos al back
  const onSuccessPostItem = (data: any) => {
    const completedInfo = {
      tipo: "desdinamizar",
      columnas_indice: selectedIndexColumns
        .filter((col: any) => {
          return col.id !== "todas";
        })
        .map((col: any) => {
          return col.label;
        }),
      columnas_nuevas: selectedDesdynamizeColumns
        .filter((col: any) => {
          return col.id !== "todas";
        })
        .map((col: any) => {
          return col.label;
        }),
      encabezado_categoria: categoricColumnHeader,
      encabezado_valor: numericColumnHeader,
      ...nodeData.position,
    };
    store.updateNode(id, {
      //  ...getBody(),
      backendId: backendId ? backendId : data.id,
      error: data.posee_error,
      errorMessage: data.mensaje_error,
      comentario: comment,
      ...completedInfo
    });
    checkIfNodeHasPendingEdges(id, putConnection, data.id)
    getFlow(GET_FLOW_URL(parseInt(store.flow.id)));

  };

  const {
    isLoading: isLoadingPostDesdynamizeRows,
    callApi: postDesdynamizeRows,
    error: errorPostDesdynamizeRows,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutDesdynamizeRows,
    callApi: putDesdynamizeRows,
    error: errorPutDesdynamizeRows,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutConnection,
    callApi: putConnection,
    error: errorPutConnection,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  /************************************** VALIDATIONS ************************************************** */

  const isFormCompleted = () => {
    return (
      !selectedIndexColumns ||
      selectedIndexColumns.length < 1 ||
      !selectedDesdynamizeColumns ||
      selectedDesdynamizeColumns.length < 1 ||
      categoricColumnHeader.length < 1 ||
      numericColumnHeader.length < 1 ||
      selectedIndexColumns.some((colIndex: any) => {
        return selectedDesdynamizeColumns.some((colDes: any) => {
          return colIndex.label === colDes.label;
        });
      }) ||
      !isColumnNameValid(categoricColumnHeader) ||
      isColumnNameRepeated(categoricColumnHeader, dataColumns) ||
      !isColumnNameValid(numericColumnHeader) ||
      isColumnNameRepeated(numericColumnHeader, dataColumns) ||
      categoricColumnHeader === numericColumnHeader ||
      (selectedIndexColumns &&
        dataColumns &&
        checkIfAllColumnNotExists(selectedIndexColumns, dataColumns)) ||
      (selectedDesdynamizeColumns &&
        dataColumns &&
        checkIfAllColumnNotExists(selectedDesdynamizeColumns, dataColumns))
    );
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    handleClose();
    setSelectedIndexColumns([]);
    setSelectedDesdynamizeColumns([]);
    setCategoricColumnHeader("");
    setNumericColumnHeader("");
    setComment("");
  };

  return (
    <BaseModal
      open={open}
      title="Desdinamizar columnas"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={handleConfirmDynamizeRows}
      confirmationModalMessage={"desdinamizar las columnas"}
      disabledAcceptButton={isFormCompleted()}
      hasResultsTable
      source_id={getBackendId(sourceItemId, store.nodes)}
      height="500px"
      width="900px"
    >
      <Stack
        gap={2}
        sx={{ width: "100%", height: dataItem? "450px":"550px", marginBottom: "35px" }}
      >
        {!dataItem &&        <ShadowBox margin>
          <TextField
            id="comment"
            label={"Comentario"}
            placeholder={"Comentario"}
            size="small"
            multiline
            variant="outlined"
            fullWidth
            value={comment}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setComment(event.target.value);
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
              },
            }}
            required
          />
        </ShadowBox>}
 
        <ShadowBox>
          {selectedIndexColumns &&
            dataColumns &&
            selectedIndexColumns
              .filter((col: any) => col.id !== "todas")
              .some((col: any) =>
                checkIfColumnNotExists(
                  col.label,
                  dataColumns.map((item: any) => {
                    return { id: item.id, label: item.columna };
                  })
                )
              ) && (
              <Typography
                sx={{
                  color: "var(--red)",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                Las columnas no existentes en la fuente actual no se tendrán en
                cuenta y se borrarán en pasos posteriores.
              </Typography>
            )}
          <Stack direction="row">
            <InputLabel sx={{ ...form_label, marginBottom: "10px" }}>
              {"Columnas índice"}
            </InputLabel>
            <Tooltip title="Las columnas utilizadas como columnas índice y las columnas a desdinamizar no pueden repetirse">
              <InfoIcon
                sx={{
                  color: "var(--blue)",
                  fontSize: "21px",
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginBottom: "2px",
                }}
              />
            </Tooltip>
            {selectedIndexColumns.some((colIndex: any) => {
              return selectedDesdynamizeColumns.some((colDes: any) => {
                return colIndex.label === colDes.label;
              });
            }) && (
              <Typography sx={{ color: "var(--red)", marginLeft: "10px" }}>
                Las columnas índice y las columnas a desdinamizar no pueden
                coincidir{" "}
              </Typography>
            )}
          </Stack>
          <CheckmarkSelect
            label="Seleccionar columnas"
            selectedItems={selectedIndexColumns}
            setSelectedItems={setSelectedIndexColumns}
            items={filterIdFromColumns(dataColumns)?.map((item: any) => {
              return { id: item.id, label: item.columna };
            })}
            width="100%"
          />
        </ShadowBox>
        <ShadowBox>
          {selectedDesdynamizeColumns &&
            dataColumns &&
            selectedDesdynamizeColumns
              .filter((col: any) => col.id !== "todas")
              .some((col: any) =>
                checkIfColumnNotExists(
                  col.label,
                  dataColumns.map((item: any) => {
                    return { id: item.id, label: item.columna };
                  })
                )
              ) && (
              <Typography
                sx={{
                  color: "var(--red)",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                Las columnas no existentes en la fuente actual no se tendrán en
                cuenta y se borrarán en pasos posteriores.
              </Typography>
            )}
          <Stack direction="row">
            <InputLabel sx={{ ...form_label, marginBottom: "10px" }}>
              {"Columnas a desdinamizar"}
            </InputLabel>
            {selectedIndexColumns.some((colIndex: any) => {
              return selectedDesdynamizeColumns.some((colDes: any) => {
                return colIndex.label === colDes.label;
              });
            }) && (
              <Typography sx={{ color: "var(--red)", marginLeft: "10px" }}>
                Las columnas índice y las columnas a desdinamizar no pueden
                coincidir{" "}
              </Typography>
            )}
          </Stack>
          <CheckmarkSelect
            label="Seleccionar columnas"
            selectedItems={selectedDesdynamizeColumns}
            setSelectedItems={setSelectedDesdynamizeColumns}
            items={filterIdFromColumns(dataColumns)?.map((item: any) => {
              return { id: item.id, label: item.columna };
            })}
            width="100%"
          />
        </ShadowBox>
        <ShadowBox>
          <InputLabel sx={form_label}>
            {"Encabezado columna categórica"}
          </InputLabel>
          <TextField
            id="columnCategoric"
            label={""}
            placeholder={"Nombre del encabezado"}
            size="small"
            multiline
            variant="outlined"
            helperText={
              isColumnNameValid(categoricColumnHeader) ||
              categoricColumnHeader === ""
                ? isColumnNameRepeated(categoricColumnHeader, dataColumns) ||
                  (categoricColumnHeader !== "" &&
                    categoricColumnHeader === numericColumnHeader)
                  ? texts.flows.newColumn.helperText.repeatedName
                  : ""
                : texts.flows.newColumn.helperText.invalidName
            }
            error={
              !isColumnNameValid(categoricColumnHeader) ||
              categoricColumnHeader === "" ||
              isColumnNameRepeated(categoricColumnHeader, dataColumns)
            }
            fullWidth
            value={categoricColumnHeader}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCategoricColumnHeader(event.target.value);
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
              },
            }}
            required
          />
        </ShadowBox>
        <ShadowBox>
          <InputLabel sx={form_label}>
            {"Encabezado columna numérica"}
          </InputLabel>
          <TextField
            id="numericColumnHeader"
            label={""}
            placeholder={"Nombre del encabezado"}
            size="small"
            multiline
            variant="outlined"
            helperText={
              isColumnNameValid(numericColumnHeader) ||
              numericColumnHeader === ""
                ? isColumnNameRepeated(numericColumnHeader, dataColumns) ||
                  (numericColumnHeader !== "" &&
                    categoricColumnHeader === numericColumnHeader)
                  ? texts.flows.newColumn.helperText.repeatedName
                  : ""
                : texts.flows.newColumn.helperText.invalidName
            }
            error={
              !isColumnNameValid(numericColumnHeader) ||
              numericColumnHeader === "" ||
              isColumnNameRepeated(numericColumnHeader, dataColumns)
            }
            fullWidth
            value={numericColumnHeader}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNumericColumnHeader(event.target.value);
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
              },
            }}
            required
          />
        </ShadowBox>
      </Stack>
    </BaseModal>
  );
};

export default DesdynamizeRows;
