import { Handle, Position } from "@xyflow/react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import useDialog from "../../../../hooks/useDialog";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import NodeWithToolbar from "../nodeToolbar/NodeToolbar";
import { useStore } from "../store/store";
import { shallow } from "zustand/shallow";
import useApi from "../../../../hooks/useApi";
import { ITEM_URL } from "../../../../api/axios";
import { texts } from "../../../../texts";
import { isEditable } from "../../../../utils/util";
import ModifyColumn from "../../Modals/ModifyColumn";
import CustomHandle from "../handles/CustomHandle";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import NodeComment from "./NodeComment";
import { useEffect } from "react";


interface ModifyColumnsNodeProps {
  id: any;
  data: any;
}

const selector = (store: any) => ({
  flow: store.flow,
  nodes: store.nodes,
  isLoadingFlow: store.isLoadingFlow
});

export default function ModifyColumnsNode({
  id,
  data,
}: ModifyColumnsNodeProps) {
  const [openModifyColumn, handleToggleModifyColumnModal] =
    useDialog();
  const store = useStore(selector, shallow);
  const node = store.nodes.find((node: any)=> node.id === id)

  useEffect(() => {
    if(!data.backendId && data.parentIds?.length > 0){
      handleToggleModifyColumnModal()
    }
   }, [data.parentIds]);

  const onSuccessGetItem = () => {
    handleToggleModifyColumnModal();
  };

  const {
    data: dataItem,
    callApi: getDataItem,
    setData: setDataItem,
    isLoading: isLoadingGetItem,
  } = useApi(
    ITEM_URL(store.flow?.id, data?.backendId),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetItem,
    undefined,
    false
  );

  const handleEdit = () => {
    if (data.backendId) {
      getDataItem();
    } else {
      handleToggleModifyColumnModal();
    }
  };
  return (
    <Stack sx={{ alignItems: "center", width: "45px",height:"64px" }}>
            <SimpleBackdrop open={isLoadingGetItem} message={"Cargando datos..."} />

    <NodeWithToolbar  handleEdit={handleEdit}
          backendId={data.backendId}
          isEditable={isEditable(data.parentIds, store.nodes)} nodeData={node}/>
        <CustomHandle
        type="target"
        position={Position.Left}
        id={`handle-target-2-${id}`}
        connectionCount={1}
        nodeId={id}

      />
       {data.error &&     <WarningAmberIcon
        style={{
          fontSize: 12,
          color: "var(--red)" ,
          position:"absolute",
          top:-8,
          right:5
        }}
      />}
           {store.isLoadingFlow && (
        <CircularProgress
          sx={{
            width: "10px !important",
            height: "10px !important",
            right: "0",
            position: "absolute",
          }}
        />
      )}
      <BorderColorIcon
        style={{
          fontSize: 40,
          color:data.color ? `var(--${data.color})` :"var(--violet)",
        }}
      />
      <Typography sx={{ fontSize: "8px", marginLeft: "5px" }}>
        Modificar columnas {data.comentario ? ": " : ""}
      </Typography>
      <NodeComment id={id} data={data}/>
      <CustomHandle
        type="source"
        position={Position.Right}
        id={`handle-source-2-${id}`}
        connectionCount={1}
        nodeId={id}


      />
         <ModifyColumn
        handleClose={handleToggleModifyColumnModal}
        open={openModifyColumn}
        dataItem={dataItem}
        id={id}
        backendId={data.backendId}
        sourceItemId={data?.parentIds ? data?.parentIds[0] : undefined}
        parentIds={data.parentIds}
        nodeData={node}

      />
    </Stack>
  );
}
