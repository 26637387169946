import Button from "@mui/material/Button";
import { pin_button_styles } from "../../../styles/app-styles";
import Tooltip from "@mui/material/Tooltip";

interface PinButtonProps {
  Icon: any;
  tooltipTitle: string;
  onClick: ((event: React.MouseEvent<HTMLElement>) => void) | (() => void);
  size?: string;
  backgroundColor?: string;
  tooltipPlacement?: "top" | "bottom" | "left" | "right";
  disabled?: boolean;
  iconSize?: string
}

const PinButton = ({
  Icon,
  tooltipTitle,
  onClick,
  size,
  backgroundColor,
  tooltipPlacement,
  disabled,
  iconSize
}: PinButtonProps) => {
  return (
    <Tooltip
      title={tooltipTitle}
      placement={tooltipPlacement ? tooltipPlacement : "right"}
      sx={{
        pointerEvents: "fill",
      }}
    >
      <Button
        variant="contained"
        sx={pin_button_styles(size ? size : undefined, backgroundColor)}
        onClick={onClick}
        disabled={disabled}
      >
        <Icon sx={{ fontSize: iconSize ? iconSize : "20px", cursor: "pointer" }} />
      </Button>
    </Tooltip>
  );
};

export default PinButton;
