import { Handle, Position } from "@xyflow/react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import useDialog from "../../../../hooks/useDialog";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import useApi from "../../../../hooks/useApi";
import { texts } from "../../../../texts";
import { CALC_FLOW_URL, ITEM_URL } from "../../../../api/axios";
import { useStore } from "../store/store";
import { shallow } from "zustand/shallow";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import { isEditable } from "../../../../utils/util";
import FileFormat from "../../Modals/CalculationConfigs/FileFormat";
import CustomHandle from "../handles/CustomHandle";
import NodeWithToolbar from "../nodeToolbar/NodeToolbar";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import NodeComment from "./NodeComment";
import { getItemById } from "../../utils";
import { useEffect } from "react";

interface CalculateNodeProps {
  id: any;
  data: any;
}

const selector = (store: any) => ({
  flow: store.flow,
  nodes: store.nodes,
  isLoadingFlow: store.isLoadingFlow
});

export default function CalculateNode({ id, data }: CalculateNodeProps) {
  const [openCalculateModal, handleToggleCalculateModal] = useDialog();
  const store = useStore(selector, shallow);
  const node = store.nodes.find((node: any)=> node.id === id)

  useEffect(() => {
    if(!data.backendId && data.parentIds?.length > 0){
      handleToggleCalculateModal()
    }
   }, [data.parentIds]);

  const onSuccessGetItem = () => {
    handleToggleCalculateModal();
  };

  const handleEdit = () => {
    if (data.backendId) {
      getDataItem();
    } else {
      handleToggleCalculateModal();
    }
  };

  const {
    data: dataItem,
    callApi: getDataItem,
    setData: setDataItem,
    isLoading: isLoadingGetItem,
  } = useApi(
    ITEM_URL(store.flow?.id, data?.backendId),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetItem,
    undefined,
    false
  );

  const { isLoading: isLoadingCalcFlow, callApi: calcFlow } = useApi(
    CALC_FLOW_URL(store.flow?.id, data.backendId),
    "POST",
    texts.flows.calcFlow.codes,
    undefined,
    undefined,
    undefined,
    true
  );

  const handleRunFlow = () => {
      calcFlow();
  };

  return (
    <Stack sx={{ alignItems: "center", width: "45px",height:"64px" }}>
      <SimpleBackdrop open={isLoadingGetItem} message={"Cargando datos..."} />
      <SimpleBackdrop open={isLoadingCalcFlow} message={ texts.flows.calcFlow.loading} />

      <NodeWithToolbar
        handleEdit={handleEdit}
        backendId={data.backendId}
        isEditable={isEditable(data.parentIds, store.nodes)}
        isCalculable={data.backendId && data.parentIds?.length > 0 && getItemById(data.parentIds[0], store.nodes)?.data.error === false}
        handleCalculate={handleRunFlow}
        activateShowResults={false}
        activateAddItems={false}
        nodeData={node}
      />
   {data.error &&     <WarningAmberIcon
        style={{
          fontSize: 12,
          color: "var(--red)" ,
          position:"absolute",
          top:-5,
          right:5
        }}
      />}
       {store.isLoadingFlow && (
        <CircularProgress
          sx={{
            width: "10px !important",
            height: "10px !important",
            right: "0",
            position: "absolute",
          }}
        />
      )}

      <PlayCircleOutlineIcon
        style={{
          fontSize: 40,
          color: data.color ? `var(--${data.color})` :"var(--dark-blue-2)",
        }}
      />
      <Typography sx={{ fontSize: "8px" }}>
        Calcular{data.comentario ? ": " : ""}
      </Typography>
      <NodeComment id={id} data={data}/>
      <CustomHandle
        type="target"
        position={Position.Left}
        id={`handle-target-2-${id}`}
        connectionCount={1}
        nodeId={id}

      />
      <FileFormat
        handleClose={handleToggleCalculateModal}
        open={openCalculateModal}
        dataItem={dataItem}
        id={id}
        backendId={data.backendId}
        sourceItemId={data?.parentIds ? data?.parentIds[0] : undefined}
        nodeData={node}
      />
    </Stack>
  );
}