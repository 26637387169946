import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { Handle, Position } from "@xyflow/react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import useDialog from "../../../../hooks/useDialog";
import NodeWithToolbar from "../nodeToolbar/NodeToolbar";
import useApi from "../../../../hooks/useApi";
import { texts } from "../../../../texts";
import { ITEM_URL } from "../../../../api/axios";
import { useStore } from "../store/store";
import { shallow } from "zustand/shallow";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import CustomHandle from "../handles/CustomHandle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import NodeComment from "./NodeComment";
import GroupModal from '../../Modals/GroupModal';

interface GroupNodeProps {
  id: any;
  data: any;
}

const selector = (store: any) => ({
  flow: store.flow,
  nodes: store.nodes
});

export default function GroupNode({ id, data }: GroupNodeProps) {
  const [openGroupModal, handleToggleGroupModal] = useDialog();

  const store = useStore(selector, shallow);

  const node = store.nodes.find((node: any) => node.id === id);

  const onSuccessGetItem = () => {
    handleToggleGroupModal();
  };

  const handleEdit = () => {
    if (data.backendId) {
      getDataItem();
    } else {
      handleToggleGroupModal();
    }
  };

  const {
    data: dataItem,
    callApi: getDataItem,
    setData: setDataItem,
    isLoading: isLoadingGetItem,
  } = useApi(
    ITEM_URL(store.flow?.id, data?.backendId),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetItem,
    undefined,
    false
  );

  return (
    <Stack sx={{ alignItems: "center", width: "45px",height:"64px" }}>
      <SimpleBackdrop open={isLoadingGetItem} message={"Cargando datos..."} />
      <NodeWithToolbar
        handleEdit={handleEdit}
        backendId={data.backendId}
        nodeData={node}
        activateShowResults={false}
        activateAddItems={false}
      />
       <CustomHandle
        type="target"
        position={Position.Left}
        id={`handle-target-2-${id}`}
        connectionCount={1}
        nodeId={id}

      />
      {(data.error || data.nodes.some((node: any)=> node?.posee_error || node?.data?.error)) && (
        <WarningAmberIcon
          style={{
            fontSize: 12,
            color: "var(--red)",
            position: "absolute",
            top: -5,
            right: 5,
          }}
        />
      )}
      <GroupWorkIcon
        style={{
          fontSize: 40,
          color: data.color ? `var(--${data.color})` :"var(--green-2)",
        }}
      />
      <Typography sx={{ fontSize: "8px" }}>
        Grupo{data.nombre ? ": " : ""}
      </Typography>
      <NodeComment id={id} data={data} isGroupNode/>
      <CustomHandle
        type="source"
        position={Position.Right}
        id={`handle-source-2-${id}`}
        connectionCount={20}
        nodeId={id}
      />
      <GroupModal
      handleClose={handleToggleGroupModal}
      open={openGroupModal}
      dataItem={dataItem}
      id={id}
      backendId={data.backendId}
      parentIds={data.parentIds}
      nodeData={node}/>
    </Stack>
  );
}
