import { Handle } from "@xyflow/react";
import { Position } from "@xyflow/react";
import { useStore } from "../store/store";
import { shallow } from "zustand/shallow";

interface CustomHandleProps {
  id: string;
  type: "source" | "target";
  connectionCount: number;
  position: Position;
  nodeId: string
}

const selector = (store: any) => ({
  flow: store.flow,
  edges: store.edges,
});

const CustomHandle = ({
  id,
  type,
  connectionCount,
  position,
  nodeId
}: CustomHandleProps) => {
  const store = useStore(selector, shallow);

  const getHandleConnection = ()=>{
   return store.edges.filter((edge: any)=> edge.targetHandle === id )
  }

  return (
    <Handle
      id={id}
      type={type}
      position={position}
      isConnectable={getHandleConnection().length < connectionCount}
    />
  );
};
export default CustomHandle;
