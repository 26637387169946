import { Stack } from "@mui/material";
import ComponentLayout from "../layout/ComponentLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ToolsBar from "./ToolsBar/ToolsBar";
import {  useEffect } from "react";
import { texts } from "../../texts";
import useApi from "../../hooks/useApi";
import { GET_FLOW_URL } from "../../api/axios";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import {  setInitialFlowEdges, setInitialFlowNodes } from "./utils";
import { useNavigate } from "react-router";
import FlowDiagram from "./FlowDiagram/FlowDiagram";
import { useStore } from "./FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
import { ReactFlowProvider } from "@xyflow/react";

const selector = (store: any) => ({
  reset: store.reset,
  saveFlow: store.saveFlow,
  setInitialNodes: store.setInitialNodes,
  setInitialEdges: store.setInitialEdges,
  updateFlowLoaded: store.updateFlowLoaded
});

const ConfigFlows = () => {
  const navigate = useNavigate();
  const store = useStore(selector, shallow);
  

  const flowId = localStorage.getItem("flowId")
    ? localStorage.getItem("flowId")
    : undefined;

  useEffect(() => {
    if (flowId) {
      getFlow(GET_FLOW_URL(parseInt(flowId)));
    }
  }, [flowId]);

  useEffect(() => {
    return () => {
     //store.reset()
    };
  }, [])

  const onSuccessGetFlow = (data: any) => {
    store.saveFlow(data.id, data.nombre)
    store.setInitialNodes(setInitialFlowNodes(data?.items))
    store.setInitialEdges(setInitialFlowEdges(data?.items))
    store.updateFlowLoaded()
  };

  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  const handleCloseConfigFlows = () => {
    localStorage.removeItem("flowId");
    navigate("/administracion-flujos");
   // store.reset()
  };

  return (
    <ComponentLayout title="Configuración de Flujos" icon={<ArrowBackIcon
      sx={{
        fontSize: "35px",
        marginRight: "10px",
        "& :hover": { cursor: "pointer", color: "#f90d4a" },
      }}
      onClick={handleCloseConfigFlows}
    />}>
      <SimpleBackdrop
        open={isLoadingFlow}
        message={texts.flows.getFlow.loading}
      />
      <Stack sx={{ width: "99%", ml: "10px", mb: "20px" }}>
      <ReactFlowProvider>
        <ToolsBar />
        <FlowDiagram isLoadingFlow={isLoadingFlow}/>
        </ReactFlowProvider>
      </Stack>
    </ComponentLayout>
  );
};

export default ConfigFlows;
