import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import SpeedDialTooltipOpen from "../buttons/SpeedDialTooltipOpen";
import { Tooltip } from "@mui/material";
import { truncateString } from "../pipelines/utils";
import useDialog from "../../hooks/useDialog";
import ExportModal from "../exportItemModal/ExportModal";
import useApi from "../../hooks/useApi";
import { COPY_FLOW_URL } from "../../api/axios";
import { texts } from "../../texts";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";

const FlowCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  showInfo,
}: any) => {
  const [openModal, handleToggleOpenModal] = useDialog();

  const { isLoading: isLoadingExportFlow, callApi: exportFlow } = useApi(
    COPY_FLOW_URL(item.id),
    "POST",
    texts.flows.exportFlow.codes
  );

  const handleAccept = (name: string, company: any) => {
    exportFlow(undefined, { nombre: name, empresa: company });
    handleToggleOpenModal();
  };

  return (
    <>
      <Stack sx={{ padding: "3px 5px 5px 5px" }}>
      <SimpleBackdrop
        open={isLoadingExportFlow}
        message={ texts.flows.exportFlow.loading}
      />
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "42px",
            wordBreak: "break-word",
          }}
        >
          <Tooltip title={item.nombre}>
            <Typography
              variant="h6"
              sx={{
                color: "var(--blue)",
                fontWeight: "800",
                textTransform: "uppercase",
                fontSize: "15px",
                lineHeight: "1",
                width: "98%",
              }}
            >
              {truncateString(item?.nombre, 100)}
            </Typography>
          </Tooltip>
        </Stack>

        <Divider sx={{ margin: "5px 0" }} />
        {/*showInfo && (
          <>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
              }}
              color="var(--blue)"
            >
              Pasos:{" "}
              <span style={{ color: "var(--light-grey)" }}>
                {item?.steppers?.length}
              </span>
            </Typography>
          </>
        )*/}
        <SpeedDialTooltipOpen
          id={item.id}
          handleEdit={handleEdit}
          /* handleCopy={handleCopy}*/
          handleInfo={handleInfo}
          handleDelete={handleDelete}
          handleExport={handleToggleOpenModal}
        />
      </Stack>
      <ExportModal open={openModal} handleClose={handleToggleOpenModal} handleAccept={handleAccept} item={item} itemType="flujo"/>
    </>
  );
};

export default FlowCard;
