import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  onTitleMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void ;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, onTitleMouseDown, ...other } = props;

  return (
    <div onMouseDown={onTitleMouseDown}>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          color: "#172D40",
          fontWeight: "800",
          height: "50px",
          WebkitUserSelect: "none",
          marginRight:"30px !important",
          cursor: onTitleMouseDown ? "move" : "default",
          wordBreak:"break-word"
        }}
        {...other}
      >
        {children}
    
      </DialogTitle>
      {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
    </div>
  );
};

interface CustomizedDialogsProps {
  open: boolean;
  handleClose: () => void;
  title?: string;
  children: React.ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  sx?: any;
  actions?: React.ReactNode;
  draggable?: boolean
}

export default function CustomizedDialogs({
  open,
  handleClose,
  title,
  children,
  maxWidth,
  sx,
  actions,
  draggable
}: CustomizedDialogsProps) {
  const [isDragging, setIsDragging] = React.useState(false);
  const [offset, setOffset] = React.useState({ x: 0, y: 0 });

  const handleTitleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const dialog = document.getElementById("customized-dialog");
    if (dialog) {
      e.preventDefault();
      setIsDragging(true);
      setOffset({
        x: e.clientX - dialog.getBoundingClientRect().left,
        y: e.clientY - dialog.getBoundingClientRect().top,
      });
    }
  };

  
    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isDragging) return;
    
      const dialog = document.getElementById("customized-dialog");
      if (dialog) {
        const newX = e.clientX - offset.x;
        let newY = e.clientY - offset.y;
    
        // Obtener el tamaño del modal
        const modal = dialog.getBoundingClientRect();
        const minHeight = 130; // Establece la altura mínima que deseas
    
        // Calcular la posición máxima y mínima permitida en función de la altura mínima
        const maxY = window.innerHeight - minHeight;
        const minY = 0;
    
        // Asegurarse de que la nueva posición esté dentro de los límites
        newY = Math.max(minY, Math.min(newY, maxY));
    
        // Calcular el cambio en la posición vertical del mouse desde la última posición
        const deltaY = newY - modal.top;
    
        // Ajustar la altura del modal en consecuencia
        const newHeight = modal.height - deltaY;
        const adjustedHeight = Math.max(minHeight, newHeight); // Asegurar que no se reduzca más allá de la altura mínima
        dialog.style.height = `${adjustedHeight}px`;
    
        // Ajustar la posición vertical del modal si se ha alcanzado la altura mínima
        if (adjustedHeight === minHeight) {
          newY = modal.top + (modal.height - minHeight);
        }
    
        // Establecer la nueva posición del modal
        dialog.style.left = `${newX}px`;
        dialog.style.top = `${newY}px`;
      }
    };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div>
      <BootstrapDialog
        id="customized-dialog"
        onMouseMove={draggable ? handleMouseMove : undefined}
        onMouseUp={draggable ? handleMouseUp : undefined }
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={maxWidth !== undefined ? maxWidth : "sm"}
        sx={sx}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          onTitleMouseDown={draggable ? handleTitleMouseDown : undefined}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ padding: "0 200px" }}>
          {children}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {actions}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
