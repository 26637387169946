import { InputLabel, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Tab, TabValue } from "../../colorTabs/types";
import ColorTabs from "../../colorTabs/ColorTabs";
import UploadModalContent from "../../flowConfiguration/Modals/AddFont/UploadLocalFiles";
import SuccessUploadLocalFiles from "../../flowConfiguration/Modals/AddFont/SuccessUploadLocalFiles";
import ExploreFilesTab from "./ExploreFilesTab";
import { convertArrayToText } from "../../taskManager/utils";
import { form_label } from "../../../styles/app-styles";
import { useLocation, useNavigate } from "react-router";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ExternalConnectionsTab from "./ExternalConnectionsTab";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "../../buttons";
import { getRoute } from "../../flowConfiguration/Modals/AddFont/utils";

interface UploadFileContentProps {
  selectedFile: string | string[];
  setSelectedFile: any;
  dataFileState: any;
  setDataFileState: any;
  areMultipleFiles?: boolean;
  isDataManagementPage?: boolean;
  setSelectedFileFolder?: any;
  notUnselectFilesOnFolderChange?: boolean;
  onSuccessChargeLocalFiles?: any;
  customCallProperties?: any;
  customWidth?: string;
  dataItem?: any;
  disableChooseFiles?: boolean
  customInitialRoute?: string
}

const UploadFileContent = ({
  isDataManagementPage,
  selectedFile,
  setSelectedFile,
  areMultipleFiles,
  dataFileState,
  setDataFileState,
  setSelectedFileFolder,
  notUnselectFilesOnFolderChange,
  onSuccessChargeLocalFiles,
  customCallProperties,
  customWidth,
  dataItem,
  customInitialRoute,
  disableChooseFiles
}: UploadFileContentProps) => {
  const [tabValue, setTabValue] = useState<TabValue>(
    isDataManagementPage ? "CARGAR ARCHIVOS LOCALES" : "EXPLORAR ARCHIVOS"
  );
  const [filesToUpload, setFilesToUpload] = useState<File[] | null>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isRestartChecked, setIsRestartChecked] = useState<boolean>(true);
  const navigate = useNavigate();
  const locationUrl = useLocation();


  const tabs: Tab[] = isDataManagementPage
    ? !decodeURIComponent(getRoute(locationUrl.pathname)).startsWith("PHI")? [
        {
          value: "CARGAR ARCHIVOS LOCALES",
          label: "CARGAR ARCHIVOS LOCALES",
        },
        {
          value: "CONEXIONES EXTERNAS",
          label: "CONEXIONES EXTERNAS",
        },
      ] :  [
        {
          value: "CARGAR ARCHIVOS LOCALES",
          label: "CARGAR ARCHIVOS LOCALES",
        }
      ] 
    : [
        {
          value: "CARGAR ARCHIVOS LOCALES",
          label: "CARGAR ARCHIVOS LOCALES",
        },
        {
          value: "EXPLORAR ARCHIVOS",
          label: "EXPLORAR ARCHIVOS",
        },

      ];

  /*************************** HANDLERS ***************************/

  const handleChangeTabs = (
    event: React.SyntheticEvent,
    newValue: TabValue
  ) => {
    setTabValue(newValue);
    if (newValue !== "CARGAR ARCHIVOS LOCALES") {
      setFilesToUpload(null);
      setIsUploading(false);
      setIsRestartChecked(true);
    }
    setDataFileState([]);
    areMultipleFiles
      ? setSelectedFile([], "delete all")
      : setSelectedFile("", "delete all");
  };

  return (
    <>
      {tabValue === "CARGAR ARCHIVOS LOCALES" && (
        <Typography
          sx={{
            color: "var(--blue)",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Cargar un nuevo archivo con el nombre de uno ya existente en la misma
          ubicación, causará que sea reemplazado.
        </Typography>
      )}

      <Stack width="100%" gap={2} justifyContent={"flex-start"}>
        <ColorTabs
          value={tabValue}
          handleChange={handleChangeTabs}
          tabs={tabs}
          marginBottom="10px"
        />
      </Stack>

      {tabValue === "CARGAR ARCHIVOS LOCALES" &&
        isDataManagementPage &&
        ((filesToUpload === null || filesToUpload?.length === 0) &&
        !isUploading ? (
          <UploadModalContent
            filesToUpload={filesToUpload}
            setFilesToUpload={setFilesToUpload}
            isRestartChecked={isRestartChecked}
            setIsRestartChecked={setIsRestartChecked}
            isSingleFile={areMultipleFiles !== true}
            customWidth={customWidth}
          />
        ) : (
          <>
            <SuccessUploadLocalFiles
              filesToUpload={filesToUpload}
              progress={progress}
              setProgress={setProgress}
              isRestartChecked={isRestartChecked}
              dataFileState={dataFileState}
              setDataFileState={setDataFileState}
              onSuccessSendFile={onSuccessChargeLocalFiles}
              customProperties={customCallProperties}
              dataItem={dataItem}
            />
          </>
        ))}
      {tabValue === "CARGAR ARCHIVOS LOCALES" && !isDataManagementPage && (
        <Stack
          sx={{ alignContent: "center", alignItems: "center", margin: "auto" }}
        >
          <Typography
            sx={{
              color: "var(--blue)",
              fontWeight: "bold",
              fontSize: "22px",
              cursor: "pointer",
              display: "flex",
              marginTop: "auto",
              alignContent: "center",
              alignItems: "center",
              ":hover": {
                textDecoration: "underline",
                color: "var(--light-blue)",
              },
            }}
            onClick={() => {
              navigate("/administracion-datos");
            }}
          >
            Ir a Administración de datos
            <ExitToAppIcon
              sx={{ fontSize: "26px", fontWeight: "bold", marginLeft: "4px" }}
            />
          </Typography>
        </Stack>
      )}
      {tabValue === "EXPLORAR ARCHIVOS" && (
        <>
          <Stack
            direction={"row"}
            width="100%"
            justifyContent={"space-between"}
          >
            <InputLabel sx={{ ...form_label, overflow: "initial !important" }}>
              {areMultipleFiles
                ? "Archivos seleccionados"
                : "Archivo seleccionado"}
            </InputLabel>
          </Stack>
          <Stack direction={"row"} width="100%" alignItems={"center"}>
            <TextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              fullWidth
              label=""
              value={
                typeof selectedFile !== "string"
                  ? convertArrayToText(
                      selectedFile.map((file: any) => file.nombre)
                    )
                  : selectedFile
              }
              placeholder={
                areMultipleFiles
                  ? `Seleccione uno o más archivos`
                  : "Seleccione un archivo"
              }
              autoComplete="off"
              disabled={disableChooseFiles}
              onChange={(e) => setSelectedFile(e.target.value, "typingFile")}
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",

                  color: "var(--blue)",
                },
              }}
              FormHelperTextProps={{
                sx: {
                  color: "var(--magenta)",
                  margin: 0,
                  backgroundColor: "var(--very-very-light-grey)",
                },
              }}
            />
            {disableChooseFiles ? (
              ""
            ) : (
              <Button
                title={"Buscar archivo"}
                color="blue-greeny"
                type="button"
                margin={true}
                onClick={() => setSelectedFile(selectedFile, "chooseFile")}
                disabled={selectedFile.length < 1}
              />
            )}
          </Stack>
          <ExploreFilesTab
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            areMultipleFiles={areMultipleFiles}
            setSelectedFileFolder={setSelectedFileFolder}
            notUnselectFilesOnFolderChange={notUnselectFilesOnFolderChange}
            customInitialRoute={customInitialRoute}
          />{" "}
        </>
      )}
      {tabValue === "CONEXIONES EXTERNAS" && (
        <ExternalConnectionsTab setDataFileState={setDataFileState} />
      )}
    </>
  );
};

export default UploadFileContent;
