import { useEffect, useState } from "react";
import BaseModal from "./BaseModal";
import useApi from "../../../hooks/useApi";
import { FLOW_ITEMS, GET_FLOW_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import ReplaceFont from "../../flowExecution/ReplaceFont";
import Stack from "@mui/material/Stack";
import useDialog from "../../../hooks/useDialog";
import { Typography } from "@mui/material";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { formatFonts, setErrorFlowNodes, setInitialFlowEdges, setInitialFlowNodes } from "../utils";
import { useStore } from "../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";

interface ChangeFontsProps {
  open: boolean;
  handleClose: () => void;
}

const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
  setInitialNodes: store.setInitialNodes,
  setInitialEdges: store.setInitalEdges,
  updateIsFlowLoading: store.updateIsFlowLoading
});

const ChangeFonts = ({ open, handleClose }: ChangeFontsProps) => {
  const store = useStore(selector, shallow);
  const [fonts, setFonts] = useState<any[]>([]);
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();

  useEffect(() => {
    if (open) {
      setFonts(formatFonts(store.nodes));
    }
  }, [open]);

  const onSuccessPutChangeFonts = () => {
    getFlow(GET_FLOW_URL(parseInt(store.flow.id)));
  };
  
  const {
    isLoading: isLoadingChangeFonts,
    callApi: putChangeFonts,
    error: errorPutChangeFonts,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPutChangeFonts,
    undefined,
    false
  );

  const handleChangeFontDataFile = (fontName: string, dataFont: any) => {
    const existingFont = fonts.find((font) => font.nombre === fontName);

    if (existingFont) {
      const updatedFonts = fonts.map((font) => {
        if (font.nombre === fontName) {
          return { ...font, dataFile: dataFont, error: false };
        } else {
          return font;
        }
      });
      setFonts(updatedFonts);
    }
  };

  const handleChangeFontInput = (fontName: string, archivo: any) => {
    const existingFont = fonts.find((font) => font.nombre === fontName);
    if (existingFont) {
      const updatedFonts = fonts.map((font) => {
        if (font.nombre === fontName) {
          return { ...font, archivo: archivo };
        } else {
          return font;
        }
      });
      setFonts(updatedFonts);
    }
  };

  const handleChangeSheet = (
    sheet: string | number | undefined,
    fontName: string
  ) => {
    const existingFont = fonts.find((font) => font.nombre === fontName);

    if (existingFont) {
      const updatedFonts = fonts.map((font) => {
        if (font.nombre === fontName) {
          return { ...font, hoja: sheet };
        } else {
          return font;
        }
      });
      setFonts(updatedFonts);
    }
  };

  /******************************* POSTEAR ITEM *********************************************/

  const onSuccessGetFlow = (data: any) => {
    store.setInitialNodes(setErrorFlowNodes(store.nodes , data.items));

  };

  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  useEffect(() => {
    store.updateIsFlowLoading(isLoadingFlow)
  }, [isLoadingFlow]);

  const handleConfirmChangeFonts = () => {
    toggleConfirmationModal();
    putChangeFonts(
      undefined,
      fonts
        .filter((font) => font.dataFile !== "original")
        .map((font) => {
          return {
            cambiar_fuente: true,
            hoja: font.hoja ? font.hoja : undefined,
            archivo: font.dataFile.id,
            id: font.id,
            editar_item_general: false,
            cambiar_conexiones: false,
            calcular: true,
            editar_detalle_item: false
          };
        })
    );
    handleClose();
  };

  const isFormCompleted = () => {
    return (
      fonts.some((font: any) => {
        return font.dataFile === undefined;
      }) ||
      fonts.some((font: any) => {
        return font.dataFile !== "original" && font.hoja === undefined;
      }) ||
      fonts.some((font: any) => {
        return font.error;
      })
    );
  };

  return (
    <BaseModal
      open={open}
      title="Cambiar fuentes"
      cleanAndClose={handleClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={handleConfirmChangeFonts}
      confirmationModalMessage={"cambiar las fuentes"}
      disabledAcceptButton={isFormCompleted()}
      height="430px"
      width="900px"
    >
      <Stack sx={{ width: "100%", height: "430px", overflowY: "scroll" }}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "700", color: "var(--blue)" }}
        >
          Por defecto, se muestran las fuentes anteriores. Si desea cambiarlas,
          ingrese las nuevas.
        </Typography>
        {fonts &&
          fonts.map((font: any) => {
            return (
              <ReplaceFont
                key={font.id}
                label={font.nombre}
                setFont={handleChangeFontInput}
                error={false} //cambiar
                setSheet={handleChangeSheet}
                setDataFont={handleChangeFontDataFile}
              />
            );
          })}
      </Stack>
    </BaseModal>
  );
};

export default ChangeFonts;
