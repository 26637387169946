import { Stack, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../../hooks/useApi";
import { texts } from "../../../../texts.js";
import {
  FILE_BY_ID,
  FILE_INFO_URL,
  FLOW_ITEMS,
  GET_FLOW_URL,
} from "../../../../api/axios";
import useDialog from "../../../../hooks/useDialog";
import ShadowBox from ".././ShadowBox";
import BaseModal from "../BaseModal";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../../forms/types";
import NewFont from "./NewFont";
import UploadFileContent from "../../../dataManagement/UploadFileModal/UploadFileContent";
import { cutUrl } from "./utils";
import { Button } from "../../../buttons";
import ReplaceFont from "../../../flowExecution/ReplaceFont";
import { useStore } from "../../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import { setErrorFlowNodes, setInitialFlowEdges, setInitialFlowNodes } from "../../utils";

interface AddFontProps {
  open: boolean;
  handleClose: () => void;
  id: string;
  dataItem?: any;
  backendId?: any;
  nodeData?: any;
  setIsLoading?: any
}
const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
  setInitialNodes: store.setInitialNodes,
});

const AddFont = ({
  open,
  handleClose,
  dataItem,
  id,
  backendId,
  nodeData,
  setIsLoading
}: AddFontProps) => {
  const store = useStore(selector, shallow);
  const [file, setFile] = useState<any>("");
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [selectedFileFolder, setSelectedFileFolder] = useState<any>("");
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [dataFileState, setDataFileState] = useState<any>(null);
  const [replaceFont, setReplaceFont] = useState<any>(false);
  const [editFile, setEditFile] = useState<any>("");
  const sheetSelectRef: any = useRef(null);
  const getFileDatacontroller = new AbortController();
  const nodesRef = useRef<any[]>([]);
  nodesRef.current = store.nodes;

  /*************************** CARGA DE DATOS ***************************/
  useEffect(() => {
    if (dataItem && open) {
      const data = dataItem.detalle_item;
      if (data.url !== null) {
        setFile(data.url);
        setEditFile(cutUrl(data.url));
        setDataFileState([
          {
            id: data.archivo.value,
            nombre: dataItem.comentario,
            dataSize: data.cantidad_muestra,
            firstLastRows: data.primeras_ultimas_filas,
            isRandomData: data.muestra_aleatoria,
            rowsNumber:
              data.cantidad_filas === null ? undefined : data.cantidad_filas,
            hoja: data.hoja,
            hojas: data.hojas,
            isEditItem: true,
            cabecera: data?.cabecera
          },
        ]);
      }
    }
  }, [dataItem, open]);

  useEffect(() => {
    sheetSelectRef?.current?.clearValue();
  }, [file]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        //  setDelayHelper(true);
      }, 2);
    } else {
      getFileDatacontroller.abort();
    }
  }, [open]);



  /*************************** LLAMADAS ***************************/

  const onSuccessGetFileInfo = (data: any) => {
    if (!dataItem) {
      setDataFileState((prevState: any) => [data]);
    } else {
      // Si estamos editando el item mantenemos toda la configuracion de numero de filas, muestra aleatoria o predeterminada etc hasta que el user lo cambie
      setDataFileState([
        {
          ...dataFileState[0],
          nombre: dataItem.comentario,
          id: data.id,
          hojas: data.hojas,
          hoja: undefined,
        },
      ]);
    }
  };

  const onErrorGetFileInfo = () => {
    setDataFileState([]);
  };

  const {
    data: dataFile,
    isLoading: isLoadingFilesInfo,
    callApi: getFilesInfo,
    error: errorFile,
    status: statusFile,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    {
      path_archivo: selectedFileFolder,
      directorio: true,
      tipo: "azure",
      origen_descarga: "flow",
      actualizar_archivo: true,
    },
    onSuccessGetFileInfo,
    onErrorGetFileInfo,
    false,
    getFileDatacontroller,
    undefined,
    { params: { refetchOn401: false } }
  );

  const {
    data: dataFileById,
    isLoading: isLoadingFileInfoById,
    callApi: getFilesInfoById,
    error: errorFileInfoById,
  } = useApi(
    FILE_BY_ID,
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessGetFileInfo,
    undefined,
    false,
    undefined,
    undefined
  );

  const onSuccessGetFlow = (data: any) => {
    store.setInitialNodes(setErrorFlowNodes(nodesRef.current , data.items));
  };

  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  // Actualizamos el nodo insertandole el id del backend que es el que vamos a utilizar
  // cuando enviemos datos al back
  const onSuccessPostItem = (data: any) => {
    store.updateNode(id, {
      ...getBody(),
      backendId: backendId ? backendId : data.id,
      error: data.posee_error,
      errorMessage: data.mensaje_error,
    });
    getFlow(GET_FLOW_URL(parseInt(store.flow.id)));
  };

  const {
    isLoading: isLoadingPostNewFont,
    callApi: postNewFont,
    error: errorPostNewFont,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    onErrorGetFileInfo,
    false
  );

  const {
    isLoading: isLoadingPutFont,
    callApi: putFont,
    error: errorPutFont,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    onErrorGetFileInfo,
    false
  );

 // Estos useEffect se utilizan para setear en el padre el estado de cargando 
  useEffect(() => {
    setIsLoading && setIsLoading(isLoadingPostNewFont)
  }, [isLoadingPostNewFont]);

  useEffect(() => {
    setIsLoading && setIsLoading(isLoadingPutFont)
  }, [isLoadingPutFont]);

  /******************************* VALIDACIONES ********************************/

  const isFormCompleted = () => {
    return (
      dataFileState !== null &&
      dataFileState?.length > 0 &&
      (dataFileState[0]?.dataSize === "filas"
        ? dataFileState[0]?.rowsNumber !== "" &&
          dataFileState[0]?.rowsNumber !== undefined
        : true)
    );
  };

  /******************************** HANDLERS ******************************************/
  const handleChangeFileSheet = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>,
    sheetInfoOptionsChangeHandler: any,
    fileInfo: any
  ) => {
    sheetInfoOptionsChangeHandler(newValue, actionMeta);
    const selectedFile = dataFileState.find((file: any) => {
      return file.id === fileInfo.id;
    });
    if (selectedFile) {
      setDataFileState([
        ...dataFileState.filter((file: any) => {
          return file.id !== fileInfo.id;
        }),
        { ...selectedFile, hoja: newValue },
      ]);
    }
  };

  const handleChangeFilesState = (row: any) => {
    setDataFileState(
      dataFileState.map((file: any) => {
        return file.id !== row.id ? file : row;
      })
    );
  };

  const handleChangeFilesStateReplaceFont = (label: string, data: any) => {
    setDataFileState([
      { id: data.id, nombre: dataItem.comentario, hojas: data.hojas },
    ]);
  };

  const handleChangeFileSheetReplaceFont = (
    sheet: string | number | undefined
  ) => {
    dataFileState?.length > 0
      ? setDataFileState([{ ...dataFileState[0], hoja: sheet }])
      : setDataFileState([{ hoja: sheet }]);
  };

  const handleSelectFile = (
    fileState: any[],
    clickedFile: any,
    deletedFile?: boolean
  ) => {
    setSelectedFiles(fileState);
    if (clickedFile !== "delete all") {
      if (deletedFile) {
        setDataFileState((prevState: any) =>
          prevState.filter((file: any) => file.nombre !== clickedFile.nombre)
        );
      } else {
        if (clickedFile && clickedFile !== "typingFile") {
          if (clickedFile.id === null || clickedFile.id === undefined) {
            if (clickedFile !== "all") {
              getFilesInfo(undefined, {
                path_archivo:
                  clickedFile === "chooseFile"
                    ? selectedFiles
                    : `${selectedFileFolder}${clickedFile.nombre}`,
                directorio: false,
                tipo: "azure",
                actualizar_archivo: true,
                flow: true
              });
            } else {
              fileState
                .filter(
                  (file: any) =>
                    !dataFileState
                      .map((f: any) => f.nombre)
                      .includes(file.nombre)
                )
                .forEach((file: any) => {
                  if (file.id === null || file.id === undefined) {
                    getFilesInfo(undefined, {
                      path_archivo: `${selectedFileFolder}${file.nombre}`,
                      directorio: false,
                      tipo: "azure",
                      actualizar_archivo: true,
                      flow: true

                    });
                  } else {
                    getFilesInfoById(FILE_BY_ID(file.id));
                  }
                });
            }
          } else {
            getFilesInfoById(FILE_BY_ID(clickedFile.id));
          }
        } else {
          clickedFile !== "typingFile" && setDataFileState([]);
        }
      }
    }
  };

  const handleReplaceFile = () => {
    setReplaceFont(true);
    if (editFile !== dataItem?.detalle_item.url) {
      getFilesInfo(undefined, {
        path_archivo: editFile,
        directorio: false,
        tipo: "azure",
        origen_descarga: "flow",
        actualizar_archivo: true,
      });
    }
  };


  /******************************** POST STEPPER Y POST ITEM ***********************************/
  const getBody = () => {
    const file = dataFileState[0];
    return {
      tipo: "nueva_fuente",
      archivo: file.id,
      hoja:
        file.hoja === undefined
          ? file.hojas[0].id
          : file.hoja.id === undefined
          ? file.hoja.value
          : file.hoja.id,
      comentario:
        file.isExternalConnection && dataItem
          ? dataItem.comentario
          : file.nombre,
      //  nombre: file.isExternalConnection && dataItem ? dataItem.nombre : file.nombre,
      muestra_aleatoria:
        file.isRandomData !== undefined ? file.isRandomData : false,
      cantidad_muestra:
        file.dataSize !== undefined ? file.dataSize : "predeterminada",
      cantidad_filas:
        file.dataSize === "filas" ? parseInt(file.rowsNumber) : "",
      primeras_ultimas_filas:
        file.firstLastRows !== undefined ? file.firstLastRows : false,
      cabecera:
        file.cabecera !== undefined && file.cabecera !== ""
          ? parseInt(file.cabecera)
          : null,
      parent_ids: [],
      calcular: true,
      editar_detalle_item: true,
      cambiar_fuente: false,
      ...nodeData.position,
    };
  };

  const saveAddFont = () => {
    const newFont = getBody();
    if (!dataItem) {
      postNewFont(undefined, newFont);
    } else {
      putFont(undefined, [{ ...newFont, id: backendId }]);
    }
    cleanAndClose();
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    handleClose();
    getFileDatacontroller.abort();
    setFile("");
    setDataFileState([]);
    setSelectedFileFolder("");
    setSelectedFiles([]);
    setEditFile("");
    setReplaceFont(false);
    if (openConfirmationModal) {
      toggleConfirmationModal();
    }
  };

  return (
    <BaseModal
      open={open}
      title="Nueva fuente"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={saveAddFont}
      confirmationModalMessage={
        dataItem ? "editar la fuente" : "agregar la fuente"
      }
      disabledAcceptButton={!isFormCompleted()}
      height="430px"
    >
      <SimpleBackdrop
        open={isLoadingFileInfoById || isLoadingFilesInfo}
        message={texts.adminPipeline.sendFileUrl.loading}
      />
      <SimpleBackdrop
        open={isLoadingFlow || isLoadingPostNewFont || isLoadingPutFont}
        message={texts.flows.postStepper.loading}
      />
      <ShadowBox margin>
        {dataItem &&
          dataItem?.detalle_item.url !== null &&
          dataItem?.detalle_item.url !== undefined && (
            <Stack
              sx={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                backgroundColor: "var(--very-very-light-grey)",
                borderRadius: "10px",
                padding: "8px",
                alignItems: "center",
              }}
            >
              <TextField
                id="url"
                label={"Archivo original"}
                placeholder={"Archivo original"}
                size="small"
                multiline
                variant="outlined"
                fullWidth
                value={editFile}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEditFile(event.target.value);
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                    width: "70%",
                  },
                }}
                sx={{
                  marginBottom: "8px",
                  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                    height: "0.18rem",
                  },
                }}
              />
              <Button
                title="Reemplazar archivo"
                color="blue"
                type="button"
                onClick={handleReplaceFile}
              />
            </Stack>
          )}
        {(!dataItem || replaceFont) && (
          <UploadFileContent
            selectedFile={selectedFiles}
            setSelectedFile={handleSelectFile}
            dataFileState={dataFileState}
            setDataFileState={setDataFileState}
            setSelectedFileFolder={setSelectedFileFolder}
            areMultipleFiles={false}
            notUnselectFilesOnFolderChange
            dataItem={dataItem}
          />
        )}

        <Stack sx={{ marginTop: "10px" }}>
          {dataFileState &&
            dataFileState.length > 0 &&
            dataItem?.url !== null &&
            dataItem?.url !== undefined && (
              <Stack marginTop="15px">
                {dataFileState.map((row: any) => {
                  return (
                    <NewFont
                      key={row.id}
                      row={row.id}
                      dataFileState={dataFileState}
                      handleChangeFileSheet={handleChangeFileSheet}
                      handleChangeFilesState={handleChangeFilesState}
                      dataItem={dataItem}
                      editName={dataItem === undefined}
                    />
                  );
                })}
              </Stack>
            )}
        </Stack>
        {dataItem && dataItem?.url === null && (
          <ReplaceFont
            label={""}
            setFont={(font: string, chau: any) => {
              console.log(font);
            }}
            error={true}
            setSheet={handleChangeFileSheetReplaceFont}
            setDataFont={handleChangeFilesStateReplaceFont}
            isAddFont={true}
          />
        )}
        {dataFileState &&
          dataFileState.length > 0 &&
          dataItem?.url === undefined && (
            <Stack marginTop="15px">
              {dataFileState.map((row: any) => {
                return (
                  <NewFont
                    key={row.id}
                    row={row.id}
                    dataFileState={dataFileState}
                    handleChangeFileSheet={handleChangeFileSheet}
                    handleChangeFilesState={handleChangeFilesState}
                    dataItem={dataItem}
                    editName
                  />
                );
              })}
            </Stack>
          )}
      </ShadowBox>
    </BaseModal>
  );
};

export default AddFont;
