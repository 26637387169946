import React, { useState } from "react";
import { Stack, Typography, Tooltip, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import PinButton from "../../FlowStepper/PinButton";
import useApi from "../../../../hooks/useApi";
import { FLOW_GROUP, FLOW_ITEMS } from "../../../../api/axios";
import { useStore } from "../store/store";
import shallow from "zustand/shallow";
import { texts } from "../../../../texts";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";

interface NodeCommentProps {
  id: any;
  data: any;
  isGroupNode?: boolean
}

const selector = (store: any) => ({
  flow: store.flow,
  nodes: store.nodes,
  updateNode: store.updateNode,
});

export default function NodeComment({ id, data, isGroupNode }: NodeCommentProps) {
  const store = useStore(selector, shallow);

  const [isEditing, setIsEditing] = useState(false);
  const [comment, setComment] = useState(isGroupNode ? data.nombre :data.comentario);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const onSuccessPutComment = () => {
    if(isGroupNode){
      store.updateNode(id, {
        ...data,
        nombre: comment,
      });
    }else{
      store.updateNode(id, {
        ...data,
        comentario: comment,
      });
    }
  };

  const onErrorPutComment = () => {
    if(isGroupNode){
      store.updateNode(id, {
        ...data,
        nombre: data.nombre,
      });
    }else{
      store.updateNode(id, {
        ...data,
        comentario: data.comentario,
      });
    }
  };

  const { callApi: putComment, isLoading: isLoadingPutItem } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.flows.editComment.codes,
    undefined,
    onSuccessPutComment, 
    onErrorPutComment,
    false
  );

  const { callApi: putCommentGroup, isLoading: isLoadingPutCommentGroup } = useApi(
    FLOW_GROUP(store.flow.id, data.groupId),
    "PUT",
    texts.flows.editComment.codes,
    undefined,
    onSuccessPutComment, 
    onErrorPutComment,
    false
  );

  const handleAccept = () => {
    setIsEditing(false);
    if(isGroupNode){
      putCommentGroup(undefined, 
        {
          nombre: comment,
        },
      );
    }else{
      putComment(undefined, [
        {
          id: parseInt(data.backendId),
          comentario: comment,
          editar_item_general: true,
          cambiar_conexiones: false,
  
        },
      ]);
    } 
  };

  const onInputBlur = () => {
    if(comment?.length > 0){
      handleAccept()

    }
    setTimeout(() => {
      setIsEditing(false);
    }, 1000);
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingPutItem}
        message={texts.flows.editComment.loading}
      />
      <Stack
        sx={{
          alignItems: "center",
          flexDirection: "row",
          width: data.comentario
            ? data.comentario.length < 20
              ? undefined
              : "130px"
            : "50px",
          textAlign: "center",
          maxWidth: "200px",
          "&:hover .edit-icon": {
            opacity: 1, // Show the icon on hover
          },
        }}
      >
        {isEditing ? (
          <>
            <Stack sx={{ width: "100px", flexDirection: "row" }}>
              <TextField
                value={comment}
                onChange={handleInputChange}
                onBlur={onInputBlur}
                size="small"
                sx={{
                  fontSize: "8px",
                  width: "150px",
                  height: "15px",
                  marginRight: "4px",
                  backgroundColor: "white", // Altura del TextField
                  "& .MuiInputBase-input": {
                    fontSize: "8px", // Tamaño del texto dentro del input
                    padding: "1px", // Espaciado interno del input
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "8px", // Tamaño de la etiqueta si está visible
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "15px", // Ajusta la altura del campo de texto
                    "& fieldset": {
                      borderRadius: "2px", // Bordes redondeados del campo si se desea
                    },
                  },
                }}
                autoFocus
              />
            </Stack>
          </>
        ) : (
          <>
            <Typography
              sx={{
                fontSize: "8px",
                cursor: "pointer",
                wordBreak: "break-word",
                maxWidth: "200px",
                textAlign: "center",
                margin: "auto",
              }}
              onClick={handleEditClick}

            >
              {isGroupNode ? data.nombre :data.comentario}
            </Typography>
            {((data.backendId) || (isGroupNode && data.groupId)) && (
              <Tooltip title={isGroupNode ? "Editar nombre":"Editar comentario"}>
                <EditIcon
                  fontSize="small"
                  className="edit-icon"
                  sx={{
                    opacity: 0,
                    transition: "opacity 0.1s",
                    fontSize: "11px",
                    marginBottom: "3px",
                    color: "var(--dark-grey)",
                    cursor: "pointer",
                    marginLeft: "auto",
                  }}
                  onClick={handleEditClick}
                />
              </Tooltip>
            )}
          </>
        )}
      </Stack>
    </>
  );
}
