import {
  Position,
  NodeToolbar,
  useReactFlow,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
} from "@xyflow/react";
import { Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import "@xyflow/react/dist/style.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useCallback, useEffect, useState } from "react";
import RepartitionIcon from "@mui/icons-material/Repartition";
import AddCardIcon from "@mui/icons-material/AddCard";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import PivotTableChartIcon from "@mui/icons-material/PivotTableChart";
import TableViewIcon from "@mui/icons-material/TableView";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import MergeIcon from "@mui/icons-material/Merge";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import AddIcon from "@mui/icons-material/Add";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import useDialog from "../../../../hooks/useDialog";
import PinButton from "../../FlowStepper/PinButton";
import ViewModal from "../../FlowStepper/ViewModal";
import DeleteColumnIcon from "../../../icons/DeleteColumnIcon";
import WarningModal from "./WarningModal";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";

import useApi from "../../../../hooks/useApi";
import { FLOW_GROUP, FLOW_ITEMS, GET_FLOW_URL } from "../../../../api/axios";
import { texts } from "../../../../texts";
import { useStore } from "../store/store";
import shallow from "zustand/shallow";
import { handleSetting, setErrorFlowNodes } from "../../utils";
import { ConfirmationModal } from "../../../dialog";
import { getInitialMeasured, getNodeType } from "../../../../utils/util";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";

interface NodeWithToolbarProps {
  handleEdit: () => void;
  openToolbar?: boolean;
  backendId?: any;
  nodeData?: any;
  isEditable?: boolean;
  activateAddItems?: boolean;
  activateShowResults?: boolean;
  isCalculable?: boolean;
  handleCalculate?: any;
}

const selector = (store: any) => ({
  flow: store.flow,
  nodes: store.nodes,
  setInitialNodes: store.setInitialNodes,
  edges: store.edges,
  onEdgesChange: store.onEdgesChange,
  addEdge: store.addEdge,
  createNode: store.createNode,
});

const NodeWithToolbar = ({
  handleEdit,
  openToolbar,
  backendId,
  nodeData,
  isEditable = true,
  isCalculable,
  handleCalculate,
  activateAddItems = true,
  activateShowResults = true,
}: NodeWithToolbarProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [openViewModal, handleToggleViewModal] = useDialog();
  const [openWarningModal, handleToggleWarningModal] = useDialog();
  const [openDeleteConfirmationModal, handleToggleDeleteConfirmationModal] =
    useDialog();

  const store = useStore(selector, shallow);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = [
    { item: "Setear cabecera", icon: RepartitionIcon },
    { item: "Nueva columna", icon: AddCardIcon },
    {
      item: "Eliminar columna",
      customIcon: (
        <DeleteColumnIcon
          size={"24"}
          color={"var(--greeny)"}
          style={{ marginRight: "4px" }}
        />
      ),
    },
    { item: "Modificar columna", icon: BorderColorIcon },
    { item: "Renombrar columnas", icon: MenuOpenIcon },
    { item: "Dinamizar columnas", icon: PivotTableChartIcon },
    { item: "Desdinamizar columnas", icon: TableViewIcon },
    { item: "Eliminar filas", icon: DeleteSweepIcon },
    { item: "Filtrar", icon: FilterAltIcon },
    { item: "Group By", icon: CalendarViewMonthIcon },
    { item: "Merge", icon: UnfoldLessIcon },
    { item: "Concat", icon: MergeIcon },
    { item: "Calcular", icon: PlayCircleOutlineIcon },
  ];

  const onSuccessChangeItems = () => {
    getDataFlow(GET_FLOW_URL(parseInt(store.flow.id)));
  };

  const { isLoading: isLoadingDeleteNodes, callApi: deleteNodes } = useApi(
    FLOW_ITEMS(store.flow.id),
    "DELETE",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessChangeItems,
    undefined,
    false
  );

  const {
    isLoading: isLoadingGetFlow,
    data: dataFlow,
    callApi: getDataFlow,
  } = useApi(
    GET_FLOW_URL(store.flow.id),
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  const onSuccessDeleteGroup = ()=>{
    store.setInitialNodes([
      ...store.nodes.filter((node: any) => node.id !== nodeData.id),
      ...nodeData.data.nodes.map((node:any)=>{return node.tipo ? {
        id: node.id.toString(),
        type: getNodeType(node.tipo),
        measured: getInitialMeasured(node.tipo),
        position: { x: node.x, y: node.y },
        data: {
          comentario: node.comentario,
          backendId: node.id,
          parentIds: node.parent_ids,
          error: node.posee_error,
          errorMessage: node.mensaje_error,
          color: nodeData.data.color
        },
      } : node ;}),
    ]);
  }

  const { callApi: deleteGroup, isLoading: isLoadingDeleteGroup } = useApi(
    FLOW_GROUP,
    "DELETE",
    texts.flows.deleteGroup.codes,
    undefined,
    onSuccessDeleteGroup,
    undefined,
    false
  );

  // Este use Effect es para que cuando se actualize la data del flow del back se actualice que items tienen error
  useEffect(() => {
    if (dataFlow?.items?.length > 0) {
      setTimeout(() => {
        store.setInitialNodes(setErrorFlowNodes(store.nodes, dataFlow?.items));
      }, 2000);
    }
  }, [dataFlow]);

  const onNodesDelete = useCallback(
    (deleted: any) => {
      store.onEdgesChange(
        deleted.reduce((acc: any, node: any) => {
          const incomers = getIncomers(node, store.nodes, store.edges);
          const outgoers = getOutgoers(node, store.nodes, store.edges);
          const connectedEdges = getConnectedEdges([node], store.edges);

          const remainingEdges = acc.filter(
            (edge: any) =>
              !connectedEdges.map((edge: any) => edge.id).includes(edge.id)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              type: "add",
              item: {
                id: `${source}->${target}`,
                source,
                target,
              },
            }))
          );

          createdEdges.forEach((edge: any) => store.addEdge(edge.item));
          return [...remainingEdges /*...createdEdges*/];
        }, store.edges)
      );
    },
    [store.nodes, store.edges]
  );

  const handleDeleteNodes = (deleted: any) => {
    onNodesDelete(deleted);
    deleteNodes(
      undefined,
      deleted
        .filter((node: any) => node.data.backendId !== undefined)
        .map((node: any) => {
          return {
            id: node.data.backendId,
            // Cuando el item tiene solo una conexion y se elimina se crea una conexion entre su padre y su hijo
            asignar_abuelo:
              /*node.data.parentIds.length > 0 && */ node.type !== "merge" &&
              node.type !== "concat",
          };
        })
    );
  };

  const handleDeleteItem = () => {
    handleDeleteNodes([nodeData]);
    store.setInitialNodes(
      store.nodes.filter((node: any) => node.id !== nodeData.id)
    );
  };

  const handleUngroup = () => {
    deleteGroup(FLOW_GROUP(store.flow.id, nodeData.data.groupId))
  };

  return (
    <NodeToolbar
      isVisible={openToolbar || undefined}
      position={Position.Bottom}
    >
         <SimpleBackdrop
        open={isLoadingDeleteGroup}
        message={texts.flows.deleteGroup.loading}
      />
      <Stack
        gap={0.5}
        sx={{ alignItems: "center", flexDirection: "row", marginTop: "15px" }}
      >
        {" "}
        {isEditable && (
          <PinButton
            Icon={EditIcon}
            tooltipTitle={"Editar item"}
            onClick={() => handleEdit()}
            tooltipPlacement={"bottom"}
          />
        )}
        {backendId && activateShowResults && !nodeData?.data?.error && (
          <PinButton
            Icon={ZoomInIcon}
            tooltipTitle={"Ver resultados"}
            onClick={() => handleToggleViewModal()}
            tooltipPlacement={"bottom"}
          />
        )}
        {isCalculable && (
          <PinButton
            Icon={PlayArrowIcon}
            tooltipTitle={"Calcular"}
            onClick={() => handleCalculate()}
            tooltipPlacement={"bottom"}
          />
        )}
        {activateAddItems && !nodeData?.data.error && (
          <PinButton
            Icon={AddIcon}
            tooltipTitle={""}
            onClick={handleOpenUserMenu}
            tooltipPlacement={"bottom"}
          />
        )}
        {nodeData?.data.error && (
          <PinButton
            Icon={WarningAmberIcon}
            tooltipTitle={"Ver error"}
            onClick={handleToggleWarningModal}
            tooltipPlacement={"bottom"}
          />
        )}
        {nodeData?.type === "node-group" && (
          <PinButton
            Icon={ReplyAllIcon}
            tooltipTitle={"Desagrupar"}
            onClick={handleUngroup}
            tooltipPlacement={"bottom"}
          />
        )}
        <PinButton
          Icon={DeleteIcon}
          tooltipTitle={"Eliminar item"}
          onClick={handleToggleDeleteConfirmationModal}
          tooltipPlacement={"bottom"}
        />
      </Stack>

      <Menu
        sx={{ height: "300px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting.item} onClick={handleCloseUserMenu}>
            <Typography
              textAlign="center"
              sx={{
                color: "var(--greeny)",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() =>
                handleSetting(setting.item, store.createNode, nodeData)
              }
            >
              {setting.customIcon ? (
                setting.customIcon
              ) : (
                <setting.icon sx={{ marginRight: "4px" }} />
              )}

              {setting.item}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <ViewModal
        open={openViewModal}
        handleClose={handleToggleViewModal}
        id={backendId}
      />
      <WarningModal
        open={openWarningModal}
        handleClose={handleToggleWarningModal}
        errorMessage={nodeData?.data?.errorMessage}
      />
      <ConfirmationModal
        open={openDeleteConfirmationModal}
        handleClose={handleToggleDeleteConfirmationModal}
        handleAccept={handleDeleteItem}
        message="eliminar el nodo"
        title="Eliminar nodo"
      />
    </NodeToolbar>
  );
};
export default NodeWithToolbar;
