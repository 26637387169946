import FlowsProvider from "../../../context/FlowsContext";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../hooks/useApi";
import {
  FLOW_ITEMS,
  GET_FLOW_URL,
  NEW_GET_COLUMNS_FLOW_URL,
} from "../../../api/axios";
import {
  checkIfNodeHasPendingEdges,
  filterIdFromColumns,
  setErrorFlowNodes,
  setInitialFlowEdges,
  setInitialFlowNodes,
} from "../../flowConfiguration/utils";
import { texts } from "../../../texts";
import BaseModal from "./BaseModal";
import useDialog from "../../../hooks/useDialog";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import CustomColumnInputFlow from "../CustomColumnInputFlow";
import { getBackendId } from "../../../utils/util";
import { useStore } from "../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
interface RenameColumnssModalProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  id: string;
  backendId?: any;
  parentIds?: any[];
  sourceItemId: string;
  nodeData?: any
}
const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
  setInitialNodes: store.setInitialNodes,
  setInitialEdges: store.setInitialEdges,
  updateIsFlowLoading: store.updateIsFlowLoading
});

const RenameColumns = ({
  open,
  handleClose,
  dataItem,
  id,
  backendId,
  sourceItemId,
  nodeData
}: RenameColumnssModalProps) => {
  const [columnsError, setColumnsError] = useState<string[]>([]);
  const [newObjectNames, setNewObjectNames] = useState<any>();
  const [columns, setColumns] = useState<string[]>([]);
  const [deletedColumns, setDeletedColumns] = useState<string[]>([]);
  const [nonExistentColumns, setNonExistentColumns] = useState<string[]>([]);
  const [openConfirmationModal, handleConfirmationModal] = useDialog();
  const [comment, setComment] = useState<any>("");
  const store = useStore(selector, shallow);
  const nodesRef = useRef<any[]>([]);
  nodesRef.current = store.nodes;
  /************* CARGA DE DATOS ************/

  // Esta función se encarga de filtrar los datos de la respuesta de la API para obtener un array de nombres de columnas.
  const onSuccessGetColumnsData = (data: any) => {
    if (dataItem) {
      const nonExistentColumns: string[] = [];
      const existentColumns = filterIdFromColumns(data).map((col: any) => {
        return col.columna;
      });
      Object.keys(dataItem.detalle_item.renombre_columnas).forEach((col: any) => {
        if (!existentColumns.includes(col)) {
          nonExistentColumns.push(col);
        }
      });
      setNonExistentColumns(nonExistentColumns);
    } else {
      const columnsNames = filterIdFromColumns(data).map((column: any) => {
        return { nombre_viejo: column.columna, nombre_nuevo: column.columna };
      });
      setColumns(columnsNames);
      const newObject: any = {};
      for (const column of columnsNames) {
        newObject[column.nombre_viejo] = column.nombre_viejo;
      }
      setNewObjectNames(newObject);
    }
  };

  const {
    data: dataColumns,
    error: errorColumnsFont,
    isLoading: isLoadingColumnsData,
    callApi: getColumnsData,
  } = useApi(
    undefined,
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetColumnsData,
    undefined,
    false
  );

  // Este useEffect se activa cuando la variable open cambia de estado. Si open está activo, se realiza una serie de operaciones para configurar las columnas y los datos según la situación actual. Si dataItem está disponible, se construye un array de nuevas columnas basadas en sus claves y valores y se configura el estado de las columnas y los nombres de objetos nuevos. Si dataItem no está disponible, se llama a la función getColumnsData para obtener los datos de las columnas.
  useEffect(() => {
    if (open) {
      getColumnsData(
        NEW_GET_COLUMNS_FLOW_URL(
          store.flow.id,
      (getBackendId(sourceItemId, store.nodes) as number)
        )
      );
      if (dataItem) {
        const data = dataItem.detalle_item;
        const newColumnsArray: any[] = [];
        for (const key in data.renombre_columnas) {
          newColumnsArray.push({
            nombre_viejo: key,
            nombre_nuevo: data.renombre_columnas[key],
          });
        }
        setColumns(newColumnsArray);
        setNewObjectNames(data.renombre_columnas);
        setDeletedColumns(data.columnas_eliminadas);
      }
    }
  }, [open, dataItem]);

  /*********** GUARDAR INFO DE INPUTS ***********/

  /**
   * @description Esta función se utiliza para crear una copia de un objeto y asignar un nuevo valor a una propiedad específica identificada por su nombre (clave). La función toma un objeto, una clave (nombre de propiedad) y un nuevo valor, y devuelve una nueva copia del objeto con la propiedad actualizada o sin cambios si la clave no se encuentra en el objeto original.
   * @param {object} object - El objeto original que se clonará y actualizará.
   * @param {string} param - El nombre de la propiedad (clave) que se desea actualizar en el objeto.
   * @param {any} value - El nuevo valor que se asignará a la propiedad identificada por la clave.
   * @returns {object} - Una nueva copia del objeto original con la propiedad actualizada o sin cambios si la clave no se encuentra en el objeto original.
   */

  function saveValueInObject(object: any, param: any, value: any) {
    const newObject = { ...object };
    const clave = Object.keys(newObject).find((key) => key === param);
    if (clave) {
      newObject[clave] = value;
    }
    return newObject;
  }

  /************************* GUARDAR Y ENVIAR DATOS *********************************/

  
  const onSuccessGetFlow = (data: any) => {
    store.setInitialNodes(setErrorFlowNodes(nodesRef.current , data.items));
  };

  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  useEffect(() => {
    store.updateIsFlowLoading(isLoadingFlow)
  }, [isLoadingFlow]);

  const saveRenameColumns = () => {
    handleConfirmationModal();
    const columnsRename = [];
    if (newObjectNames) {
      for (const key in newObjectNames) {
        if (newObjectNames.hasOwnProperty(key)) {
          columnsRename.push({
            nombre_viejo: key,
            nombre_nuevo: newObjectNames[key],
          });
        }
      }
    }
    const completedInfo = {
      tipo: "renombrar_columnas",
      calcular: true,
      editar_detalle_item: true,
      parent_ids: [getBackendId(sourceItemId, store.nodes)],
      comentario: comment,
      id: backendId ? backendId : undefined,
      renombre_columnas: columnsRename.filter((col: any) => {
        return (
          !deletedColumns.includes(col.nombre_viejo) &&
          !nonExistentColumns.includes(col.nombre_viejo)
        );
      }),
      columnas_eliminadas: deletedColumns.filter((col: any) => {
        return !nonExistentColumns.includes(col);
      }),
      ...nodeData.position
    };

    if (dataItem) {
      putRenameColumns(undefined, [completedInfo]);
    } else {
      postRenameColumns(undefined, completedInfo);
    }
    cleanAndClose();
  };
  // Actualizamos el nodo insertandole el id del backend que es el que vamos a utilizar
  // cuando enviemos datos al back
  const onSuccessPostItem = (data: any) => {
    const columnsRename = [];
    if (newObjectNames) {
      for (const key in newObjectNames) {
        if (newObjectNames.hasOwnProperty(key)) {
          columnsRename.push({
            nombre_viejo: key,
            nombre_nuevo: newObjectNames[key],
          });
        }
      }
    }
    store.updateNode(id, {
      renombre_columnas: columnsRename.filter((col: any) => {
        return (
          !deletedColumns.includes(col.nombre_viejo) &&
          !nonExistentColumns.includes(col.nombre_viejo)
        );
      }),
      columnas_eliminadas: deletedColumns.filter((col: any) => {
        return !nonExistentColumns.includes(col);
      }),
      backendId: backendId ? backendId : data.id,
      error: data.posee_error,
      errorMessage: data.mensaje_error,
      comentario: comment,
    });
    checkIfNodeHasPendingEdges(id, putConnection, data.id)
    getFlow(GET_FLOW_URL(parseInt(store.flow.id)));

  };

  const {
    isLoading: isLoadingPostRenameColumns,
    callApi: postRenameColumns,
    error: errorPostRenameColumns,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutRenameColumns,
    callApi: putRenameColumns,
    error: errorPutRenameColumns,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutConnection,
    callApi: putConnection,
    error: errorPutConnection,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  /*************************** LIMPIEZA Y CIERRE ************************************/

  const cleanAndClose = () => {
    handleClose();
    setNewObjectNames({});
    setColumns([]);
    setComment("");
  };
;

  const handleDeleteColumn = (col: string) => {
    if (deletedColumns.includes(col)) {
      setDeletedColumns(
        deletedColumns.filter((column: any) => {
          return col !== column;
        })
      );
    } else {
      setDeletedColumns([...deletedColumns, col]);
    }
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingColumnsData}
        message={texts.flows.getFlowColumns.loading}
      />
      {!isLoadingColumnsData && (
        <BaseModal
          open={open}
          title="Renombrar columnas"
          cleanAndClose={cleanAndClose}
          openConfirmationModal={openConfirmationModal}
          toggleConfirmationModal={handleConfirmationModal}
          handleAccept={saveRenameColumns}
          confirmationModalMessage={"renombrar las columnas"}
          disabledAcceptButton={columnsError.length > 0}
          width={"100%"}
          height={"100%"}
          hasResultsTable
          source_id={getBackendId(sourceItemId, store.nodes)}
        >
          <Stack
            sx={{
              margin: "0 15px 5px 15px",
              width: "95%",
              alignItems: "center",
              minHeight: "150px",
              justifyContent: "space-evenly",
            }}
          >
            {!dataItem &&   <TextField
              id="comment"
              label={"Comentario"}
              placeholder={"Comentario"}
              size="small"
              multiline
              variant="outlined"
              fullWidth
              value={comment}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setComment(event.target.value);
              }}
              FormHelperTextProps={{
                sx: {
                  color: "var(--magenta)",
                },
              }}
              required
            />}
          
       
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: "10px",
                      color: "#172D40",
                      fontSize: "18px",
                      fontWeight: "600",
                      textAlign: "center",
                      alignSelf: "flex-start",
                      marginLeft: "15px",
                    }}
                  >
                    Columnas:
                  </Typography>
                </Box>
                {!!columns &&
                  columns.length > 0 &&
                  columns.map((column: any) => {
                    return (
                      <CustomColumnInputFlow
                        key={column.nombre_viejo}
                        column={column}
                        setColumnsError={setColumnsError}
                        newObjectNames={newObjectNames}
                        setNewObjectNames={setNewObjectNames}
                        saveValueInObject={saveValueInObject}
                        isDeleted={deletedColumns.includes(column.nombre_viejo)}
                        setDeletedColumns={handleDeleteColumn}
                        nonExistentColumn={
                          dataItem &&
                          nonExistentColumns.includes(column.nombre_viejo)
                        }
                        nonExistentColumns={nonExistentColumns}
                      />
                    );
                  })}
              </>
            
          </Stack>
        </BaseModal>
      )}
    </>
  );
};

export default RenameColumns;
