import { Stack, Typography } from "@mui/material";
import Dialog from "../../../dialog/Dialog";

interface WarningModalProps {
  open: boolean;
  handleClose: () => void;
  errorMessage: string
}


const WarningModal = ({ open, handleClose, errorMessage}: WarningModalProps) => {


  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title="Error"
      maxWidth="xl"
    >
      <Stack
        width="100%"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{  width: "100%", minWidth: "400px" }}
      >
     <Typography>
        {errorMessage}
     </Typography>
      </Stack>
    </Dialog>
  );
};

export default WarningModal;
