import Grid from "@mui/material/Grid";
import { DeleteForever } from "../icons";
import Select from "../forms/Select";
import { useContext, useEffect, useRef } from "react";
import { selectMultiStyles, selectStyles } from "../../styles/select.styles";
import SchemasProvider from "../../context/SchemasContext";
import useSingleSelect from "../../hooks/useSingleSelect";
import Stack from "@mui/material/Stack";
import { AddButton } from "../buttons";
import useMultiSelect from "../../hooks/useMultiSelect";
import useApi from "../../hooks/useApi";
import { GET_PIPELINES_URL } from "../../api/axios";
import { texts } from "../../texts";
import { formatOptions } from "../../utils/util";
import { formatSchemaRow, validateRow } from "./utils";
import { typeOptions } from "./constants";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../forms/types";

const SchemaRowSelects = () => {
  const pipelineSelectRef: any = useRef(null);
  const sheetsSelectRef: any = useRef(null);
  const filterTypeSelectRef: any = useRef(null);
  const { schemaState, schemaDispatch } =
    useContext<React.ContextType<typeof SchemasProvider>>(SchemasProvider);
  const {
    sheetsList,
    pipelinesRestList,
    sheetsRestList,
    schema,
    filterTypeRestList,
    allPipelines
  } = schemaState;

  const [
    pipelineSelectedOption,
    setPipelineSelectedOption,
    pipelineOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    sheetsSelectedOptions,
    setSheetsSelectedOption,
    sheetsOptionsChangeHandler,
  ] = useMultiSelect();

  const [
    filterTypeSelectedOption,
    setFilterTypeSelectedOption,
    filterTypeOptionsChangeHandler,
  ] = useSingleSelect();

  const cleanSelects = () => {
    setPipelineSelectedOption(undefined);
    setSheetsSelectedOption(undefined);
    setFilterTypeSelectedOption(undefined);
    pipelineSelectRef.current?.clearValue();
    sheetsSelectRef.current?.clearValue();
    filterTypeSelectRef.current?.clearValue();   
  };

  const handleAddPipeline = () => {
    const newRow = formatSchemaRow(
      pipelineSelectedOption,
      sheetsSelectedOptions,
      filterTypeSelectedOption
    );
  
    
    if (validateRow(schema, newRow, schemaDispatch, schemaState)) {
      const newSchema = schema;
      newSchema.push(newRow);      
      schemaDispatch({
        type: "SET_SCHEMA",
        payload: newSchema,
      });
      schemaDispatch({
        type: "SET_MESSAGE",
        payload: "",
      });
      cleanSelects();
    }
  };

  const handleDeleteAllRows = () => {
    schemaDispatch({
      type: "SET_SCHEMA",
      payload: [],
    });
    schemaDispatch({
      type: "SET_MESSAGE",
      payload: "",
    });
    schemaDispatch({
      type: "SET_SHEETS_REST_LIST",
      payload: sheetsList,
    });
    schemaDispatch({
      type: "SET_PIPELINES_REST_LIST",
      payload: allPipelines ? allPipelines : [],
    });
    schemaDispatch({
      type: "SET_FILTER_TYPE_REST_LIST",
      payload: typeOptions,
    });
    cleanSelects();
  };
  
  const handleChangePipelineSelectedOption = (newValue: SingleValue<IdOption>, actionMeta: ActionMeta<IdOption>)=>{
    pipelineOptionsChangeHandler(newValue, actionMeta)
    schemaDispatch({
      type: "SET_PIPELINE_SELECTED_OPTION",
      payload: newValue,
    });
  }
  return (
    <>
      <Grid item xs={0.5}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <DeleteForever
            tooltipPlacement="bottom"
            onClick={handleDeleteAllRows}
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Select
          reference={pipelineSelectRef}
          styles={selectStyles(pipelineSelectedOption)}
          options={pipelinesRestList}
          onChange={handleChangePipelineSelectedOption}
          closeMenuOnSelect
          placeholder="Elegir pipeline..."
          isClearable
        />
      </Grid>
      <Grid item xs={4.5}>
        <Select
          id="Sheets"
          reference={sheetsSelectRef}
          styles={selectMultiStyles(sheetsSelectedOptions)}
          options={sheetsRestList ? sheetsRestList : []}
          className="cc_select"
          name="bucket"
          onChange={sheetsOptionsChangeHandler}
          closeMenuOnSelect={false}
          isMulti
          placeholder="Todas"
          isClearable
        />
      </Grid>
      <Grid item xs={2}>
        <Select
          reference={filterTypeSelectRef}
          styles={selectStyles(filterTypeSelectedOption)}
          options={filterTypeRestList}
          className="cc_select"
          name="filterType"
          onChange={filterTypeOptionsChangeHandler}
          closeMenuOnSelect
          placeholder="Tipo..."
          isClearable
        />
      </Grid>
      <Grid item xs={1}>
        <AddButton onClick={() => handleAddPipeline()} />
      </Grid>
    </>
  );
};

export default SchemaRowSelects;
