import { Handle, Position } from "@xyflow/react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import useDialog from "../../../../hooks/useDialog";
import NodeWithToolbar from "../nodeToolbar/NodeToolbar";
import DeleteColumnIcon from "../../../icons/DeleteColumnIcon";
import { useStore } from "../store/store";
import { shallow } from "zustand/shallow";
import useApi from "../../../../hooks/useApi";
import { ITEM_URL } from "../../../../api/axios";
import { texts } from "../../../../texts";
import DeleteColumn from "../../Modals/DeleteColumn";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import { isEditable } from "../../../../utils/util";
import CustomHandle from "../handles/CustomHandle";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import NodeComment from "./NodeComment";
import { useEffect } from "react";


interface DeleteColumnNodeProps {
  id: any;
  data: any;
}

const selector = (store: any) => ({
  flow: store.flow,
  nodes: store.nodes,
  isLoadingFlow: store.isLoadingFlow
});

export default function DeleteColumnNode({ id, data }: DeleteColumnNodeProps) {
  const [openDeleteColumnModal, handleToggleDeleteColumn] = useDialog();
  const store = useStore(selector, shallow);
  const node = store.nodes.find((node: any)=> node.id === id)

  useEffect(() => {
    if(!data.backendId && data.parentIds?.length > 0){
      handleToggleDeleteColumn()
    }
   }, [data.parentIds]);

  const onSuccessGetItem = () => {
    handleToggleDeleteColumn();
  };

  const {
    data: dataItem,
    callApi: getDataItem,
    setData: setDataItem,
    isLoading: isLoadingGetItem,
  } = useApi(
    ITEM_URL(store.flow?.id, data?.backendId),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetItem,
    undefined,
    false
  );

  const handleEdit = () => {
    if (data.backendId) {
      getDataItem();
    } else {
      handleToggleDeleteColumn();
    }
  };

  return (
    <>
      <SimpleBackdrop open={isLoadingGetItem} message={"Cargando datos..."} />
      <Stack sx={{ alignItems: "center", width: "45px",height:"64px" }}>

        <NodeWithToolbar
          handleEdit={handleEdit}
          backendId={data.backendId}
          isEditable={isEditable(data.parentIds, store.nodes)}
          nodeData={node}
        />
       <CustomHandle
        type="target"
        position={Position.Left}
        id={`handle-target-2-${id}`}
        connectionCount={1}
        nodeId={id}

      />
         {data.error &&     <WarningAmberIcon
        style={{
          fontSize: 12,
          color: "var(--red)" ,
          position:"absolute",
          top:-12,
          right:5
        }}
      />}
           {store.isLoadingFlow && (
        <CircularProgress
          sx={{
            width: "10px !important",
            height: "10px !important",
            right: "0",
            position: "absolute",
          }}
        />
      )}
        <DeleteColumnIcon size={"36"} color={data.color ? `var(--${data.color})` :"var( --pink)"} />
        <Typography sx={{ fontSize: "8px", marginLeft: "9px" }}>
          Eliminar columna {data.comentario ? ": " : ""}
        </Typography>
        <NodeComment id={id} data={data}/>
        <CustomHandle
        type="source"
        position={Position.Right}
        id={`handle-source-2-${id}`}
        connectionCount={1}
        nodeId={id}

      />
        <DeleteColumn
          handleClose={handleToggleDeleteColumn}
          open={openDeleteColumnModal}
          dataItem={dataItem}
          id={id}
          backendId={data.backendId}
          sourceItemId={data?.parentIds ? data?.parentIds[0] : undefined}
          parentIds={data.parentIds}
          nodeData={node}
        />
      </Stack>
    </>
  );
}