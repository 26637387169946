import Stack from "@mui/material/Stack";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { ListFile } from "./types";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorIcon from "@mui/icons-material/Error";
import LinearDeterminate from "./LinearDeterminate";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { FILE_INFO_URL } from "../../../../api/axios";
import { useLocation } from "react-router";
import { getRoute } from "./utils";
import { cutFileExtension, getFileExtension } from "../../../../utils/util";

interface ISuccessUploadLocalFiles {
  filesToUpload: File[] | null;
  progress: number;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  isRestartChecked: boolean;
  dataFileState: any;
  setDataFileState: any;
  onSuccessSendFile?: any;
  alternativeUrl?: string;
  customProperties?: any;
  dataItem?: any;
}

const SuccessUploadLocalFiles = ({
  filesToUpload,
  progress,
  setProgress,
  isRestartChecked,
  dataFileState,
  setDataFileState,
  onSuccessSendFile,
  alternativeUrl,
  customProperties,
  dataItem,
}: ISuccessUploadLocalFiles) => {
  const { t } = useLanguage();
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const [counter, setCounter] = useState(0);
  const [filesList, setFilesList] = useState<ListFile[] | undefined>(
    filesToUpload?.map((file: File) => {
      return {
        file: file,
        nombre: file.name,
        size: file.size,
        messageType: "",
        message: "",
        status: "pending",
        cancel: true,
      };
    })
  );

  const controller = new AbortController();
  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const insertFiles = async (
    fileToUpload: any,
    axiosPrivate: any,
    handleUploadFileResponse: any,
    isRestartChecked: boolean
  ) => {
    try {
      const formData = new FormData();
      formData.append("archivo", fileToUpload);
      formData.append("tipo", "archivo_local");
      formData.append(
        "ruta_destino",
        decodeURIComponent(getRoute(location.pathname))
      );
      formData.append(
        "carga_datos_detalle",
       true as unknown as string
      );
      customProperties &&
        customProperties.forEach((property: any) => {
          formData.append(
            Object.keys(property)[0],
            Object.values(property)[0] as string
          );
        });
      const response = await axiosPrivate.post(
        alternativeUrl ? alternativeUrl : FILE_INFO_URL,
        formData,
        {
          signal: controller.signal,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      handleUploadFileResponse(response.status, response.data);
    } catch (error: any) {
      if (error.code === "ERR_NETWORK") {
        handleUploadFileResponse(502, fileToUpload);
      } else {
        handleUploadFileResponse(500, fileToUpload);
      }
    }
  };

  useEffect(() => {
    const fileToSend = filesList?.find((f: ListFile) => f.status === "pending");
    if (fileToSend) {
      setFilesList((prev) =>
        prev?.map((file: ListFile) => {
          if (file.nombre === fileToSend.nombre) {
            return {
              ...file,
              status: "fetching",
              cancel: false,
            };
          } else {
            return file;
          }
        })
      );
      insertFiles(
        fileToSend.file,
        axiosPrivate,
        handleUploadFileResponse,
        isRestartChecked
      );
    }
  }, [counter]);

  const handleUploadFileResponse = (status: number, data: any) => {
    if (status === 200 || status === 201) {
      setFilesList((prev) =>
        prev?.map((file: ListFile) => {
          if (
            cutFileExtension(file.nombre) +
              getFileExtension(file.nombre).toLowerCase() ===
            (data.nombre !== undefined ? data.nombre : data.nombre_archivo)
          ) {
            return {
              ...file,
              status: "success",
              messageType: "success",
              message: "El archivo fue cargado exitosamente.",
              ...data,
            };
          } else {
            return file;
          }
        })
      );
      setCounter(counter + 1);
      setDataFileState((prev: any[]) => {
        return prev !== null &&
          prev?.length > 0 &&
          prev[0]?.isEditItem === undefined
          ? [...prev, data]
          : [{ ...data, nombre: dataItem ? dataItem.nombre : data.nombre }];
      });
      onSuccessSendFile && onSuccessSendFile(data);
    } else if (status === 502 || status === 500) {
      setFilesList((prev) =>
        prev?.map((file: ListFile) => {
          if (file.nombre === data?.name) {
            return {
              ...file,
              status: "error",
              messageType: "danger",
              message: t(
                "dataManagementTexts.successUploadLocalFiles.connectionError"
              ),
            };
          } else {
            return file;
          }
        })
      );
      setCounter(counter + 1);
    } else {
      data &&
        data[0] &&
        setFilesList((prev) =>
          prev?.map((file: ListFile) => {
            if (file.nombre === data?.name) {
              return {
                ...file,
                status: "error",
                messageType: "danger",
                message: t(
                  "dataManagementTexts.successUploadLocalFiles.connectionError"
                ),
              };
            } else {
              return file;
            }
          })
        );
      setCounter(counter + 1);
    }
  };

  const cancelUpload = (file: ListFile) => {
    setFilesList((prev) =>
      prev?.map((f: ListFile) => {
        if (f.nombre === file.nombre) {
          return {
            ...f,
            status: "canceled",
            cancel: false,
          };
        } else {
          return f;
        }
      })
    );
    setDataFileState(
      dataFileState?.filter((f: any) => {
        return f.nombre !== file.nombre;
      })
    );
  };

  const reUpload = (file: ListFile) => {
    setFilesList((prev) =>
      prev?.map((f: ListFile) => {
        if (f.nombre === file.nombre) {
          return {
            ...f,
            status: "pending",
            messageType: "",
            message: "",
            cancel: true,
          };
        } else {
          return f;
        }
      })
    );
    setCounter(counter + 1);
  };

  return (
    <Stack width="100%" sx={{ overflow: "auto", minHeight: "190px" }}>
      <Typography
        variant="body2"
        sx={{
          fontWeight: "bold",
          color: "var(--blue)",
          textAlign: "start",
          marginBottom: "15px",
        }}
      >
        {t("dataManagementTexts.successUploadLocalFiles.stopLoad")}
      </Typography>
      <LinearDeterminate
        filesList={filesList}
        progress={progress}
        setProgress={setProgress}
      />
      <Stack sx={{ overflow: "auto" }}>
        {filesToUpload && filesToUpload.length && (
          <>
            <List dense={true}>
              {filesList?.map((file: any) => {
                return (
                  <ListItem
                    key={file.nombre}
                    secondaryAction={
                      <Stack direction="row" spacing={2} alignItems="center">
                        {file.status === "success" &&
                          file.messageType === "success" && (
                            <DoneIcon
                              sx={{
                                color: "var(--blue-greeny) !important",
                                fontSize: "30px",
                              }}
                            />
                          )}
                        {file.status === "canceled" && (
                          <ClearIcon
                            sx={{
                              color: "var(--very-light-grey) !important",
                              fontSize: "30px",
                            }}
                          />
                        )}
                        {(file.status === "error" ||
                          file.messageType === "danger") && (
                          <ErrorIcon
                            sx={{
                              color: "#fb6e92 !important",
                              fontSize: "30px",
                            }}
                          />
                        )}
                        {file.status === "pending" && (
                          <HourglassEmptyIcon
                            sx={{
                              fontSize: "30px",
                              color: "var(--light-grey) !important",
                            }}
                          />
                        )}
                        {file.status === "fetching" && (
                          <Stack>
                            <CircularProgress
                              size={30}
                              sx={{
                                "& svg": {
                                  color: "var(--light-blue) !important",
                                },
                              }}
                            />
                          </Stack>
                        )}
                        <Button
                          variant="contained"
                          sx={{
                            width: "108px",
                            backgroundColor:
                              file.status !== "canceled" &&
                              file.status !== "error" &&
                              file.status !== "success"
                                ? "var(--dark-grey)"
                                : "var(--light-blue)",
                          }}
                          onClick={
                            file.status !== "canceled" &&
                            file.status !== "error"
                              ? () => cancelUpload(file)
                              : () => reUpload(file)
                          }
                          disabled={file.status === "fetching" ? true : false}
                        >
                          {file.status === "canceled"
                            ? t(
                                "dataManagementTexts.successUploadLocalFiles.charge"
                              )
                            : file.status === "error"
                            ? t(
                                "dataManagementTexts.successUploadLocalFiles.reTry"
                              )
                            : file.status === "success"
                            ? t(
                                "dataManagementTexts.successUploadLocalFiles.delete"
                              )
                            : t(
                                "dataManagementTexts.successUploadLocalFiles.cancel"
                              )}
                        </Button>
                      </Stack>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor:
                            file.status === "pending" ||
                            file.status === "fetching"
                              ? "var(--light-grey) !important"
                              : file.status === "canceled"
                              ? "var(--very-light-grey)"
                              : file.status === "error" ||
                                file.messageType === "danger"
                              ? "#fb6e92 !important"
                              : "#73AAB8 !important",
                        }}
                      >
                        <FolderIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{
                        color:
                          file.status === "pending" ||
                          file.status === "fetching" ||
                          file.status === "canceled"
                            ? "var(--light-grey) !important"
                            : file.status === "error" ||
                              file.messageType === "danger"
                            ? "var(--magenta) !important"
                            : "var(--blue-greeny) !important",
                        fontWeight: "bold",
                        maxWidth: "340px",
                      }}
                      primary={
                        <p
                          style={{
                            fontWeight: "bold",
                            margin: 0,
                            padding: 0,
                          }}
                        >{`${file.nombre.toUpperCase()}`}</p>
                      }
                      secondary={
                        file.status === "canceled"
                          ? t(
                              "dataManagementTexts.successUploadLocalFiles.chargeAbort"
                            )
                          : file.status === "success" &&
                            file.messageType === "success"
                          ? file.message
                          : file.status === "success" &&
                            file.messageType === "danger"
                          ? "Error - " + file.message
                          : file.status === "error"
                          ? t(
                              "dataManagementTexts.successUploadLocalFiles.errorFileNotLoaded"
                            )
                          : file.status === "fetching"
                          ? file.size +
                            ` bytes - ${t(
                              "dataManagementTexts.successUploadLocalFiles.loading"
                            )}`
                          : file.size +
                            ` bytes - ${t(
                              "dataManagementTexts.successUploadLocalFiles.waiting"
                            )}`
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default SuccessUploadLocalFiles;
