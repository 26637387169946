interface DeleteColumnIconProps {
  color: string;
  size: string;
  style?: any
}

export default function DeleteColumnIcon({
  color,
  size,
  style
}: DeleteColumnIconProps) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 2048 2009"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      style={style? style : undefined}
    >
      <path
        transform="translate(0)"
        d="m0 0h2048v2009h-2048z"
        fill="transparent"
      />
      <path
        transform="translate(1046,1259)"
        d="m0 0 4 1 38 38 14 11 11 8 16 9 17 8 21 7 17 4 14 2 14 1h15l22-2 20-4 20-6 18-8 17-9 6-4h2v648l-3 12-6 10-8 7-8 4-6 2h-505l-10-3-8-5-5-4-7-11-2-6-1-11v-643l4 1 21 12 24 10 22 6 19 3 14 1h16l21-2 24-5 21-7 25-12 17-11 10-8 14-12 23-23 5-6h2z"
        fill={color}
      />
      <path
        transform="translate(872,630)"
        d="m0 0 13 1 11 4 9 6 140 140 4 1 141-141 11-7 9-3 13-1 14 3 12 7 51 51 2 1v2l4 2 5 5 9 10 6 9 4 10 1 5v11l-3 12-4 8-9 10-136 136 1 4 57 57 6 5v2l4 2 5 5 1 3 3 1v2h2l60 60 9 13 3 9 1 6v10l-3 12-5 9-9 10-64 64-11 7-10 3h-18l-11-4-8-5-12-11-18-18v-2l-3-1v-2l-4-2-105-105v-2l-4 1-142 142-10 6-10 3h-18l-11-4-9-6-12-12-3-2v-2l-3-1v-2l-3-1-7-8-6-5v-2l-3-1-4-5-5-4-4-5-8-7-11-11-6-8-5-11-2-11 1-11 4-12 8-11 139-139v-5l-4-2-136-136-7-10-4-12-1-10 2-12 7-14 12-13 58-58 13-9 9-3z"
        fill={color}
      />
      <path
        transform="translate(1577,664)"
        d="m0 0h363l13 3 11 7 8 11 3 7 1 5v368l-2 9-5 9-6 7-10 6-11 3-355 1-13-1-11-4-9-6-8-9-4-10-1-8v-362l2-10 4-8 9-10 11-6z"
        fill={color}
      />
      <path
        transform="translate(1572,14)"
        d="m0 0h372l12 4 8 6 6 7 5 11 1 5v366l-2 10-5 10-8 8-12 6-4 1-34 1h-332l-11-2-13-7-8-9-4-8-2-12v-362l2-9 5-10 6-7 9-6z"
        fill={color}
      />
      <path
        transform="translate(1573,1315)"
        d="m0 0h370l8 2 10 5 7 7 5 8 3 11v367l-3 12-6 9-9 8-9 4-4 1-48 1h-319l-12-3-11-6-8-9-4-8-2-13v-360l2-10 5-10 5-6 8-6 7-3z"
        fill={color}
      />
      <path
        transform="translate(155,664)"
        d="m0 0h362l11 2 10 5 6 5 6 9 3 7 1 5v369l-3 10-6 10-8 7-12 5-4 1-356 1-13-1-11-4-9-6-7-8-4-8-1-4v-375l4-10 6-8 7-6 8-4z"
        fill={color}
      />
      <path
        transform="translate(151,14)"
        d="m0 0h371l10 3 9 6 7 8 4 8 2 7v369l-4 13-6 8-8 7-9 4-4 1-35 1h-331l-10-2-12-6-5-4-6-8-4-10v-375l4-10 4-6 5-5 5-4 8-4z"
        fill={color}
      />
      <path
        transform="translate(151,1315)"
        d="m0 0h371l10 3 9 6 7 8 4 8 2 7v369l-4 13-6 8-8 7-9 4-4 1-53 1h-313l-10-2-12-6-6-5-6-9-3-8v-376l4-10 7-9 9-6 6-3z"
        fill={color}
      />
      <path
        transform="translate(896,262)"
        d="m0 0h303l103 1 12 5 10 9 5 8 3 12v215l-5-2-15-9-17-8-24-8-20-4-18-2h-26l-23 3-21 5-15 5-18 8-14 8-17 12-14 12-36 36-4-2-5-5-12-11-9-9-7-8-8-7-17-13-13-8-19-10-22-8-21-5-24-3h-26l-23 3-21 5-22 8-23 12-7 5h-3v-212l2-11 5-10 7-8 10-6 6-2z"
        fill={color}
      />
    </svg>
  );
}

