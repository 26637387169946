import { Stack, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { texts } from "../../../texts.js";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import {
  FLOW_ITEMS,
  GET_FLOW_URL,
  NEW_VIEW_RESULTS_URL,
} from "../../../api/axios";
import {
  checkIfNodeHasPendingEdges,
  initialFilterData,
  setErrorFlowNodes,
  setInitialFlowEdges,
  setInitialFlowNodes,
  transformDataToSend,
} from "../utils";
import useDialog from "../../../hooks/useDialog";
import FilterTable from "../../tablesTools/FilterTable";
import React from "react";
import { GridFilterModel, GridLinkOperator } from "@mui/x-data-grid-pro";
import BaseModal from "./BaseModal";
import { getBackendId } from "../../../utils/util";
import { useStore } from "../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
import ShadowBox from "./ShadowBox";
interface FilterProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  id: string;
  backendId?: any;
  parentIds?: any[];
  sourceItemId: string;
  nodeData?: any;
}
const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
  setInitialNodes: store.setInitialNodes,
  setInitialEdges: store.setInitialEdges,
  updateIsFlowLoading: store.updateIsFlowLoading
});

const Filter = ({
  open,
  handleClose,
  dataItem,
  id,
  backendId,
  sourceItemId,
  nodeData,
}: FilterProps) => {
  const [comment, setComment] = useState<any>("");
  const store = useStore(selector, shallow);
  const nodesRef = useRef<any[]>([]);
  nodesRef.current = store.nodes;
  const [openConfirmFilterModal, toggleConfirmFilterModal] = useDialog();
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>(
    initialFilterData(dataItem?.detalle_item)
  );
  const [hasCalledGetViewResults, setHasCalledGetViewResults] = useState(false);

  /************************************* CARGA DE DATOS ********************************/
  useEffect(() => {
    if (open && !hasCalledGetViewResults) {
      setHasCalledGetViewResults(true);
      getTableData();
    }
  }, [open]);

  useEffect(() => {
    if (open && dataItem) {
      setComment(dataItem.comentario);
      setFilterModel(initialFilterData(dataItem.detalle_item));
    }
  }, [open, dataItem]);

  const {
    data: tableData,
    isLoading: isLoadingTableData,
    callApi: getTableData,
    setData: setTableData,
  } = useApi(
    NEW_VIEW_RESULTS_URL(
      store.flow.id,
      getBackendId(sourceItemId, store.nodes) as number
    ),
    "GET",
    texts.flows.viewResults.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  /************************************ POSTEAR ITEM **********************************/

  const onSuccessGetFlow = (data: any) => {
    store.setInitialNodes(setErrorFlowNodes(nodesRef.current , data.items));
  };

  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  useEffect(() => {
    store.updateIsFlowLoading(isLoadingFlow)
  }, [isLoadingFlow]);


  const handleApplyFilters = () => {
    toggleConfirmFilterModal();
    const completedInfo = {
      calcular: true,
      editar_detalle_item: true,
      parent_ids: [getBackendId(sourceItemId, store.nodes)],
      comentario: comment,
      id: backendId ? backendId : undefined,
      ...transformDataToSend(filterModel),
      ...nodeData.position,
    };
    if (dataItem) {
      putFilter(undefined, [completedInfo]);
    } else {
      postFilter(undefined, completedInfo);
    }
    cleanAndClose();
  };

  // Actualizamos el nodo insertandole el id del backend que es el que vamos a utilizar
  // cuando enviemos datos al back
  const onSuccessPostItem = (data: any) => {
    store.updateNode(id, {
      ...transformDataToSend(filterModel),
      backendId: backendId ? backendId : data.id,
      error: data.posee_error,
      errorMessage: data.mensaje_error,
      comentario: comment,
    });
    checkIfNodeHasPendingEdges(id, putConnection, data.id)

    getFlow(GET_FLOW_URL(parseInt(store.flow.id)));

  };

  const {
    isLoading: isLoadingPostFilter,
    callApi: postFilter,
    error: errorPostFilter,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutFilter,
    callApi: putFilter,
    error: errorPutFilter,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutConnection,
    callApi: putConnection,
    error: errorPutConnection,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );


  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    setHasCalledGetViewResults(false);
    setComment("");
    /*setFilterModel({
      items: [
        {
          id: "",
          columnField: "",
          operatorValue: "",
          value: [],
        },
      ],
      linkOperator: GridLinkOperator.And,
    });*/
    setTableData([]);
    handleClose();
  };

  const validateData = () => {
    const result = filterModel.items.some((item: any) => {
      const keys = Object.keys(item);
      return keys.find((key) => key === "value") === undefined;
    });
    if (result) {
      return true;
    }
    if (
      filterModel.items.every((item: any) => {
        return item.value === "";
      })
    ) {
      return true;
    }
    if (filterModel.items.length < 1) {
      return true;
    }
    return false;
  };

  function modificarClaves(
    obj: Record<string, string>
  ): Record<string, string> {
    const nuevoObj: Record<string, string> = {}; // Creamos un nuevo objeto para almacenar las claves modificadas

    for (const clave in obj) {
      nuevoObj["id"] = obj["id"];
      if (obj.hasOwnProperty(clave) && clave.includes("(")) {
        const nuevaClave = clave.substring(0, clave.lastIndexOf("(")); // Modificamos la clave
        nuevoObj[nuevaClave] = obj[clave]; // Asignamos el valor original a la nueva clave
      }
    }

    return nuevoObj; // Devolvemos el nuevo objeto con las claves modificadas
  }


  return (
    <BaseModal
      open={open}
      title="Filtrar"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmFilterModal}
      toggleConfirmationModal={toggleConfirmFilterModal}
      handleAccept={handleApplyFilters}
      confirmationModalMessage={"aplicar los filtros permanentemente"}
      disabledAcceptButton={validateData()}
      width="1300px"
      height="445px"
    >
      <SimpleBackdrop
        open={isLoadingTableData}
        message={texts.flows.getFilterData.loading}
      />
      <Stack alignItems="center" justifyContent={"space-between"}>
        {!dataItem &&  <ShadowBox margin>
          <TextField
            id="comment"
            label={"Comentario"}
            placeholder={"Comentario"}
            size="small"
            multiline
            variant="outlined"
            fullWidth
            value={comment}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setComment(event.target.value);
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
              },
            }}
            required
          />
        </ShadowBox>}
       
        <Stack sx={{ minWidth: "1300px", height: "450px", overflowY: "auto", width:"95%"  }}>
          {tableData && (
            <FilterTable
              columns={
                transformDataSimpleTable(
                  tableData?.map((item: any) => modificarClaves(item)),
                  undefined,
                  undefined,
                  undefined,
                  tableData
                ).columns
              }
              rows={
                transformDataSimpleTable(
                  tableData?.map((item: any) => modificarClaves(item))
                ).rows
              }
              toolbar={true}
              exportButton={false}
              filterButton={true}
              filterModel={filterModel}
              setFilterModel={setFilterModel}
            />
          )}
        </Stack>
      </Stack>
    </BaseModal>
  );
};

export default Filter;
