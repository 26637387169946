import { Handle, Position } from "@xyflow/react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import useDialog from "../../../../hooks/useDialog";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import NodeWithToolbar from "../nodeToolbar/NodeToolbar";
import { useStore } from "../store/store";
import { shallow } from "zustand/shallow";
import useApi from "../../../../hooks/useApi";
import { ITEM_URL } from "../../../../api/axios";
import { texts } from "../../../../texts";
import Merge from "../../Modals/Merge";
import { isEditable } from "../../../../utils/util";
import CustomHandle from "../handles/CustomHandle";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import NodeComment from "./NodeComment";
import { useEffect, useState } from "react";
import { getRandomColor } from "../../utils";


interface MergeNodeProps {
  id: any;
  data: any;
}
const selector = (store: any) => ({
  flow: store.flow,
  nodes: store.nodes,
  currentColors: store.currentColors,
  updateColors: store.updateColors,
  updateNode: store.updateNode,
  isLoadingFlow: store.isLoadingFlow
});

export default function MergeNode({ id, data }: MergeNodeProps) {
  const [openMergeModal, handleToggleEditMergeModal] = useDialog();
  const [color, setColor] = useState<string | null>("");

  const store = useStore(selector, shallow);
  const onSuccessGetItem = () => {
    handleToggleEditMergeModal();
  };
  const node = store.nodes.find((node: any)=> node.id === id)
  
  useEffect(() => {
    const newColor =getRandomColor(store.currentColors)
    setColor(newColor)
    store.updateColors([...store.currentColors,  newColor])
    store.updateNode(id, {...node.data, color: newColor})

 }, [])

 useEffect(() => {
  if(!data.backendId && data.parentIds?.length > 1){
    handleToggleEditMergeModal()
  }
}, [data.parentIds])


  const {
    data: dataItem,
    callApi: getDataItem,
    setData: setDataItem,
    isLoading: isLoadingGetItem,
  } = useApi(
    ITEM_URL(store.flow?.id, data?.backendId),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetItem,
    undefined,
    false
  );

  const handleEdit = () => {
    if (data.backendId) {
      getDataItem();
    } else {
      handleToggleEditMergeModal();
    }
  };
  return (
    <Stack sx={{ alignItems: "center", width: "45px",height:"64px" }}>
            <SimpleBackdrop open={isLoadingGetItem} message={"Cargando datos..."} />

      <NodeWithToolbar  handleEdit={handleEdit}
        backendId={data.backendId}
        isEditable={data?.parentIds?.length === 2 && isEditable(data?.parentIds, store.nodes)}
        nodeData={node}
      />
      <CustomHandle
        type="target"
        position={Position.Left}
        id={`handle-target-2-${id}`}
        connectionCount={2}
        nodeId={id}
      /> {data.error &&     <WarningAmberIcon
        style={{
          fontSize: 12,
          color: "var(--red)" ,
          position:"absolute",
          top:-5,
          right:5
        }}
      />}
           {store.isLoadingFlow && (
        <CircularProgress
          sx={{
            width: "10px !important",
            height: "10px !important",
            right: "0",
            position: "absolute",
          }}
        />
      )}
      <UnfoldLessIcon
        style={{
          fontSize: 40,
          color: `var(--${color})`, 
        }}
      />
      <Typography sx={{ fontSize: "8px", marginLeft: "2px" }}>
      Merge {data.comentario ? ": " : ""}
      </Typography>
      <NodeComment id={id} data={data}/>
      <CustomHandle
        type="source"
        position={Position.Right}
        id={`handle-source-2-${id}`}
        connectionCount={1}
        nodeId={id}

      />
        <Merge
        handleClose={handleToggleEditMergeModal}
        open={openMergeModal}
        dataItem={dataItem}
        id={id}
        backendId={data.backendId}
        parentIds={data.parentIds}
        nodeData={node}

      />
    </Stack>
  );
}
