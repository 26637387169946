import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Dialog from "../../dialog/Dialog";
import SelectResourceLabel from "./SelectResourceLabel";
import { SingleValue } from "react-select";
import { IdOption } from "../../forms/types";
import { Button } from "../../buttons";
import useApi from "../../../hooks/useApi";
import { DATA_URL, FILE_INFO_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";

interface EditResourceLabelModalProps {
  open: boolean;
  name: string;
  route: string;
  type: string;
  handleClose: (type: string) => void;
  selectedLabel?: any;
  refreshResources?: () => void;
}

const EditResourceLabelModal = ({
  open,
  name,
  selectedLabel,
  route,
  type,
  handleClose,
  refreshResources,
}: EditResourceLabelModalProps) => {
  const [label, setLabel] = useState<SingleValue<IdOption> | undefined>();
  const [delayHelper, setDelayHelper] = useState<boolean>(false);
  /*************************** CARGA DE DATOS ***************************/

  useEffect(() => {
    if (open) {
      selectedLabel?.nombre !== undefined &&
        setLabel({
          value: selectedLabel.id as number,
          label: selectedLabel.nombre as string,
        } as SingleValue<IdOption>);
      setDelayHelper(true);
    }
  }, [open]);

  /********************************* LLAMADAS ************************************************/
  const {
    isLoading: isLoadingEditResourceLabel,
    callApi: callEditResourceLabel,
  } = useApi(
    DATA_URL,
    "PUT",
    texts.dataManagement.editResource.codes,
    {
      lista_recursos: [
        { ruta: `${route}${name}`, etiqueta: label === null ? "" : label?.value, tipo: type },
      ],
    },
    () => {
      refreshResources && refreshResources();
    },
    undefined,
    false
  );

  const {
    isLoading: isLoadingPostFileLabel,
    callApi: postFileLabel,
    error: errorPutFileLabel,
  } = useApi(
    FILE_INFO_URL,
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    {
      path_archivo: `${route}${name}`,
      directorio: false,
      etiqueta: label?.value,
      tipo: "azure",
      actualizar_archivo: false
    },
    () => {
      refreshResources && refreshResources();
    },
    undefined,
    false,
    undefined,
    undefined,
    { params: { refetchOn401: false } }
  );
  /******************************* LIMPIEZA DE DATOS, VALIDACIONES *******************************/
  const cleanAndClose = (type: string) => {
    setLabel(undefined);
    setDelayHelper(false);
    handleClose(type);
  };

  const handleAccept = () => {
    type === "archivo" && selectedLabel?.nombre === undefined
      ? postFileLabel()
      : callEditResourceLabel();
    cleanAndClose(
      type === "archivo" && selectedLabel === undefined ? "cancelar" : "aceptar"
    );
  };
  return (
    <>
      <SimpleBackdrop
        open={isLoadingEditResourceLabel || isLoadingPostFileLabel}
        message={texts.dataManagement.editResource.loading}
      />
      <Dialog
        open={open}
        handleClose={() => handleClose("cancelar")}
        title={`Asignar etiqueta a ${name}`}
        maxWidth="xl"
      >
        <Stack
          sx={{ height: "250px", minWidth: "400px", alignItems: "center" }}
        >
          {delayHelper && (
            <SelectResourceLabel setLabel={setLabel} selectedLabel={label} />
          )}
          <Stack
            direction="row"
            sx={{
              alignSelf: "center",
              marginTop: "auto",
              justifyContent: "center",
            }}
          >
            <Button
              title="Cerrar"
              type="button"
              onClick={() => {
                cleanAndClose("cerrar");
              }}
              color="light-grey"
            />
            <Button
              title="Aceptar"
              type="button"
              onClick={() => {
                handleAccept();
              }}
              color="blue"
            />
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default EditResourceLabelModal;
