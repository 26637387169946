import { Checkbox, Stack, TextField, Tooltip } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "../forms/Select";
import { selectStyles } from "../../styles/select.styles";
import useSingleSelect from "../../hooks/useSingleSelect";
import { Button } from "../buttons";
import useApi from "../../hooks/useApi";
import {
  CALC_FLOW_URL,
  FLOW_CONFIGURATION,
  FLOW_CONFIGURATION_BY_ID,
  FLOW_URL,
  GET_COLUMNS_FLOW_URL,
  GET_FLOW_URL,
} from "../../api/axios";
import { texts } from "../../texts.js";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import ComponentLayout from "../layout/ComponentLayout";
import { ConfirmationModal } from "../dialog";
import useDialog from "../../hooks/useDialog";
import { fileFormatOptions, formatOptions, formatTemplatePhiName, getMonthFromTemplatePhiName, getYearFromTemplatePhiName } from "../../utils/util";
import { form_label } from "../../styles/app-styles";
import { useNavigate } from "react-router-dom";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { useEffect, useRef, useState } from "react";
import { useApiQuery } from "../../hooks/useApiQuery";
import ReplaceFont from "./ReplaceFont";
import InfoIcon from "@mui/icons-material/Info";
import { formatColumns } from "../fileColumns/utils";
import {
  filterIdFromColumns,
  getSteppersOptions,
  isFileNameValid,
} from "../flowConfiguration/utils";
import ColorTabs from "../colorTabs/ColorTabs";
import { Tab, TabValue } from "../colorTabs/types";
import ExploreFolders from "../dataManagement/UploadFileModal/ExploreFolders";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../forms/types";
import SaveNameModal from "../dialog/SaveNameModal";
import PhiTemplateConfigurationTab from "../flowConfiguration/Modals/CalculationConfigs/PhiTemplateConfigurationTab";

const FlowExecution = () => {
  const navigate = useNavigate();
  const [openConfirmExecuteFlowModal, toggleConfirmExecuteFlowModal] =
    useDialog();
  const [fonts, setFonts] = useState<any[]>([]);
  const [executionName, setExecutionName] = useState<any>("");
  const flowSelectRef: any = useRef(null);
  const formatSelectRef: any = useRef(null);
  const columnSelectRef: any = useRef(null);
  const configurationRef: any = useRef(null);
  const templatePhiNameRef: any = useRef(null);
  const monthSelectRef: any = useRef(null);
  const yearSelectRef: any = useRef(null);

  const stepperToDeconsolidateSelectRef: any = useRef(null);
  const [desdinamizeFile, setDesdinamizeFile] = useState(false);
  const [dataColumnsState, setDataColumns] = useState([]);
  const [tabValue, setTabValue] = useState<TabValue>("NUEVA CONFIGURACIÓN");
  const [newConfigTabValue, setNewConfigTabValue] =
    useState<TabValue>("GENERAL");
  const [openSaveConfigModal, toggleOpenSaveConfigModal] = useDialog();
  const [editName, setEditName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [fileName, setFileName] = useState("");
  const [assignPeriods, setAssignPeriods] = useState("manual");
  const [conservatePeriods, setConservatePeriods] = useState("column");
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([]);
  const [overwritePeriods, setOverwritePeriods] = useState(false);

  const tabs: Tab[] = [
    {
      value: "NUEVA CONFIGURACIÓN",
      label: "NUEVA CONFIGURACIÓN",
    },
    {
      value: "CONFIGURACIONES GUARDADAS",
      label: "CONFIGURACIONES GUARDADAS",
    },
  ];

  const newConfigTabs: Tab[] = [
    {
      value: "GENERAL",
      label: "GENERAL",
    },
    {
      value: "TEMPLATE PARA PHI",
      label: "TEMPLATE PARA PHI",
    },
  ];

  const [
    configurationSelectedOption,
    setConfigurationSelectedOption,
    configurationChangeHandler,
  ] = useSingleSelect();

  const [flowSelectedOption, setFlowSelectedOption, flowOptionsChangeHandler] =
    useSingleSelect();

  const [
    formatSelectedOption,
    setFormatSelectedOption,
    formatOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    stepperToDeconsolidateSelectedOption,
    setStepperToDeconsolidateSelectedOption,
    stepperToDeconsolidateChangeHandler,
  ] = useSingleSelect();

  const [
    templatePhiNameSelectedOption,
    setTemplatePhiNameSelectedOption,
    templatePhiNameChangeHandler,
  ] = useSingleSelect();

  const [monthSelectedOption, setMonthSelectedOption, monthChangeHandler] =
    useSingleSelect();

  const [yearSelectedOption, setYearSelectedOption, yearChangeHandler] =
    useSingleSelect();

  /********************************* LLAMADAS **************************************/

  const onSuccesGetFlow = (data: any) => {
    setFonts(formatFonts(data));
  };

  const onSuccessCalcFlow = (data: any) => {
    cleanValues();
    navigate("/gestor-tareas");
  };

  const onSuccesGetColumns = (data: any) => {
    setDataColumns(data);
  };

  const {
    isLoading: loadingFlows,
    data: dataFlows,
    refetch: refetchFlows,
  } = useApiQuery(FLOW_URL, true, texts.flows.getFlows.error);

  const { isLoading: isLoadingCalcFlow, callApi: calcFlow } = useApi(
    CALC_FLOW_URL(flowSelectedOption?.value, "cambiar"),
    "POST",
    texts.flows.calcFlow.codes,
    {
      nombre_ejecucion: executionName.length > 0 ? executionName : undefined,
      nombre_archivo: fileName,
      url_destino: selectedFolder === "Carpeta raíz" ? "" : selectedFolder,
      formato_archivo: formatSelectedOption?.value,
      desconsolidar: desdinamizeFile,
      stepper_desconsolidar: desdinamizeFile
        ? stepperToDeconsolidateSelectedOption?.value
        : undefined,
      columna_desconsolidar: desdinamizeFile
        ? columnSelectedOption?.label
        : undefined,
      fuentes: fonts.map((font: any) => {
        return {
          item_id: font.id,
          hoja: font.hoja ? font.hoja : undefined,
        };
      }),
    },
    onSuccessCalcFlow,
    undefined,
    true
  );

  const {
    isLoading: loadingFlow,
    data: dataFlow,
    callApi: getDataFlow,
  } = useApi(
    GET_FLOW_URL(flowSelectedOption?.value),
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccesGetFlow,
    undefined,
    false
  );

  const {
    data: dataColumns,
    isLoading: isLoadingColumns,
    callApi: getColumns,
  } = useApi(
    GET_COLUMNS_FLOW_URL(
      dataFlow?.id,
      dataFlow?.steppers[dataFlow?.steppers?.length - 1]?.id,
      dataFlow?.steppers[dataFlow?.steppers?.length - 1]?.items[
        dataFlow.steppers[dataFlow?.steppers?.length - 1].items.length - 1
      ]?.id
    ),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccesGetColumns,
    undefined,
    false
  );

  const {
    data: dataConfigs,
    isLoading: isLoadingGetConfigs,
    callApi: getConfigs,
    error: errorGetConfigs,
  } = useApi(
    FLOW_CONFIGURATION,
    "GET",
    texts.flows.getFlowConfigs.codes,
    undefined,
    undefined, //onSucces
    undefined, //onError
    false,
    undefined,
    undefined
  );

  const { isLoading: isLoadingPostConfig, callApi: postConfig } = useApi(
    FLOW_CONFIGURATION,
    "POST",
    texts.flows.postFlowConfigs.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  const { callApi: putConfig } = useApi(
    FLOW_CONFIGURATION,
    "PUT",
    texts.flows.postFlowConfigs.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  /********************************* CARGA DE DATOS  *********************************************/

  useEffect(() => {
    if (flowSelectedOption) {
      getDataFlow();
      getConfigs(FLOW_CONFIGURATION(flowSelectedOption?.value as number));
    } else {
      setDataColumns([]);
      setColumnSelectedOption(undefined);
      columnSelectRef?.current?.clearValue();
      setDesdinamizeFile(false);
    }
  }, [flowSelectedOption]);

  useEffect(() => {
    setColumnSelectedOption(undefined);
    columnSelectRef?.current?.clearValue();
    setDesdinamizeFile(false);
  }, [dataFlow]);

  useEffect(() => {
    if (
      stepperToDeconsolidateSelectedOption &&
      stepperToDeconsolidateSelectedOption?.value
    ) {
      getColumns(
        GET_COLUMNS_FLOW_URL(
          dataFlow?.id,
          stepperToDeconsolidateSelectedOption?.value as number,
          dataFlow?.steppers[
            dataFlow?.steppers.findIndex((s: any) => {
              return stepperToDeconsolidateSelectedOption?.value === s.id;
            })
          ].items[
            dataFlow?.steppers[
              dataFlow?.steppers.findIndex(
                (s: any) => stepperToDeconsolidateSelectedOption?.value === s.id
              )
            ].items.length - 1
          ]?.id
        )
      );
    } else {
      setDataColumns([]);
    }
  }, [stepperToDeconsolidateSelectedOption]);

  /********************************* VALIDACIONES Y LIMPIEZA **************************************/

  const formatFonts = (data: any) => {
    return data.steppers
      ?.filter((step: any) => {
        return step.items[0]?.tipo === "nueva_fuente";
      })
      .map((step: any) => {
        return {
          nombre: step.nombre,
          id: step.items[0].id,
          error: step.items[0].posee_error,
          dataFile: "original",
        };
      });
  };

  const isFormCompleted = () => {
    return (
      !formatSelectedOption ||
      formatSelectedOption === undefined ||
      !flowSelectedOption ||
      flowSelectedOption === undefined ||
      fonts.some((font: any) => {
        return font.dataFile === undefined;
      }) ||
      (desdinamizeFile &&
        (columnSelectedOption === null ||
          columnSelectedOption === undefined )) ||
      fonts.some((font: any) => {
        return font.dataFile !== "original" && font.hoja === undefined;
      })
    );
  };


  const cleanValues = () => {
    setFlowSelectedOption(undefined);
    flowSelectRef?.current?.clearValue();
    setExecutionName("");
    setFormatSelectedOption(undefined);
    formatSelectRef?.current?.clearValue();
    setStepperToDeconsolidateSelectedOption(undefined);
    stepperToDeconsolidateSelectRef?.current?.clearValue();
    setColumnSelectedOption(undefined);
    setConfigurationSelectedOption(undefined);
    configurationRef?.current?.clearValue();
    setSelectedFolder("");
    setDesdinamizeFile(false);
    setFileName("");
    setEditName("");
  };
  /********************************* HANDLERS **************************************/

  const handleChangeFontDataFile = (fontName: string, dataFont: any) => {
    const existingFont = fonts.find((font) => font.nombre === fontName);

    if (existingFont) {
      const updatedFonts = fonts.map((font) => {
        if (font.nombre === fontName) {
          return { ...font, dataFile: dataFont };
        } else {
          return font;
        }
      });
      setFonts(updatedFonts);
    }
  };

  const handleChangeFontInput = (fontName: string, archivo: any) => {
    const existingFont = fonts.find((font) => font.nombre === fontName);
    if (existingFont) {
      const updatedFonts = fonts.map((font) => {
        if (font.nombre === fontName) {
          return { ...font, archivo: archivo };
        } else {
          return font;
        }
      });
      setFonts(updatedFonts);
    }
  };

  const handleChangeSheet = (
    sheet: string | number | undefined,
    fontName: string
  ) => {
    const existingFont = fonts.find((font) => font.nombre === fontName);

    if (existingFont) {
      const updatedFonts = fonts.map((font) => {
        if (font.nombre === fontName) {
          return { ...font, hoja: sheet };
        } else {
          return font;
        }
      });
      setFonts(updatedFonts);
    }
  };

  const handleExecuteFlow = () => {
    if (
      newConfigTabValue === "TEMPLATE PARA PHI" ||
      (configurationSelectedOption &&
        dataConfigs.find(
          (config: any) => config.id === configurationSelectedOption?.value
        )?.template_phi)
    ) {
      calcFlow(undefined, {
        id_configuracion: configurationSelectedOption?.value as number,
        nombre_archivo:
          assignPeriods === "manual"
            ? `${templatePhiNameSelectedOption?.label}-${monthSelectedOption?.label}-${yearSelectedOption?.label}`
            : templatePhiNameSelectedOption?.label,
        template_phi: true,
        url_destino: `PHI/${templatePhiNameSelectedOption?.label}`,
        formato_archivo: formatSelectedOption?.value,
        steppers: [], // se envia vacio porque se deben calcular todos
        fuentes: fonts.map((font: any) => {
          return {
            item_id: font.id,
            hoja: font.hoja ? font.hoja : undefined,
          };
        }),
        desconsolidar: assignPeriods === "deconsolidate",
        stepper_desconsolidar:
          assignPeriods === "deconsolidate"
            ? stepperToDeconsolidateSelectedOption?.value
            : undefined,
        columna_desconsolidar:
          assignPeriods === "deconsolidate"
            ? columnSelectedOption?.label
            : undefined,
        lista_periodos:
          assignPeriods === "deconsolidate" &&
          conservatePeriods === "select-periods"
            ? selectedPeriods
            : [],
        sobreescribir_periodos_existentes: overwritePeriods,
      });
    } else {
      calcFlow(undefined, {
        id_configuracion: configurationSelectedOption?.value as number,
        nombre_archivo: fileName,
        template_phi: false,
        url_destino: selectedFolder === "Carpeta raíz" ? "" : selectedFolder,
        formato_archivo: formatSelectedOption?.value,
        steppers: [], // se envia vacio porque se deben calcular todos
        fuentes: fonts.map((font: any) => {
          return {
            item_id: font.id,
            hoja: font.hoja ? font.hoja : undefined,
          };
        }),
        desconsolidar: desdinamizeFile || assignPeriods === "deconsolidate",
        stepper_desconsolidar:
          desdinamizeFile || assignPeriods === "deconsolidate"
            ? stepperToDeconsolidateSelectedOption?.value
            : undefined,
        columna_desconsolidar:
          desdinamizeFile || assignPeriods === "deconsolidate"
            ? columnSelectedOption?.label
            : undefined,
      });
    }
    toggleConfirmExecuteFlowModal();
  };

  const handleChangeTabs = (
    event: React.SyntheticEvent,
    newValue: TabValue
  ) => {
    setTabValue(newValue);
    setFormatSelectedOption(null);
    setColumnSelectedOption(undefined);
    setDesdinamizeFile(false);
    setEditName("");
    setConfigurationSelectedOption(undefined);
    setFlowSelectedOption(undefined);
    setFileName("");
    setSelectedFolder("");
    setFonts([]);
    setStepperToDeconsolidateSelectedOption(undefined);
    setTemplatePhiNameSelectedOption(undefined)
    templatePhiNameRef?.current?.clearValue()
    formatSelectRef?.current?.clearValue()
    setAssignPeriods("manual")
    setOverwritePeriods(false)
    setMonthSelectedOption(undefined)
    monthSelectRef?.current?.clearValue()
    setYearSelectedOption(undefined)
    yearSelectRef?.current?.clearValue()
    stepperToDeconsolidateSelectRef?.current?.clearValue()
    columnSelectRef?.current?.clearValue()
    setSelectedPeriods([])
  };

  const handleChangeNewConfigTabs = (
    event: React.SyntheticEvent,
    newValue: TabValue
  ) => {
    setNewConfigTabValue(newValue);
    setFormatSelectedOption(null);
    setColumnSelectedOption(undefined);
    setDesdinamizeFile(false);
    setFileName("");
    setConfigurationSelectedOption(undefined);
    setEditName("");
    setSelectedFolder("");
    setFlowSelectedOption(undefined)
  };

  const handleChangeSelectedFolder = (location: string) => {
    if (location === "") {
      setSelectedFolder("Carpeta raíz");
    } else {
      setSelectedFolder(location);
    }
  };

  const handleChangeConfigSelectedOption = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    if (newValue !== null && newValue !== undefined) {
      const selectedConfig = dataConfigs.find(
        (config: any) => config.id === newValue?.value
      );
      if (selectedConfig.template_phi) {
        if (selectedConfig.desconsolidar) {
          setTemplatePhiNameSelectedOption({
            value: selectedConfig.nombre_archivo,
            label: selectedConfig.nombre_archivo,
          });
          setConservatePeriods(
            selectedConfig?.lista_periodos?.length === 0
              ? "column"
              : "select-periods"
          );
          setSelectedPeriods(selectedConfig?.lista_periodos);
        } else {
          setTemplatePhiNameSelectedOption({
            value: formatTemplatePhiName(selectedConfig.nombre_archivo),
            label: formatTemplatePhiName(selectedConfig.nombre_archivo),
          });
          setMonthSelectedOption({
            value: getMonthFromTemplatePhiName(selectedConfig.nombre_archivo),
            label:getMonthFromTemplatePhiName(selectedConfig.nombre_archivo),
          });
          setYearSelectedOption({
            value: getYearFromTemplatePhiName(selectedConfig.nombre_archivo),
            label:getYearFromTemplatePhiName(selectedConfig.nombre_archivo),
          });
        }
        setOverwritePeriods(selectedConfig.sobreescribir_periodos_existentes);
        setAssignPeriods(
          selectedConfig.desconsolidar ? "deconsolidate" : "manual"
        );
      }
      setFileName(selectedConfig.nombre_archivo);
      setEditName(selectedConfig.nombre);
      setColumnSelectedOption({
        value: selectedConfig.columna_desconsolidar,
        label: selectedConfig.columna_desconsolidar,
      });
      setSelectedFolder(selectedConfig.ubicacion_archivo);
      setFormatSelectedOption({
        value: selectedConfig.formato_archivo,
        label: selectedConfig.formato_archivo,
      });
      setDesdinamizeFile(selectedConfig.desconsolidar);
      setStepperToDeconsolidateSelectedOption(
        selectedConfig.stepper_desconsolidar
      );
    }
    setTimeout(() => {
      configurationChangeHandler(newValue, actionMeta);
    }, 50);
  };

  const handleAcceptSaveConfig = () => {
    if (tabValue === "NUEVA CONFIGURACIÓN") {
      toggleOpenSaveConfigModal();
      if (newConfigTabValue === "TEMPLATE PARA PHI") {
        postConfig(FLOW_CONFIGURATION(dataFlow?.id), {
          nombre: editName,
          nombre_archivo:
            assignPeriods === "manual"
              ? `${templatePhiNameSelectedOption?.label}-${monthSelectedOption?.label}-${yearSelectedOption?.label}`
              : templatePhiNameSelectedOption?.label,
          template_phi: true,
          url_destino: `PHI/${templatePhiNameSelectedOption?.label}`,
          formato_archivo: formatSelectedOption?.value,
          steppers: dataFlow.steppers.map((s: any) => s.id),
          desconsolidar: assignPeriods === "deconsolidate",
          stepper_desconsolidar:
            assignPeriods === "deconsolidate"
              ? stepperToDeconsolidateSelectedOption?.value
              : undefined,
          columna_desconsolidar:
            assignPeriods === "deconsolidate"
              ? columnSelectedOption?.label
              : undefined,
          lista_periodos:
            assignPeriods === "deconsolidate" &&
            conservatePeriods === "select-periods"
              ? selectedPeriods
              : [],
          sobreescribir_periodos_existentes: overwritePeriods,
        });
      } else {
        postConfig(FLOW_CONFIGURATION(dataFlow?.id), {
          nombre: editName,
          nombre_archivo: fileName,
          template_phi: false,
          steppers: dataFlow.steppers.map((s: any) => s.id),
          ubicacion_archivo:
            selectedFolder === "Carpeta raíz" ? "" : selectedFolder,
          formato_archivo: formatSelectedOption?.value,
          desconsolidar: desdinamizeFile,
          stepper_desconsolidar: desdinamizeFile
            ? stepperToDeconsolidateSelectedOption?.value
            : undefined,
          columna_desconsolidar: desdinamizeFile
            ? columnSelectedOption?.label
            : undefined,
        });
      }
    } else {
      const selectedConfig: any = dataConfigs.find(
        (config: any) => config.id === configurationSelectedOption?.value
      );
      if (selectedConfig.template_phi) {
        putConfig(
          FLOW_CONFIGURATION_BY_ID(
            dataFlow?.id,
            configurationSelectedOption?.value as number
          ),
          {
            nombre: editName,
            nombre_archivo:
              assignPeriods === "manual"
                ? `${templatePhiNameSelectedOption?.label}-${monthSelectedOption?.label}-${yearSelectedOption?.label}`
                : templatePhiNameSelectedOption?.label,
            template_phi: true,
            url_destino: `PHI/${templatePhiNameSelectedOption?.label}`,
            formato_archivo: formatSelectedOption?.value,
            steppers: dataFlow.steppers.map((s: any) => s.id),
            desconsolidar: assignPeriods === "deconsolidate",
            stepper_desconsolidar:
              assignPeriods === "deconsolidate"
                ? stepperToDeconsolidateSelectedOption?.value
                : undefined,
            columna_desconsolidar:
              assignPeriods === "deconsolidate"
                ? columnSelectedOption?.label
                : undefined,
            lista_periodos:
              assignPeriods === "deconsolidate" &&
              conservatePeriods === "select-periods"
                ? selectedPeriods
                : [],
            sobreescribir_periodos_existentes: overwritePeriods,
          }
        );
      } else {
        putConfig(
          FLOW_CONFIGURATION_BY_ID(
            dataFlow?.id,
            configurationSelectedOption?.value as number
          ),
          {
            nombre: editName,
            nombre_archivo: fileName,
            template_phi: false,
            ubicacion_archivo:
              selectedFolder === "Carpeta raíz" ? "" : selectedFolder,
            formato_archivo: formatSelectedOption?.value,
            steppers: dataFlow.steppers.map((s: any) => s.id),
            desconsolidar: desdinamizeFile || assignPeriods === "deconsolidate",
            stepper_desconsolidar:
              desdinamizeFile || assignPeriods === "deconsolidate"
                ? stepperToDeconsolidateSelectedOption?.value
                : undefined,
            columna_desconsolidar:
              desdinamizeFile || assignPeriods === "deconsolidate"
                ? columnSelectedOption?.label
                : undefined,
          }
        );
      }
    }
    toggleConfirmExecuteFlowModal();
  };

  const handleChangeStepperToDeconsolidate = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    stepperToDeconsolidateChangeHandler(newValue, actionMeta);
    setColumnSelectedOption(undefined);
    columnSelectRef?.current.clearValue();
  };

  const handleChangeFlow = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    flowOptionsChangeHandler(newValue, actionMeta);
    setConfigurationSelectedOption(undefined);
    configurationRef?.current?.clearValue();
  };

  const addPeriod = () => {
    if (
      !selectedPeriods.includes(
        `${monthSelectedOption?.value}-${yearSelectedOption?.value}`
      )
    ) {
      setSelectedPeriods([
        ...selectedPeriods,
        `${monthSelectedOption?.value}-${yearSelectedOption?.value}`,
      ]);
      setMonthSelectedOption(undefined);
      monthSelectRef?.current?.clearValue();
      setYearSelectedOption(undefined);
      yearSelectRef?.current?.clearValue();
    }
  };

  const deletePeriod = (period: string) => {
    setSelectedPeriods(selectedPeriods.filter((p: any) => p !== period));
  };

  return (
    <ComponentLayout title="Ejecución de flujos" icon={<PlayCircleIcon />}>
      <SimpleBackdrop
        open={loadingFlow}
        message={texts.flows.getFlow.loading}
      />

      <SimpleBackdrop
        open={isLoadingCalcFlow}
        message={texts.flows.executeFlow.loading}
      />
      <Stack
        width="60%"
        height="80vh"
        alignItems={"center"}
        justifyContent={"flex-start"}
        gap={2}
      >
        <Stack width="100%" gap={2} justifyContent={"flex-start"}>
          <ColorTabs
            value={tabValue}
            handleChange={handleChangeTabs}
            tabs={tabs}
            marginBottom="10px"
          />
        </Stack>
        {tabValue === "NUEVA CONFIGURACIÓN" && (
          <>
            <Stack width="100%" gap={2} justifyContent={"flex-start"}>
              <ColorTabs
                value={newConfigTabValue}
                handleChange={handleChangeNewConfigTabs}
                tabs={newConfigTabs}
                marginBottom="10px"
              />
            </Stack>
            {newConfigTabValue === "GENERAL" && (
              <>
                {" "}
                <Stack width="100%" gap={2} justifyContent={"flex-start"}>
                  <Stack>
                    <Stack direction="row" sx={{ width: "100%" }}>
                      <InputLabel id="flujo-select" sx={form_label}>
                        Referencia
                      </InputLabel>
                      <Tooltip
                        title={
                          <p
                            style={{ fontSize: "14px", padding: 0, margin: 0 }}
                          >
                            La referencia será utilizada para identificar a la
                            ejecución en el gestor de tareas
                          </p>
                        }
                        placement="right"
                      >
                        <InfoIcon
                          sx={{
                            color: "var(--blue)",
                            fontSize: "22px",
                            cursor: "pointer",
                            marginLeft: "2px",
                            marginBottom: "2px",
                          }}
                        />
                      </Tooltip>
                    </Stack>
                    <TextField
                      id="filled-textarea"
                      label=""
                      error={executionName.length > 120}
                      placeholder="Ej: Ejecución VENTAS-01-2022"
                      size="small"
                      multiline
                      variant="outlined"
                      fullWidth
                      value={executionName}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setExecutionName(event.target.value);
                      }}
                      helperText={
                        executionName.length > 120 &&
                        "El nombre no puede tener más de 120 carácteres"
                      }
                      FormHelperTextProps={{
                        sx: {
                          color: "var(--magenta)",
                        },
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack width="100%">
                  <InputLabel id="flujo-select" sx={form_label}>
                    Flujo
                  </InputLabel>
                  <Select
                    reference={flowSelectRef}
                    styles={selectStyles(flowSelectedOption)}
                    options={
                      dataFlows &&
                      formatOptions(
                        dataFlows?.filter((flow: any) => {
                          return flow && flow.steppers && flow.steppers?.length > 0;
                        })
                      )
                    }
                    onChange={flowOptionsChangeHandler}
                    closeMenuOnSelect
                    placeholder="Seleccionar flujo"
                    isClearable
                    isDisabled={loadingFlows}
                  />
                </Stack>
                <Stack
                  sx={{ width: "100%", marginTop: "2px", marginBottom: "15px" }}
                >
                  <InputLabel id="format" sx={form_label}>
                    Nombre del archivo
                  </InputLabel>
                  <TextField
                    id="fileName"
                    label=""
                    placeholder="Ej: GASTOS"
                    size="small"
                    variant="outlined"
                    error={fileName === ""}
                    fullWidth
                    value={fileName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFileName(event.target.value);
                    }}
                    helperText={
                      isFileNameValid(fileName) || fileName === ""
                        ? ""
                        : texts.flows.concat.helperText.invalidName
                    }
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                      },
                    }}
                    required
                  />
                </Stack>
                <Stack sx={{ width: "100%", marginTop: "10px" }}>
                  <InputLabel id="format" sx={form_label}>
                    Formato del archivo
                  </InputLabel>
                  <Select
                    reference={formatSelectRef}
                    styles={selectStyles(formatSelectedOption)}
                    options={fileFormatOptions}
                    onChange={formatOptionsChangeHandler}
                    closeMenuOnSelect
                    placeholder="Seleccionar formato"
                    isClearable
                  />
                </Stack>
                {flowSelectedOption &&
                  !loadingFlow &&
                  dataFlow &&
                  dataFlow?.steppers?.length > 0 && (
                    <Stack sx={{ width: "100%", marginTop: "10px" }}>
                      <InputLabel
                        id="fonts"
                        sx={{
                          ...form_label,
                          fontSize: "17px",
                          marginBottom: "5px",
                        }}
                      >
                        Fuentes:
                      </InputLabel>
                      {formatFonts(dataFlow).map((font: any) => {
                        return (
                          <ReplaceFont
                            key={font.nombre}
                            label={font.nombre}
                            setFont={handleChangeFontInput}
                            error={font.error}
                            setSheet={handleChangeSheet}
                            setDataFont={handleChangeFontDataFile}
                          />
                        );
                      })}
                    </Stack>
                  )}
                <Stack
                  sx={{ width: "100%", marginTop: "2px", marginBottom: "15px" }}
                >
                  <Stack direction="row">
                    <InputLabel id="format" sx={form_label}>
                      Ubicación del archivo
                    </InputLabel>
                    <Tooltip
                      title={`Elija la carpeta destino donde se alojará el archivo`}
                    >
                      <InfoIcon
                        sx={{
                          color: "var(--blue)",
                          fontSize: "21px",
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginBottom: "2px",
                        }}
                      />
                    </Tooltip>
                  </Stack>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    fullWidth
                    label=""
                    value={selectedFolder}
                    placeholder={`Ubicación`}
                    autoComplete="off"
                    disabled={true}
                    InputLabelProps={{
                      style: {
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "100%",
                        color: "var(--blue)",
                      },
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                        margin: 0,
                        backgroundColor: "var(--very-very-light-grey)",
                      },
                    }}
                  />
                  <Stack width="950px">
                    <ExploreFolders
                      handleChangeSelectedFolder={handleChangeSelectedFolder}
                    />
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    width: "100%",
                    marginTop: "0px",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <InputLabel
                    id="format"
                    sx={{
                      ...form_label,
                      color:
                        !dataFlow || (dataFlow && dataFlow.desconsolidar)
                          ? "var(--blue)"
                          : "var(--light-grey)",
                    }}
                  >
                    Desconsolidar archivo
                  </InputLabel>
                  {!dataFlow || (dataFlow && dataFlow.desconsolidar) ? (
                    <Checkbox
                      checked={desdinamizeFile}
                      sx={{
                        color: "#172D40",
                        "& svg": { stroke: "#172D40" },
                      }}
                      onChange={() => {
                        setDesdinamizeFile((prev) => !prev);
                      }}
                    />
                  ) : (
                    <Tooltip title="No es posible desconsolidar el archivo porque hay una o más fuentes que no forman parte de ningun Merge o Concat">
                      <InfoIcon
                        sx={{
                          color: "var(--blue)",
                          fontSize: "21px",
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginBottom: "2px",
                        }}
                      />
                    </Tooltip>
                  )}
                </Stack>
                <Stack sx={{ width: "100%", marginTop: "10px" }}>
                  <InputLabel
                    sx={{
                      ...form_label,
                      color:
                        desdinamizeFile && dataFlow && dataFlow.desconsolidar
                          ? "var(--blue)"
                          : "var(--light-grey)",
                    }}
                  >
                    Elja el stepper a desconsolidar
                  </InputLabel>
                  <Select
                    reference={stepperToDeconsolidateSelectRef}
                    styles={selectStyles(stepperToDeconsolidateSelectedOption)}
                    options={dataFlow?.steppers.map((s: any) => {
                      return { value: s.id, label: s.nombre };
                    })}
                    onChange={stepperToDeconsolidateChangeHandler}
                    closeMenuOnSelect
                    placeholder="Seleccionar stepper"
                    isDisabled={
                      !desdinamizeFile ||
                      !dataFlow ||
                      (dataFlow && !dataFlow.desconsolidar)
                    }
                  />
                </Stack>
                <Stack sx={{ width: "100%", marginTop: "5px" }}>
                  <InputLabel
                    id="format"
                    sx={{
                      ...form_label,
                      color:
                        desdinamizeFile && dataFlow && dataFlow.desconsolidar
                          ? "var(--blue)"
                          : "var(--light-grey)",
                    }}
                  >
                    Selecciona la columna para desconsolidar
                  </InputLabel>
                  <Select
                    reference={columnSelectRef}
                    styles={selectStyles(columnSelectedOption)}
                    options={formatColumns(
                      filterIdFromColumns(dataColumnsState)
                    )}
                    onChange={columnOptionsChangeHandler}
                    closeMenuOnSelect
                    placeholder="Seleccionar columna"
                    isClearable
                    isDisabled={
                      !desdinamizeFile ||
                      !dataFlow ||
                      (dataFlow && !dataFlow.desconsolidar)
                    }
                  />
                </Stack>
              </>
            )}
            {newConfigTabValue === "TEMPLATE PARA PHI" && (
              <>
                <Stack width="100%">
                  <InputLabel id="flujo-select" sx={form_label}>
                    Flujo
                  </InputLabel>
                  <Select
                    reference={flowSelectRef}
                    styles={selectStyles(flowSelectedOption)}
                    options={
                      dataFlows &&
                      formatOptions(
                        dataFlows?.filter((flow: any) => {
                          return flow && flow.steppers && flow.steppers.length > 0;
                        })
                      )
                    }
                    onChange={flowOptionsChangeHandler}
                    closeMenuOnSelect
                    placeholder="Seleccionar flujo"
                    isClearable
                    isDisabled={loadingFlows}
                  />
                </Stack>
                <PhiTemplateConfigurationTab
                  formatSelectRef={formatSelectRef}
 columns={[]}
                  columnSelectRef={columnSelectRef}
                  formatSelectedOption={formatSelectedOption}
                  formatOptionsChangeHandler={formatOptionsChangeHandler}
                  columnSelectedOption={columnSelectedOption}
                  columnOptionsChangeHandler={columnOptionsChangeHandler}
                  templatePhiNameRef={templatePhiNameRef}
                  templatePhiNameSelectedOption={templatePhiNameSelectedOption}
                  templatePhiNameChangeHandler={templatePhiNameChangeHandler}
                  monthSelectRef={monthSelectRef}
                  monthChangeHandler={monthChangeHandler}
                  monthSelectedOption={monthSelectedOption}
                  yearSelectRef={yearSelectRef}
                  yearSelectedOption={yearSelectedOption}
                  yearChangeHandler={yearChangeHandler}
                  addPeriod={addPeriod}
                  deletePeriod={deletePeriod}
                  assignPeriods={assignPeriods}
                  setAssignPeriods={setAssignPeriods}
                  conservatePeriods={conservatePeriods}
                  setConservatePeriods={setConservatePeriods}
                  selectedPeriods={selectedPeriods}
                  overwritePeriods={overwritePeriods}
                  setOverwritePeriods={setOverwritePeriods}
                  fonts={
                    dataFlow && dataFlow?.steppers?.length > 0 && flowSelectedOption
                      ? formatFonts(dataFlow)
                      : []
                  }
                  handleChangeFontInput={handleChangeFontInput}
                  handleChangeSheet={handleChangeSheet}
                  handleChangeFontDataFile={handleChangeFontDataFile}
                  configSelectedOption={configurationSelectedOption?.value}
                />
              </>
            )}
          </>
        )}
        {tabValue === "CONFIGURACIONES GUARDADAS" && (
          <>
            <Stack width="100%" gap={2} justifyContent={"flex-start"}>
              <Stack>
                <Stack direction="row" sx={{ width: "100%" }}>
                  <InputLabel id="flujo-select" sx={form_label}>
                    Referencia
                  </InputLabel>
                  <Tooltip
                    title={
                      <p style={{ fontSize: "14px", padding: 0, margin: 0 }}>
                        La referencia será utilizada para identificar a la
                        ejecución en el gestor de tareas
                      </p>
                    }
                    placement="right"
                  >
                    <InfoIcon
                      sx={{
                        color: "var(--blue)",
                        fontSize: "22px",
                        cursor: "pointer",
                        marginLeft: "2px",
                        marginBottom: "2px",
                      }}
                    />
                  </Tooltip>
                </Stack>
                <TextField
                  id="filled-textarea"
                  label=""
                  error={executionName.length > 120}
                  placeholder="Ej: Ejecución VENTAS-01-2022"
                  size="small"
                  multiline
                  variant="outlined"
                  fullWidth
                  value={executionName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setExecutionName(event.target.value);
                  }}
                  helperText={
                    executionName.length > 120 &&
                    "El nombre no puede tener más de 120 carácteres"
                  }
                  FormHelperTextProps={{
                    sx: {
                      color: "var(--magenta)",
                    },
                  }}
                />
              </Stack>
            </Stack>
            <Stack width="100%">
              <InputLabel id="flujo-select" sx={form_label}>
                Flujo
              </InputLabel>
              <Select
                reference={flowSelectRef}
                styles={selectStyles(flowSelectedOption)}
                options={
                  dataFlows &&
                  formatOptions(
                    dataFlows?.filter((flow: any) => {
                      return flow && flow.steppers && flow.steppers.length > 0;
                    })
                  )
                }
                onChange={handleChangeFlow}
                closeMenuOnSelect
                placeholder="Seleccionar flujo"
                isClearable
                isDisabled={loadingFlows}
              />
            </Stack>
            {flowSelectedOption !== undefined &&
              dataFlow &&
              dataConfigs !== undefined && (
                <>
                  <Stack sx={{ width: "100%" }}>
                    <InputLabel sx={{ ...form_label }}>
                      Elija la configuración
                    </InputLabel>
                    <Select
                      reference={configurationRef}
                      styles={selectStyles(configurationSelectedOption)}
                      options={formatOptions(dataConfigs)}
                      onChange={handleChangeConfigSelectedOption}
                      closeMenuOnSelect
                      placeholder="Seleccionar configuración"
                      isClearable
                    />
                  </Stack>
                </>
              )}

            {configurationSelectedOption !== undefined &&
              configurationSelectedOption !== null &&
              (dataConfigs?.find(
                (config: any) =>
                  config.id === configurationSelectedOption?.value
              )?.template_phi ? (
                <PhiTemplateConfigurationTab
                  formatSelectRef={formatSelectRef}
                  columnSelectRef={columnSelectRef}
                  columns={dataColumnsState}
                  formatSelectedOption={formatSelectedOption}
                  formatOptionsChangeHandler={formatOptionsChangeHandler}
                  columnSelectedOption={columnSelectedOption}
                  columnOptionsChangeHandler={columnOptionsChangeHandler}
                  templatePhiNameRef={templatePhiNameRef}
                  templatePhiNameSelectedOption={templatePhiNameSelectedOption}
                  templatePhiNameChangeHandler={templatePhiNameChangeHandler}
                  monthSelectRef={monthSelectRef}
                  monthChangeHandler={monthChangeHandler}
                  monthSelectedOption={monthSelectedOption}
                  yearSelectRef={yearSelectRef}
                  yearSelectedOption={yearSelectedOption}
                  yearChangeHandler={yearChangeHandler}
                  addPeriod={addPeriod}
                  deletePeriod={deletePeriod}
                  assignPeriods={assignPeriods}
                  setAssignPeriods={setAssignPeriods}
                  conservatePeriods={conservatePeriods}
                  setConservatePeriods={setConservatePeriods}
                  selectedPeriods={selectedPeriods}
                  overwritePeriods={overwritePeriods}
                  setOverwritePeriods={setOverwritePeriods}
                  fonts={
                    dataFlow && dataFlow?.steppers?.length > 0 && flowSelectedOption
                      ? formatFonts(dataFlow)
                      : []
                  }
                  handleChangeFontInput={handleChangeFontInput}
                  handleChangeSheet={handleChangeSheet}
                  handleChangeFontDataFile={handleChangeFontDataFile}
                  configSelectedOption={configurationSelectedOption?.value}
                />
              ) : (
                <>
                  <Stack sx={{ width: "100%", marginTop: "2px" }}>
                    <InputLabel id="format" sx={form_label}>
                      Nombre del archivo
                    </InputLabel>
                    <TextField
                      id="fileName"
                      label=""
                      placeholder="Ej: GASTOS"
                      size="small"
                      variant="outlined"
                      error={fileName === ""}
                      fullWidth
                      value={fileName}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setFileName(event.target.value);
                      }}
                      helperText={
                        isFileNameValid(fileName) || fileName === ""
                          ? ""
                          : texts.flows.concat.helperText.invalidName
                      }
                      FormHelperTextProps={{
                        sx: {
                          color: "var(--magenta)",
                        },
                      }}
                      required
                    />
                  </Stack>
                  <Stack sx={{ width: "100%" }}>
                    <InputLabel id="format" sx={form_label}>
                      Formato del archivo
                    </InputLabel>
                    <Select
                      reference={formatSelectRef}
                      styles={selectStyles(formatSelectedOption)}
                      options={fileFormatOptions}
                      onChange={formatOptionsChangeHandler}
                      closeMenuOnSelect
                      placeholder="Seleccionar formato"
                      isClearable
                      defaultValue={formatSelectedOption}
                    />
                  </Stack>
                  {flowSelectedOption &&
                    !loadingFlow &&
                    dataFlow &&
                    dataFlow?.steppers?.length > 0 && (
                      <Stack sx={{ width: "100%", marginTop: "10px" }}>
                        <InputLabel
                          id="fonts"
                          sx={{
                            ...form_label,
                            fontSize: "17px",
                            marginBottom: "5px",
                          }}
                        >
                          Fuentes:
                        </InputLabel>
                        {formatFonts(dataFlow).map((font: any) => {
                          return (
                            <ReplaceFont
                              key={font.nombre}
                              label={font.nombre}
                              setFont={handleChangeFontInput}
                              error={font.error}
                              setSheet={handleChangeSheet}
                              setDataFont={handleChangeFontDataFile}
                            />
                          );
                        })}
                      </Stack>
                    )}

                  <Stack
                    sx={{
                      width: "100%",
                      marginTop: "2px",
                      marginBottom: "15px",
                    }}
                  >
                    <Stack direction="row">
                      <InputLabel id="format" sx={form_label}>
                        Ubicación del archivo
                      </InputLabel>
                      <Tooltip
                        title={`Elija la carpeta destino donde se alojará el archivo`}
                      >
                        <InfoIcon
                          sx={{
                            color: "var(--blue)",
                            fontSize: "21px",
                            cursor: "pointer",
                            marginLeft: "5px",
                            marginBottom: "2px",
                          }}
                        />
                      </Tooltip>
                    </Stack>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      fullWidth
                      label=""
                      value={selectedFolder}
                      placeholder={`Ubicación`}
                      autoComplete="off"
                      disabled={true}
                      InputLabelProps={{
                        style: {
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "100%",
                          color: "var(--blue)",
                        },
                      }}
                      FormHelperTextProps={{
                        sx: {
                          color: "var(--magenta)",
                          margin: 0,
                          backgroundColor: "var(--very-very-light-grey)",
                        },
                      }}
                    />
                    <Stack width="950px">
                      <ExploreFolders
                        handleChangeSelectedFolder={handleChangeSelectedFolder}
                      />
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      width: "100%",
                      marginTop: "0px",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel
                      id="format"
                      sx={{
                        ...form_label,
                        color:
                          !dataFlow || (dataFlow && dataFlow.desconsolidar)
                            ? "var(--blue)"
                            : "var(--light-grey)",
                      }}
                    >
                      Desconsolidar archivo
                    </InputLabel>
                    {!dataFlow || (dataFlow && dataFlow.desconsolidar) ? (
                      <Checkbox
                        checked={desdinamizeFile}
                        sx={{
                          color: "#172D40",
                          "& svg": { stroke: "#172D40" },
                        }}
                        onChange={() => {
                          setDesdinamizeFile((prev) => !prev);
                        }}
                      />
                    ) : (
                      <Tooltip title="No es posible desconsolidar el archivo porque hay una o más fuentes que no forman parte de ningun Merge o Concat">
                        <InfoIcon
                          sx={{
                            color: "var(--blue)",
                            fontSize: "21px",
                            cursor: "pointer",
                            marginLeft: "5px",
                            marginBottom: "2px",
                          }}
                        />
                      </Tooltip>
                    )}
                  </Stack>
                  <Stack sx={{ width: "100%", marginTop: "10px" }}>
                    <InputLabel
                      sx={{
                        ...form_label,
                        color:
                          desdinamizeFile && dataFlow && dataFlow.desconsolidar
                            ? "var(--blue)"
                            : "var(--light-grey)",
                      }}
                    >
                      Elja el stepper a desconsolidar
                    </InputLabel>
                    <Select
                      reference={stepperToDeconsolidateSelectRef}
                      styles={selectStyles(
                        stepperToDeconsolidateSelectedOption
                      )}
                      options={dataFlow?.steppers.map((s: any) => {
                        return { value: s.id, label: s.nombre };
                      })}
                      onChange={stepperToDeconsolidateChangeHandler}
                      closeMenuOnSelect
                      placeholder="Seleccionar stepper"
                      defaultValue={stepperToDeconsolidateSelectedOption}
                      isDisabled={
                        !desdinamizeFile ||
                        !dataFlow ||
                        (dataFlow && !dataFlow.desconsolidar)
                      }
                    />
                  </Stack>
                  <Stack sx={{ width: "100%", marginTop: "5px" }}>
                    <InputLabel
                      id="format"
                      sx={{
                        ...form_label,
                        color:
                          desdinamizeFile && dataFlow && dataFlow.desconsolidar
                            ? "var(--blue)"
                            : "var(--light-grey)",
                      }}
                    >
                      Selecciona la columna para desconsolidar
                    </InputLabel>
                    <Select
                      reference={columnSelectRef}
                      styles={selectStyles(columnSelectedOption)}
                      options={formatColumns(
                        filterIdFromColumns(dataColumnsState)
                      )}
                      onChange={columnOptionsChangeHandler}
                      closeMenuOnSelect
                      placeholder="Seleccionar columna"
                      isClearable
                      defaultValue={columnSelectedOption}
                      isDisabled={
                        !desdinamizeFile ||
                        !dataFlow ||
                        (dataFlow && !dataFlow.desconsolidar)
                      }
                    />
                  </Stack>
                </>
              ))}
          </>
        )}
        <ConfirmationModal
          open={openConfirmExecuteFlowModal}
          handleClose={toggleConfirmExecuteFlowModal}
          handleAccept={handleExecuteFlow}
          title="Ejecución del flow"
          message="ejecutar el flow seleccionado"
        />
        <Stack
          width="100%"
          direction="row"
          justifyContent={"center"}
          marginTop={"auto"}
        >
          <Button
            title="Borrar datos"
            color="grey"
            type="button"
            onClick={cleanValues}
          />
          <Button
            title="Ejecutar flujo"
            color="blue"
            type="button"
            onClick={toggleConfirmExecuteFlowModal}
            disabled={isFormCompleted()}
          />
          <Button
            title="Ejecutar y guardar"
            color="blue-greeny"
            type="button"
            onClick={
              tabValue === "NUEVA CONFIGURACIÓN"
                ? toggleOpenSaveConfigModal
                : handleAcceptSaveConfig
            }
            disabled={isFormCompleted()}
          />
        </Stack>
      </Stack>

      <SaveNameModal
        open={openSaveConfigModal}
        handleClose={toggleOpenSaveConfigModal}
        handleAccept={handleAcceptSaveConfig}
        title={"Guardar configuración de cálculo"}
        text={"Nombre de la nueva configuración"}
        label={"Nombre de la nueva configuración"}
        placeholder={"Nombre de la nueva configuración"}
        dispatchFunction={setEditName}
        valueState={editName}
        namesList={dataConfigs}
      />
    </ComponentLayout>
  );
};

export default FlowExecution;
