import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Delete from "../../../icons/Delete";
import { Item } from "../../../basicGrid/BasicGrid";
import { useContext } from "react";
import FlowsProvider from "../../../../context/FlowsContext";
import { useStore } from "../../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
interface GroupByRowProps {
  row: any;
}
const selector = (store: any) => ({
  groupByRows: store.groupByRows,
  updateGroupByRows: store.updateGroupByRows,
});

const RowGroupBy = ({ row }: GroupByRowProps) => {
  const store = useStore(selector, shallow);
  const groupByRows = store.groupByRows;


  const handleDeleteFilter = () => {
    const newGroupByRows = groupByRows;
    store.updateGroupByRows(newGroupByRows.filter((groupByRow: any) => {
      return !(
        groupByRow.column.value === row?.column?.value &&
        groupByRow.function.value === row?.function?.value
      );
    }))
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />
        </Stack>
      </Grid>
      <Grid item xs={5.5}>
        <Item sx={{ color: "var(--blue) !important" }}>
          {row?.column?.label}
        </Item>
      </Grid>
      <Grid item xs={5}>
        <Item sx={{ color: "var(--blue) !important" }}>
          {row.function.label}
        </Item>
      </Grid>
      <Grid item xs={1}></Grid>
    </>
  );
};

export default RowGroupBy;
