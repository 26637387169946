import { Stack } from "@mui/material";
import Dialog from "../../dialog/Dialog";
import {  useEffect, useState } from "react";
import { texts } from "../../../texts.js";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import SimpleTable from "../../tablesTools/SimpleTable";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import { NEW_VIEW_RESULTS_URL} from "../../../api/axios";
import { useStore } from "../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";

interface ViewModalProps {
  open: boolean;
  handleClose: () => void;
  id: number;
}

const selector = (store: any) => ({
  flow: store.flow,
});

const ViewModal = ({ open, handleClose, id }: ViewModalProps) => {
  const store = useStore(selector, shallow);
  const [resultsDataState, setResultsDataState] = useState<any>(
    undefined
  );

  const onSuccesGetData = (data: any) => {
    setResultsDataState(data);
  }

  const onClose = () => {
    setResultsDataState(undefined);
    handleClose()
  }

  useEffect(() => {
    if (open) {
      getViewResults();
    }
  }, [open]);

  const {
    data: tableData,
    isLoading: isLoadingViewResults,
    callApi: getViewResults,
  } = useApi(
    NEW_VIEW_RESULTS_URL(
      store.flow.id,
     id
    ),
    "GET",
    texts.flows.viewResults.codes,
    undefined,
    onSuccesGetData,
    undefined,
    false
  );


  return (
    <Dialog
      open={open}
      handleClose={onClose}
      title="Ver resultados"
      draggable={true}
      maxWidth="xl"
    >
      <SimpleBackdrop
        open={isLoadingViewResults}
        message={texts.flows.viewResults.loading}
      />
      <Stack
        width="100%"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ minHeight: "500px", height: "100%", width: "100%", minWidth: "81vw" }}
      >
        <Stack sx={{ minWidth: "80vw" }}>
          {resultsDataState && (
            <SimpleTable
              columns={transformDataSimpleTable(resultsDataState).columns}
              rows={transformDataSimpleTable(resultsDataState).rows}
              toolbar={false}
              exportButton={false}
              filterButton={false}
            />
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ViewModal;
