import { Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Dialog from "../../dialog/Dialog";
import { useStore } from "../FlowDiagram/store/store";
import shallow from "zustand/shallow";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AddCardIcon from "@mui/icons-material/AddCard";
import DeleteColumnIcon from "../../icons/DeleteColumnIcon";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import MergeIcon from "@mui/icons-material/Merge";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import PivotTableChartIcon from "@mui/icons-material/PivotTableChart";
import TableViewIcon from "@mui/icons-material/TableView";
import RepartitionIcon from "@mui/icons-material/Repartition";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddFont from "./AddFont/AddFont";
import useDialog from "../../../hooks/useDialog";
import useApi from "../../../hooks/useApi";
import { ITEM_URL } from "../../../api/axios";
import { texts } from "../../../texts";
import FileFormat from "./CalculationConfigs/FileFormat";
import Concat from "./Concat";
import DeleteColumn from "./DeleteColumn";
import DeleteRow from "./DeleteRow";
import DesdynamizeRows from "./DesdynamizeRows";
import DynamizeRows from "./DynamizeRows";
import Filter from "./Filter";
import GroupBy from "./GroupBy/GroupBy";
import Merge from "./Merge";
import ModifyColumn from "./ModifyColumn";
import NewColumn from "./NewColumn/NewColumn";
import RenameColumns from "./RenameColumns";
import SetHeader from "./SetHeader";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


interface GroupModalProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  id: string;
  backendId?: any;
  parentIds?: any[];
  nodeData?: any;
}
const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
  setInitialNodes: store.setInitialNodes,
  setInitialEdges: store.setInitialEdges,
});

type NodeType =
  | "font"
  | "new-column"
  | "delete-column"
  | "delete-rows"
  | "modify-column"
  | "merge"
  | "group-by"
  | "concat"
  | "filter"
  | "rename-columns"
  | "dinamize"
  | "desdinamize"
  | "set-header"
  | "calculate";

interface NodeProps {
  id: string;
  data: any;
}

const GroupModal = ({ open, handleClose, id, nodeData }: GroupModalProps) => {
  const store = useStore(selector, shallow);
  const [data, setData] = useState<any>(null);
  const [selectedNode, setSelectedNode] = useState<any>({ data: {
    parentIds: [1]
  } });
  const [openFontModal, handleToggleFontModal] = useDialog();
  const [openCalculateModal, handleToggleCalculateModal] = useDialog();
  const [openConcatModal, handleToggleEditConcatModal] = useDialog();
  const [openDeleteColumnModal, handleToggleDeleteColumn] = useDialog();
  const [openDeleteRowsModal, handleToggleEditDeleteRowsModal] = useDialog();
  const [openDesdynamizeColumns, handleToggleDesdynamizeColumnsModal] =
    useDialog();
  const [openDynamizeColumns, handleToggleDynamizeColumnsModal] = useDialog();
  const [openFilterModal, handleToggleFilterModal] = useDialog();
  const [openGroupByModal, handleToggleEditGroupByModal] = useDialog();
  const [openMergeModal, handleToggleEditMergeModal] = useDialog();
  const [openModifyColumn, handleToggleModifyColumnModal] = useDialog();
  const [openNewColumnModal, handleToggleEditNewColumnModal] = useDialog();
  const [openRenameColumnsModal, handleToggleRenameColumnsModal] = useDialog();
  const [openSetHeaderModal, handleToggleSetHeaderModal] = useDialog();

  const nodeTypes = {
    font: {
      icon: InsertDriveFileIcon,
      label: "Fuente",
      style: { color: "var(--blue)" },
    },
    "new-column": {
      icon: AddCardIcon,
      label: "Nueva columna",
      style: { color: "var( --yellow)" },
    },
    "delete-column": {
      icon: DeleteColumnIcon,
      label: "Eliminar columna",
      style: { color: "var( --pink)" },
    },
    "delete-rows": {
      icon: DeleteSweepIcon,
      label: "Eliminar filas",
      style: { color: "var( --light-green)" },
    },
    "modify-column": {
      icon: BorderColorIcon,
      label: "Modificar columna",
      style: { color: "var(--violet)" },
    },
    merge: {
      icon: UnfoldLessIcon,
      label: "Merge",
      style: { color: "var(--grey)" },
    },
    "group-by": {
      icon: CalendarViewMonthIcon,
      label: "Group by",
      style: { color: "var(--greeny)" },
    },
    concat: {
      icon: MergeIcon,
      label: "Concat",
      style: { color: "var(--green)" },
    },
    filter: {
      icon: FilterAltIcon,
      label: "Filtrar",
      style: { color: "var(--light-blue)" },
    },
    "rename-columns": {
      icon: MenuOpenIcon,
      label: "Renombrar columnas",
      style: { color: "var(--light-violet)" },
    },
    dinamize: {
      icon: PivotTableChartIcon,
      label: "Dinamizar columnas",
      style: { color: "var(--orange)" },
    },
    desdinamize: {
      icon: TableViewIcon,
      label: "Desdinamizar columnas",
      style: { color: "var(--light-magenta)" },
    },
    "set-header": {
      icon: RepartitionIcon,
      label: "Setear cabecera",
      style: { color: "var(--light-blue-2)" },
    },
    calculate: {
      icon: PlayCircleOutlineIcon,
      label: "Calcular",
      style: { color: "var(--dark-blue-2)" },
    },
  };

  const handleClickOnEditButton = (node: any) => {
    setSelectedNode({
      ...node,
      position: node.position ? node.position : { x: node.x, y: node.y },
      type: node.type ? node.type : node.tipo,
    });
    getDataItem(
      ITEM_URL(
        store.flow?.id,
        node?.backendId ? node?.backendId : node?.data?.backendId
      )
    );
  };

  const onSuccessGetItem = (data: any) => {
    setData(data);
  };

  const handleOpenModal = () => {
    switch (selectedNode.type) {
      case "font":
        if (!openFontModal) {
          handleToggleFontModal();
        }
        return;
      case "new-column":
        if (!openNewColumnModal) {
          handleToggleEditNewColumnModal();
        }
        return;
      case "delete-column":
        if (!openDeleteColumnModal) {
          handleToggleDeleteColumn();
        }
        return;
      case "delete-rows":
        if (!openDeleteRowsModal) {
          handleToggleEditDeleteRowsModal();
        }
        return;
      case "modify-column":
        if (!openModifyColumn) {
          handleToggleModifyColumnModal();
        }
        return;
      case "merge":
        if (!openMergeModal) {
          handleToggleEditMergeModal();
        }
        return;
      case "group-by":
        if (!openGroupByModal) {
          handleToggleEditGroupByModal();
        }
        return;
      case "concat":
        if (!openConcatModal) {
          handleToggleEditConcatModal();
        }
        return;
      case "filter":
        if (!openFilterModal) {
          handleToggleFilterModal();
        }
        return;
      case "rename-columns":
        if (!openRenameColumnsModal) {
          handleToggleRenameColumnsModal();
        }
        return;
      case "dinamize":
        if (!openDynamizeColumns) {
          handleToggleDynamizeColumnsModal();
        }
        return;
      case "desdinamize":
        if (!openDesdynamizeColumns) {
          handleToggleDesdynamizeColumnsModal();
        }
        return;
      case "set-header":
        if (!openSetHeaderModal) {
          handleToggleSetHeaderModal();
        }
        return;
      case "calculate":
        if (!openCalculateModal) {
          handleToggleCalculateModal();
        }
        return;
      default:
        return "Clave no reconocida";
    }
  };

  const handleCloseModal = (func: any) => {
    func();
    setData(undefined);
  };

  const {
    data: dataSelectedItem,
    callApi: getDataItem,
    isLoading: isLoadingGetItem,
  } = useApi(
    ITEM_URL,
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetItem,
    undefined,
    false
  );

  useEffect(() => {
    if (selectedNode.id && data?.detalle_item) {
      handleOpenModal();
    }
  }, [selectedNode.id, data]);


  return (
    <>
      <Dialog
        open={open}
        handleClose={handleClose}
        title={"Editar agrupación"}
        maxWidth="xl"
      >
        <SimpleBackdrop
          open={isLoadingGetItem}
          message={texts.flows.getFlowColumns.loading}
        />
        <Stack spacing={2} direction={"column"} sx={{ minWidth: "600px" }}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              margin: "auto",
              color: "var(--blue)",
            }}
          >
            Nodos agrupados:
          </Typography>
          <Stack direction="row" spacing={2}>
            {nodeData?.data?.nodes?.map((node: any) => {
              // Aquí aseguramos que el tipo es válido antes de renderizar
              const NodeComponent = nodeTypes[node.type as NodeType].icon;
              return NodeComponent ? (
                <Stack sx={{ alignItems: "center", justifyContent: "center", position:"relative" }}>
                  {(node?.posee_error || node?.data?.error) && (
                    <WarningAmberIcon
                      style={{
                        fontSize: 24,
                        color: "var(--red)",
                        position: "absolute",
                        top: -12,
                        right: 5,
                      }}
                    />
                  )}
                  {nodeTypes[node.type as NodeType].label ===
                  "Eliminar columna" ? (
                    <NodeComponent
                      color="inherit"
                      size={"80"}
                      style={{
                        fontSize: "80px",
                        ...nodeTypes[node.type as NodeType].style,
                      }}
                    />
                  ) : (
                    <NodeComponent
                      color="inherit"
                      size="100px"
                      style={{
                        fontSize: "80px",
                        ...nodeTypes[node.type as NodeType].style,
                      }}
                    />
                  )}

                  <Typography
                    sx={{
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {nodeTypes[node.type as NodeType].label}
                    <Tooltip title="Editar">
                      <EditIcon
                        onClick={() => {
                          handleClickOnEditButton(node);
                        }}
                        sx={{
                          fontSize: "20px",
                          cursor: "pointer",
                          color: "var(--dark-grey)",
                          margin: "0px 2px 5px 5px",
                        }}
                      />
                    </Tooltip>
                  </Typography>
                </Stack>
              ) : null;
            })}
          </Stack>
        </Stack>
        <AddFont
          handleClose={() => {
            handleCloseModal(handleToggleFontModal);
          }}
          open={openFontModal}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          nodeData={selectedNode}
        />
        <FileFormat
          handleClose={() => {
            handleCloseModal(handleToggleCalculateModal);
          }}
          open={openCalculateModal}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          nodeData={selectedNode}
          sourceItemId={
            selectedNode?.data?.parentIds?.length > 0
              ? selectedNode?.data?.parentIds[0]
              : (selectedNode?.parent_ids?.length >0 ? selectedNode?.parent_ids[0] : undefined)
          }
        />
        <Concat
          handleClose={() => {
            handleCloseModal(handleToggleEditConcatModal);
          }}
          open={openConcatModal}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          parentIds={
            selectedNode?.data?.parentIds
              ? selectedNode?.data?.parentIds
              : selectedNode?.parent_ids
          }
          nodeData={selectedNode}
        />
        <DeleteColumn
          handleClose={() => {
            handleCloseModal(handleToggleDeleteColumn);
          }}
          open={openDeleteColumnModal}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          sourceItemId={
            selectedNode?.data?.parentIds?.length > 0
              ? selectedNode?.data?.parentIds[0]
              : (selectedNode?.parent_ids?.length >0 ? selectedNode?.parent_ids[0] : undefined)
          }
          parentIds={
            selectedNode?.parentIds
              ? selectedNode?.parentIds
              : selectedNode?.parent_ids
          }
          nodeData={selectedNode}
        />
        <DeleteRow
          handleClose={() => {
            handleCloseModal(handleToggleEditDeleteRowsModal);
          }}
          open={openDeleteRowsModal}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          sourceItemId={
            selectedNode?.data?.parentIds?.length > 0
              ? selectedNode?.data?.parentIds[0]
              : (selectedNode?.parent_ids?.length >0 ? selectedNode?.parent_ids[0] : undefined)
          }
          parentIds={
            selectedNode?.data?.parentIds
              ? selectedNode?.data.parentIds
              : selectedNode?.parent_ids
          }
          nodeData={selectedNode}
        />
        <DesdynamizeRows
          handleClose={() => {
            handleCloseModal(handleToggleDesdynamizeColumnsModal);
          }}
          open={openDesdynamizeColumns}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          sourceItemId={
            selectedNode?.data?.parentIds?.length > 0
              ? selectedNode?.data?.parentIds[0]
              : (selectedNode?.parent_ids?.length >0 ? selectedNode?.parent_ids[0] : undefined)
          }
          parentIds={
            selectedNode?.data?.parentIds
              ? selectedNode?.data?.parentIds
              : selectedNode?.parent_ids
          }
          nodeData={selectedNode}
        />
        <DynamizeRows
          handleClose={() => {
            handleCloseModal(handleToggleDynamizeColumnsModal);
          }}
          open={openDynamizeColumns}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          sourceItemId={
            selectedNode?.data?.parentIds?.length > 0
              ? selectedNode?.data?.parentIds[0]
              : (selectedNode?.parent_ids?.length >0 ? selectedNode?.parent_ids[0] : undefined)
          }
          parentIds={
            selectedNode?.data?.parentIds
              ? selectedNode?.data?.parentIds
              : selectedNode?.parent_ids
          }
          nodeData={selectedNode}
        />
        <Filter
          handleClose={() => {
            handleCloseModal(handleToggleFilterModal);
          }}
          open={openFilterModal}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          sourceItemId={
            selectedNode?.data?.parentIds?.length > 0
              ? selectedNode?.data?.parentIds[0]
              : (selectedNode?.parent_ids?.length >0 ? selectedNode?.parent_ids[0] : undefined)
          }
          parentIds={
            selectedNode?.data?.parentIds
              ? selectedNode?.data?.parentIds
              : selectedNode?.parent_ids
          }
          nodeData={selectedNode}
        />
        <GroupBy
          handleClose={() => {
            handleCloseModal(handleToggleEditGroupByModal);
          }}
          open={openGroupByModal}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          sourceItemId={
            selectedNode?.data?.parentIds?.length > 0
              ? selectedNode?.data?.parentIds[0]
              : (selectedNode?.parent_ids?.length >0 ? selectedNode?.parent_ids[0] : undefined)
          }
          parentIds={
            selectedNode?.data?.parentIds
              ? selectedNode?.data?.parentIds
              : selectedNode?.parent_ids
          }
          nodeData={selectedNode}
        />
        <Merge
          handleClose={() => {
            handleCloseModal(handleToggleEditMergeModal);
          }}
          open={openMergeModal}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          parentIds={
            selectedNode?.data?.parentIds
              ? selectedNode?.data?.parentIds
              : selectedNode?.parent_ids
          }
          nodeData={selectedNode}
        />
        <ModifyColumn
          handleClose={() => {
            handleCloseModal(handleToggleModifyColumnModal);
          }}
          open={openModifyColumn}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          sourceItemId={
            selectedNode?.data?.parentIds?.length > 0
              ? selectedNode?.data?.parentIds[0]
              : (selectedNode?.parent_ids?.length >0 ? selectedNode?.parent_ids[0] : undefined)
          }
          parentIds={
            selectedNode?.data?.parentIds
              ? selectedNode?.data?.parentIds
              : selectedNode?.parent_ids
          }
          nodeData={selectedNode}
        />
        <NewColumn
          handleClose={() => {
            handleCloseModal(handleToggleEditNewColumnModal);
          }}
          open={openNewColumnModal}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          sourceItemId={
            selectedNode?.data?.parentIds?.length > 0
              ? selectedNode?.data?.parentIds[0]
              : (selectedNode?.parent_ids?.length >0 ? selectedNode?.parent_ids[0] : undefined)
          }
          parentIds={
            selectedNode?.data?.parentIds
              ? selectedNode?.data?.parentIds
              : selectedNode?.parent_ids
          }
          nodeData={selectedNode}
        />
        <RenameColumns
          handleClose={() => {
            handleCloseModal(handleToggleRenameColumnsModal);
          }}
          open={openRenameColumnsModal}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          sourceItemId={
            selectedNode?.data?.parentIds?.length > 0
              ? selectedNode?.data?.parentIds[0]
              : (selectedNode?.parent_ids?.length >0 ? selectedNode?.parent_ids[0] : undefined)
          }
          parentIds={
            selectedNode?.data?.parentIds
              ? selectedNode?.data?.parentIds
              : selectedNode?.parent_ids
          }
          nodeData={selectedNode}
        />
        <SetHeader
          handleClose={() => {
            handleCloseModal(handleToggleSetHeaderModal);
          }}
          open={openSetHeaderModal}
          dataItem={data}
          id={id}
          backendId={
            selectedNode?.backendId
              ? selectedNode?.backendId
              : selectedNode?.data?.backendId
          }
          sourceItemId={
            selectedNode?.data?.parentIds?.length > 0
              ? selectedNode?.data?.parentIds[0]
              : (selectedNode?.parent_ids?.length >0 ? selectedNode?.parent_ids[0] : undefined)
          }
          parentIds={
            selectedNode?.data?.parentIds
              ? selectedNode?.data?.parentIds
              : selectedNode?.parent_ids
          }
          nodeData={selectedNode}
        />
      </Dialog>
    </>
  );
};

export default GroupModal;
