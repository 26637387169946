import { BaseEdge, getStraightPath } from '@xyflow/react';
import { useStore } from '../store/store';
import shallow from 'zustand/shallow';

const selector = (store: any) => ({
    nodes: store.nodes,
    edges: store.edges,
    selectedEdges: store.selectedEdges
  });
 
export default function CustomEdge({ id, sourceX, sourceY, targetX, targetY, selected }: any) {
    const store = useStore(selector, shallow);

    const edge = store?.edges?.find((edge: any)=> edge?.id === id)

    const sourceNode = store?.nodes?.find((node: any)=> node?.id === edge?.source)

  const [edgePath] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

 
  return (
    <>
      <BaseEdge id={id} path={edgePath}  style={{ stroke: sourceNode?.data?.error ? "var(--red)": (sourceNode?.data?.color && edge.target !== undefined ? `var(--${sourceNode?.data?.color})`: undefined), strokeWidth: 1.5, filter: selected ? 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 1))' : 'none',}} />
    </>
  );
}