import { Position } from "@xyflow/react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import useDialog from "../../../../hooks/useDialog";
import RepartitionIcon from "@mui/icons-material/Repartition";
import NodeWithToolbar from "../nodeToolbar/NodeToolbar";
import { useStore } from "../store/store";
import { shallow } from "zustand/shallow";
import useApi from "../../../../hooks/useApi";
import { ITEM_URL } from "../../../../api/axios";
import { texts } from "../../../../texts";
import { isEditable } from "../../../../utils/util";
import SetHeader from "../../Modals/SetHeader";
import CustomHandle from "../handles/CustomHandle";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import NodeComment from "./NodeComment";
import { useEffect } from "react";


interface SetHeaderNodeProps {
  id: any;
  data: any;
}

const selector = (store: any) => ({
  flow: store.flow,
  nodes: store.nodes,
  isLoadingFlow: store.isLoadingFlow
});


export default function SetHeaderNode({ id, data }: SetHeaderNodeProps) {
  const [openSetHeaderModal, handleToggleSetHeaderModal] = useDialog();
  const store = useStore(selector, shallow);
  const node = store.nodes.find((node: any)=> node.id === id)

  
  useEffect(() => {
    if(!data.backendId && data.parentIds?.length > 0){
      handleToggleSetHeaderModal()
    }
   }, [data.parentIds]);

  const onSuccessGetItem = () => {
    handleToggleSetHeaderModal();
  };

  const {
    data: dataItem,
    callApi: getDataItem,
    setData: setDataItem,
    isLoading: isLoadingGetItem,
  } = useApi(
    ITEM_URL(store.flow?.id, data?.backendId),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetItem,
    undefined,
    false
  );

  const handleEdit = () => {
    if (data.backendId) {
      getDataItem();
    } else {
      handleToggleSetHeaderModal();
    }
  };
  return (
    <Stack sx={{ alignItems: "center", width: "45px",height:"64px" }}>
            <SimpleBackdrop open={isLoadingGetItem} message={"Cargando datos..."} />

      <NodeWithToolbar handleEdit={handleEdit}
          backendId={data.backendId}
          isEditable={isEditable(data.parentIds, store.nodes)} nodeData={node}/>

      <CustomHandle
        type="target"
        position={Position.Left}
        id={`handle-target-2-${id}`}
        connectionCount={1}
        nodeId={id}

      />
      {data.error &&     <WarningAmberIcon
        style={{
          fontSize: 12,
          color: "var(--red)" ,
          position:"absolute",
          top:-5,
          right:5
        }}
      />}
           {store.isLoadingFlow && (
        <CircularProgress
          sx={{
            width: "10px !important",
            height: "10px !important",
            right: "0",
            position: "absolute",
          }}
        />
      )}
      <RepartitionIcon
        style={{
          fontSize: 40,
          color: data.color ? `var(--${data.color})` :"var( --light-blue-2)",
        }}
      />
      <Typography sx={{ fontSize: "8px", marginLeft:"9px" }}>
        Setear cabecera{data.comentario ? ": " : ""}
      </Typography>
      <NodeComment id={id} data={data}/>

      <CustomHandle
        type="source"
        position={Position.Right}
        id={`handle-source-2-${id}`}
        connectionCount={1}
        nodeId={id}

      />
       <SetHeader
          handleClose={handleToggleSetHeaderModal}
          open={openSetHeaderModal}
          dataItem={dataItem}
          id={id}
          backendId={data.backendId}
          sourceItemId={data?.parentIds ? data?.parentIds[0] : undefined}
          parentIds={data.parentIds}
          nodeData={node}

        />
    </Stack>
  );
}
