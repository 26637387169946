import { Box, Stack, TextField } from "@mui/material";
import { useState } from "react";

interface CustomColumnInputErrorProps {
  column: any;
}

const CustomColumnInputError = ({
  column,
}: CustomColumnInputErrorProps) => {
  const [inputValue, setInputValue] = useState(column.actualName);


  return (
    <Stack
      sx={{ alignItems: "stretch", flexDirection: "row", margin: "13px 0px" }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "var(--red)",
          borderRadius: "4px",
          color: "white",
          display: "flex",
          fontSize: column.oldName?.length > 17 ? "14px" : "16px",
          justifyContent: "center",
          marginRight: "8px",
          minWidth: "350px",
          textAlign: "center",
          width: "50%",
          wordBreak: "break-all",
        }}
      >
        {column.oldName}
      </Box>
      <TextField
        sx={{
          height: "100%",
          minWidth: "300px",
          padding: "0 !important",
          width: "50%",
        }}
        size="small"
        multiline
        variant="outlined"
        fullWidth
        value={inputValue}
        FormHelperTextProps={{
          sx: {
            color: "var(--magenta)",
            margin: 0,
          },
        }}
        required
        disabled
      />
    </Stack>
  );
};

export default CustomColumnInputError;