import { useEffect } from "react";
import CustomNoRowsOverlay from "../tablesTools/NoRows";
import {
  DataGridPro,
  GridEventListener,
  GridSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
interface Props {
  columns: any;
  rows: any[];
  rowsPerPageOptions: number[];
  rowHeight: number;
  tableHeight: number;
  hideFooter?: boolean;
  toolbar?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  densitySelector?: boolean;
  exportButton?: boolean;
  setSelectedRows?: (prev: any) => void;
  setColumnsPropsArray?: (prev: any) => void;
  columnVisibility?: any;
  setNewVisibility?: any;
  checkboxSelection?: boolean;
}

const VirtualizatedTable = ({
  columns,
  rows,
  rowsPerPageOptions,
  rowHeight,
  tableHeight,
  hideFooter,
  toolbar,
  columnsButton,
  filterButton,
  densitySelector,
  exportButton,
  setSelectedRows,
  setColumnsPropsArray,
  columnVisibility,
  setNewVisibility,
  checkboxSelection,
}: Props) => {
  function CustomToolbar() {
    if (toolbar) {
      return (
        <GridToolbarContainer
          sx={{
            color: "#F90D4A",
            display: toolbar ? "flex" : "none",
            flexDirection: "row",
            alignItems: "center",
            height: "40px",
            width: "100%",
          }}
        >
          <GridToolbarColumnsButton
            translate="yes"
            title="Columnas"
            name="Columnas"
            placeholder="Columnas"
            value="Columnas"
            sx={{
              display: columnsButton ? "flex" : "none",
              color: "#4D4C50",
              width: "90px",
              background: "transparent",
              margin: "2px 5px 2px 18px",
              fontSize: "14px",
              fontWeight: "bold",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#F90D4A",
              },
            }}
            onResize={undefined}
            onResizeCapture={undefined}
            onPointerEnterCapture={ ( ) => <></>}
            onPointerLeaveCapture={ ( ) => <></>}
          ></GridToolbarColumnsButton>
          <GridToolbarFilterButton
            sx={{
              display: filterButton ? "flex" : "none",
              color: "#4D4C50",
              width: "90px",
              background: "transparent",
              margin: "2px 5px 2px 15px",
              fontSize: "14px",
              fontWeight: "bold",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#F90D4A",
              },
              "& .MuiFormControl.root": {
                backgroundColor: "yellow",
                color: "white",
              },
            }}
            onResize={undefined}
            onResizeCapture={undefined}
            placeholder="" 
        onPointerLeaveCapture={ ( ) => <></>}
        onPointerEnterCapture={ ( ) => <></>}

          />
          <GridToolbarDensitySelector
            sx={{
              display: densitySelector ? "flex" : "none",
              color: "#4D4C50",
              width: "90px",
              background: "transparent",
              margin: "2px 5px 2px 15px",
              fontSize: "14px",
              fontWeight: "bold",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#F90D4A",
              },
            }}
            onResize={undefined}
            onResizeCapture={undefined}
            placeholder="" 
            onPointerEnterCapture={ ( ) => <></>}
             onPointerLeaveCapture={ ( ) => <></>}
          />
          <GridToolbarExport
            sx={{
              display: exportButton ? "flex" : "none",
              color: "#4D4C50",
              width: "90px",
              background: "transparent",
              margin: "2px 5px 2px 15px",
              fontSize: "14px",
              fontWeight: "bold",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#F90D4A",
              },
            }}
          />
        </GridToolbarContainer>
      );
    } else {
      return <></>;
    }
  }

  const apiRef = useGridApiRef();

  useEffect(() => {
    apiRef.current.subscribeEvent(
      "selectionChange",
      (params: GridSelectionModel) => {
        setSelectedRows && setSelectedRows(params);
      }
    );

    setColumnsPropsArray &&
      apiRef.current.subscribeEvent(
        "columnsChange",
        (params: string[], event, details) => {
          const columnsProps = details.api.state.columns.lookup;
          const propsArray = Object.keys(columnsProps).map((key: any) => {
            return {
              column: key,
              width: columnsProps[key].width,
              hide: columnsProps[key].hide,
            };
          });
          setColumnsPropsArray(propsArray);
        }
      );

    apiRef.current.subscribeEvent("stateChange", handleStateEvent);
  }, [apiRef]);

  const handleStateEvent: GridEventListener<"stateChange"> = (params) => {
    const filteredRowsMap: any = {};
    for (const key in params.filter.filteredRowsLookup) {
      if (params.filter.filteredRowsLookup[key]) {
        filteredRowsMap[key] = params.rows.idRowsLookup[key];
      }
    }
  };

  const handleSetVisibility = (newModel: any) => {
    setNewVisibility && setNewVisibility(newModel);
  };

  return (
    <div style={{ height: tableHeight, width: "100%" }}>
      <DataGridPro
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        rowsPerPageOptions={rowsPerPageOptions}
        rowHeight={rowHeight}
        components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        componentsProps={{
          panel: {
            sx: {
              [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
                display: "none",
              },
            },
          },
        }}
        disableColumnMenu={true}
        hideFooter={hideFooter}
        checkboxSelection={checkboxSelection === false ? false : true}
        disableSelectionOnClick
        sx={{
          boxShadow: 3,
          fontSize: "12px",
          padding: "0 10px 10px 10px",
          "& .MuiDataGrid-columnHeadersInner": {
            backgroundColor: "var(--blue-greeny)",
          },
          "& .MuiDataGrid-columnHeadersInner span": {
            color: "white",
          },
        }}
        columnBuffer={columns.length}
        columnThreshold={5}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0
            ? "es_whiteRow"
            : "es_colorRow"
        }
        initialState={{
          columns: {
            columnVisibilityModel: columnVisibility,
          },
        }}
        onColumnVisibilityModelChange={(newModel) =>
          handleSetVisibility(newModel)
        }
      />
    </div>
  );
};

export default VirtualizatedTable;
