import {
  Box,
  Checkbox,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import Select from "../../../forms/Select";
import { optionalSelectStyles } from "../../../../styles/select.styles";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import { form_label } from "../../../../styles/app-styles";
import { getFileById, isFileNameValid, isStepNameRepeated } from "../../utils";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../../forms/types";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/Info";
import FlowsProvider from "../../../../context/FlowsContext";
import { texts } from "../../../../texts";
import useApi from "../../../../hooks/useApi";
import { GET_FILE_INFO_URL } from "../../../../api/axios";
import CustomSkeleton from "../../../skeleton/CustomSkeleton";
import SimpleTable from "../../../tablesTools/SimpleTable";
import { transformDataSimpleTable } from "../../../tablesTools/transformDataSimpleTable";

interface NewFontProps {
  row: any;
  dataFileState: any[];
  handleChangeFileSheet: (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>,
    sheetInfoOptionsChangeHandler: any,
    fileInfo: any
  ) => void;
  handleChangeFilesState: (row: any) => void;
  editName?: boolean;
  dataItem?: any;
}

const NewFont = ({
  row,
  dataFileState,
  handleChangeFileSheet,
  handleChangeFilesState,
  editName,
  dataItem,
}: NewFontProps) => {
  const [name, setName] = useState<any>(
    dataItem ? dataItem.comentario : getFileById(row, dataFileState)?.nombre
  );

  const [dataSize, setDataSize] = useState<string>("predeterminada");
  const [isRandomData, setIsRandomData] = useState<boolean>(false);
  const [firstLastRows, setFirstLastRows] = useState<boolean>(false);
  const [rowsNumber, setRowsNumber] = useState<string>("");
  const [headerIndex, setHeaderIndex] = useState<number | string | undefined>(
    undefined
  );
  const sheetSelectRef: any = useRef(null);
  const { flowState } =
    useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
  const file = getFileById(row, dataFileState);
  const { data_flow } = flowState;

  const [
    sheetDirectorySelectedOption,
    setSheetDirectorySelectedOption,
    sheetDirectoryOptionsChangeHandler,
  ] = useSingleSelect();

  useEffect(() => {
    if (dataItem /*&& dataItem?.id === getFileById(row, dataFileState)?.id*/) {
      const data = dataItem.detalle_item;
      setDataSize(data.cantidad_muestra);
      setFirstLastRows(data.primeras_ultimas_filas);
      setIsRandomData(data.muestra_aleatoria);
      setRowsNumber(
        data.cantidad_filas !== null ? data.cantidad_filas : undefined
      );
      setHeaderIndex(data.cabecera);
    }
  }, [dataItem]);

  useEffect(() => {
    // Cada vez que cambiamos la hoja vamos a pedir nuevamente la muestra
    if (sheetDirectorySelectedOption !== undefined) {
      getTable();
    }
  }, [sheetDirectorySelectedOption]);

  // Ni bien se renderiza la fuente pedimos los datos de la tabla
  useEffect(() => {
    //si hay data item y no reemplazamos el archivo usamos la hoja del dataItem para pedir la data

    if (dataItem && dataItem?.detalle_item.archivo?.value === file?.id) {
      getTable(GET_FILE_INFO_URL(row, dataItem.detalle_item.hoja.value, false));
    } else {
      if (!getFileById(row, dataFileState).muestra && !getFileById(row, dataFileState).pesado) {
        //Si no hay una hoja seleccionada obtenemos la data de la tabla con la primera hoja
        if (file?.hojas?.length) {
          getTable(GET_FILE_INFO_URL(row, file.hojas[0]?.id, false));
        }
      }
    }
  }, []);

  const {
    data: dataGetTable,
    isLoading: isLoadingGetTable,
    error: isErrorGetTable,
    callApi: getTable,
  } = useApi(
    GET_FILE_INFO_URL(
      row,
      sheetDirectorySelectedOption?.value as number,
      false
    ),
    "GET",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    /*onSuccessGetTableData*/ undefined,
    /*onErrorGetTableData*/ undefined,
    false
  );

  const isFileNameDuplicated = (name: string) => {
    const names = dataFileState?.map((file: any) => file.nombre);
    if (names) {
      const nameCount = names.filter((n) => n === name).length;
      return nameCount > 1;
    }
    return false;
  };

  return (
    <Stack
      key={row}
      sx={{
        marginBottom: "35px",
        backgroundColor: "var(--very-very-light-grey)",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      {editName && !dataItem && (
        <TextField
          id="fontName"
          label={"Comentario"}
          placeholder={"Comentario"}
          size="small"
          multiline
          variant="outlined"
          helperText={""}
          error={name === ""}
          fullWidth
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeFilesState({
              ...getFileById(row, dataFileState),
              nombre: event.target.value,
            });
            setName(event.target.value);
          }}
          FormHelperTextProps={{
            sx: {
              color: "var(--magenta)",
            },
          }}
          sx={{
            marginBottom: "8px",
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              height: "0.18rem",
            },
          }}
          required={false}
        />
      )}
      {isFileNameDuplicated(getFileById(row, dataFileState).nombre) && (
        <Typography sx={{ color: "var(--magenta)" }}>
          Ya existe un archivo con este nombre, es necesario cambiarlo para
          agregar la fuente
        </Typography>
      )}
      <InputLabel id="demo-simple-select-filled-label" sx={form_label}>
        Seleccione la hoja del archivo {getFileById(row, dataFileState).nombre}
      </InputLabel>
      <Select
        reference={sheetSelectRef}
        styles={optionalSelectStyles(sheetDirectorySelectedOption)}
        options={
          dataItem &&
          dataItem.detalle_item.archivo.value ===
            getFileById(row, dataFileState).id
            ? getFileById(row, dataFileState).hojas?.map((sheet: any) => {
                return {
                  value: sheet.id ? sheet.id : sheet.value,
                  label: sheet.nombre ? sheet.nombre : sheet.label,
                };
              })
            : dataFileState &&
              getFileById(row, dataFileState).hojas?.map((sheet: any) => {
                return { value: sheet.id, label: sheet.nombre };
              })
        }
        form="cc_form"
        className="cc_select"
        name="Hoja"
        onChange={(
          value: SingleValue<IdOption>,
          action: ActionMeta<IdOption>
        ) => {
          handleChangeFileSheet(
            value,
            action,
            sheetDirectoryOptionsChangeHandler,
            getFileById(row, dataFileState)
          );
        }}
        closeMenuOnSelect
        placeholder="Seleccionar hoja del archivo"
        defaultValue={
          dataItem &&
          dataItem.detalle_item.archivo?.value ===
            getFileById(row, dataFileState).id
            ? dataItem.detalle_item.hoja
            : getFileById(row, dataFileState).hojas?.map((sheet: any) => {
                return { value: sheet.id, label: sheet.nombre };
              })[0]
        }
      />
      <Stack sx={{ marginTop: "10px" }}>
        <InputLabel id="demo-simple-select-filled-label" sx={form_label}>
          Seleccione la cantidad de datos que desea utilizar
        </InputLabel>

        <Stack direction="row" gap={2} width="100%" alignItems="center">
          <label htmlFor={`predeterminada-${row}`} style={{ display: "flex" }}>
            <input
              type="radio"
              id={`predeterminada-${row}`}
              name={`dataSize-${row}`}
              onChange={() => {
                handleChangeFilesState({
                  ...getFileById(row, dataFileState),
                  dataSize: "predeterminada",
                });
                setDataSize("predeterminada");
              }}
              checked={dataSize === "predeterminada"}
            />
            Cantidad de muestra predeterminada
            <Tooltip
              title={"Se utilizan mil filas por defecto"}
              placement={"right"}
            >
              <InfoIcon sx={{ fontSize: "22px", marginLeft: "3px" }} />
            </Tooltip>
          </label>

          <label htmlFor={`completa-${row}`} style={{ display: "flex" }}>
            <input
              type="radio"
              id={`completa-${row}`}
              name={`dataSize-${row}`}
              onChange={() => {
                handleChangeFilesState({
                  ...getFileById(row, dataFileState),
                  dataSize: "completa",
                });
                setDataSize("completa");
              }}
              checked={dataSize === "completa"}
            />
            Utilizar todos los datos
            <Tooltip
              title={"Esta opción puede causar que el sistema se ralentice"}
              placement={"right"}
            >
              <ErrorOutlineIcon sx={{ fontSize: "22px", marginLeft: "3px" }} />
            </Tooltip>
          </label>
          <label htmlFor={`filas-${row}`}>
            <input
              type="radio"
              id={`filas-${row}`}
              name={`dataSize-${row}`}
              onChange={() => {
                handleChangeFilesState({
                  ...getFileById(row, dataFileState),
                  dataSize: "filas",
                });
                setDataSize("filas");
              }}
              checked={dataSize === "filas"}
            />
            Número de filas
          </label>
          {dataSize === "filas" && (
            <Box sx={{ width: "300px" }}>
              <TextField
                id="filas-input"
                label="Ingrese el número de filas"
                placeholder="Ingrese el número de filas"
                size="small"
                multiline
                variant="outlined"
                error={false}
                fullWidth
                value={rowsNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const regex = /^[0-9\b]+$/;
                  if (
                    event.target.value === "" ||
                    regex.test(event.target.value)
                  ) {
                    setRowsNumber(event.target.value.trim());
                    handleChangeFilesState({
                      ...getFileById(row, dataFileState),
                      rowsNumber: event.target.value.trim(),
                    });
                  }
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
                required
              />
            </Box>
          )}
        </Stack>
      </Stack>
      <Stack sx={{ marginTop: "10px" }}>
        <InputLabel id="demo-simple-select-filled-label" sx={form_label}>
          Seleccione el metodo de muestreo
        </InputLabel>

        <Stack direction="row" gap={2} width="100%" alignItems="center">
          <label htmlFor={`muestra-predeterminada-${row}`}>
            <input
              type="radio"
              id={`muestra-predeterminada-${row}`}
              name={`dataType-${row}`}
              onChange={() => {
                setIsRandomData(false);
                handleChangeFilesState({
                  ...getFileById(row, dataFileState),
                  isRandomData: false,
                });
              }}
              checked={!isRandomData}
            />
            Muestra predeterminada
          </label>
          <label
            htmlFor={`muestra-aleatoria-${row}`}
            style={{ display: "flex" }}
          >
            <input
              type="radio"
              id={`muestra-aleatoria-${row}`}
              name={`dataType-${row}`}
              onChange={() => {
                setIsRandomData(true);
                handleChangeFilesState({
                  ...getFileById(row, dataFileState),
                  isRandomData: true,
                });
              }}
              checked={isRandomData}
            />
            Muestra aleatoria
            <Tooltip
              title={"Esta opción puede causar que el sistema se ralentice"}
              placement={"right"}
            >
              <ErrorOutlineIcon sx={{ fontSize: "22px", marginLeft: "3px" }} />
            </Tooltip>
          </label>
          {isRandomData && (
            <label
              htmlFor={`firstLastRows-${row}`}
              style={{ fontSize: "0.95rem", lineHeight: "1.5" }}
            >
              <Checkbox
                id={`firstLastRows-${row}`}
                checked={firstLastRows}
                sx={{
                  color: "#172D40",
                  marginBottom: "2px",
                  "& svg": { stroke: "#172D40" },
                }}
                onChange={(event) => {
                  setFirstLastRows((prev) => !prev);
                  handleChangeFilesState({
                    ...getFileById(row, dataFileState),
                    firstLastRows: event.target.checked,
                  });
                }}
              />
              Utilizar primeras y últimas filas
            </label>
          )}
        </Stack>
        <Stack
          direction="row"
          sx={{
            margin: "8px 0",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Stack direction="row">
              <InputLabel id="demo-simple-select-filled-label" sx={form_label}>
                Indice de la cabecera
              </InputLabel>
              <Tooltip title="Si no se envia indice de cabecera se utiliza la cabecera original">
                <InfoIcon
                  sx={{
                    color: "var(--blue)",
                    fontSize: "21px",
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginBottom: "2px",
                  }}
                />
              </Tooltip>
            </Stack>

            <TextField
              type="number"
              id="rangeToRows"
              label={""}
              placeholder={"Índice de la cabecera"}
              size="small"
              multiline
              variant="outlined"
              fullWidth
              value={headerIndex}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const regex = /^[0-9\b]+$/;
                if (
                  event.target.value === "" ||
                  regex.test(event.target.value)
                ) {
                  setHeaderIndex(event.target.value);
                  handleChangeFilesState({
                    ...getFileById(row, dataFileState),
                    cabecera: event.target.value,
                  });
                }
              }}
              FormHelperTextProps={{
                sx: {
                  color: "var(--magenta)",
                },
              }}
            />
          </Box>
        </Stack>
        {isLoadingGetTable ? (
          <Box sx={{ width: "100%" }}>
            <CustomSkeleton height={5} />
          </Box>
        ) : (
          ((getFileById(row, dataFileState).muestra?.length > 0 ||
          (dataGetTable && dataGetTable?.body?.length > 0)) && (
            <SimpleTable
              columns={
                transformDataSimpleTable(
                  getFileById(row, dataFileState).muestra?.length > 0
                    ? getFileById(row, dataFileState).muestra
                    : dataGetTable?.body,
                  undefined,
                  undefined,
                  true
                ).columns
              }
              rows={
                transformDataSimpleTable(
                  getFileById(row, dataFileState).muestra?.length > 0
                    ? getFileById(row, dataFileState).muestra
                    : dataGetTable?.body,
                  undefined,
                  undefined,
                  true
                ).rows
              }
              toolbar={false}
            />
          ))
        )}
      </Stack>
    </Stack>
  );
};

export default NewFont;
