import {
  Box,
  Checkbox,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts.js";
import Select from "../../forms/Select";
import {
  optionalSelectStyles,
  selectStyles,
} from "../../../styles/select.styles";
import useSingleSelect from "../../../hooks/useSingleSelect";
import { form_label } from "../../../styles/app-styles";
import JoinFullIcon from "@mui/icons-material/JoinFull";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import JoinRightIcon from "@mui/icons-material/JoinRight";
import {
  FLOW_ITEMS,
  GET_FLOW_URL,
  NEW_GET_COLUMNS_FLOW_URL,
  POST_ITEM_URL,
} from "../../../api/axios";
import useMultiSelect from "../../../hooks/useMultiSelect";
import {
  checkIfNodeHasPendingEdges,
  filterIdFromColumns,
  formatConcatAndMergeOptions,
  formatMergeColumnControlOptions,
  formatMergeColumnOptions,
  formatMergeOption,
  isFileNameValid,
  setErrorFlowNodes,
} from "../utils";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../forms/types";
import { formatColumns } from "../../fileColumns/utils";
import useDialog from "../../../hooks/useDialog";
import ShadowBox from "./ShadowBox";
import BaseModal from "./BaseModal";
import HowMerge from "../../icons/HowMerge";
import { useStore } from "../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
import { getBackendId } from "../../../utils/util";

interface MergeProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  id: string;
  backendId?: any;
  parentIds?: any[];
  nodeData?: any

}
const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
  setInitialNodes: store.setInitialNodes,
  setInitialEdges: store.setInitialEdges,
  updateIsFlowLoading: store.updateIsFlowLoading
});

const Merge = ({
  open,
  handleClose,
  id,
  backendId,
  parentIds,
  dataItem,
  nodeData
}: MergeProps) => {
  const [comment, setComment] = useState<any>("");
  const [columnsFont1, setColumnsFont1] = useState<any>([]);
  const [columnsFont2, setColumnsFont2] = useState<any>([]);
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const font1SelectRef: any = useRef(null);
  const font2SelectRef: any = useRef(null);
  const columnsToSaveFont1SelectRef: any = useRef(null);
  const columnsToSaveFont2SelectRef: any = useRef(null);
  const columnsToMerge1SelectRef: any = useRef(null);
  const columnsToMerge2SelectRef: any = useRef(null);
  const columnControlSelectRef: any = useRef(null);
  const [delayHelper, setDelayHelper] = useState<boolean>(false);
  const [howMerge, setHowMerge] = useState("");
  const [hasIndicator, setHasIndicator] = useState(false);
  const [indicator, setIndicator] = useState("");
  const [hasControlColumn, setHasControlColumn] = useState(false);

  const store = useStore(selector, shallow);
  const nodesRef = useRef<any[]>([]);
  nodesRef.current = store.nodes;


  const [font1SelectedOption, setFont1SelectedOption, font1ChangeHandler] =
    useSingleSelect();

  const [font2SelectedOption, setFont2SelectedOption, font2ChangeHandler] =
    useSingleSelect();

  const [
    controlColumnSelectedOption,
    setControlColumSelectedOption,
    controlColumChangeHandler,
  ] = useSingleSelect();
  const [
    columnsToSaveFont1SelectedOption,
    setColumnsToSaveFont1SelectedOption,
    columnsToSaveFont1ChangeHandler,
  ] = useMultiSelect();

  const [
    columnsToSaveFont2SelectedOption,
    setColumnsToSaveFont2SelectedOption,
    columnsToSaveFont2ChangeHandler,
  ] = useMultiSelect();

  const [
    columnsToMerge1SelectedOption,
    setColumnsToMerge1SelectedOption,
    columnsToMerge1ChangeHandler,
  ] = useMultiSelect();

  const [
    columnsToMerge2SelectedOption,
    setColumnsToMerge2SelectedOption,
    columnsToMerge2ChangeHandler,
  ] = useMultiSelect();

  /****************************** CARGA DE DATOS *******************************/

  useEffect(() => {
    if (dataItem && open) {
      const data = dataItem.detalle_item
      setComment(dataItem.comentario);
      setHowMerge(data.how);
      setFont1SelectedOption(
        formatMergeOption(data?.izquierda?.parent_ids, store.nodes)
      );
      setFont2SelectedOption(
        formatMergeOption(data?.derecha?.parent_ids, store.nodes)
      );
      setColumnsToMerge1SelectedOption(formatMergeColumnOptions(data?.izquierda?.columnas));
      setColumnsToMerge2SelectedOption(formatMergeColumnOptions(data?.derecha?.columnas));
      setColumnsToSaveFont2SelectedOption(formatMergeColumnOptions(data?.derecha?.columnas_finales));
      setColumnsToSaveFont1SelectedOption(
        formatMergeColumnOptions( data?.izquierda?.columnas_finales)
      );
      data.indicador && setHasIndicator(true);
      data.indicador && setIndicator(data.indicador);
      if (data.columna_control) {
        setHasControlColumn(true);
        setControlColumSelectedOption({
          value: data.columna_control.columna,
          label: data.columna_control.columna,
          parent_ids: data.columna_control.parent_ids,
        } as IdOption);
      }

      if (data?.izquierda?.parent_ids) {

        getColumnsFont1(
          NEW_GET_COLUMNS_FLOW_URL(
            store.flow.id,
           data.izquierda.parent_ids
          )
        );
      }
      if (data?.derecha?.parent_ids) {
        getColumnsFont2(
          NEW_GET_COLUMNS_FLOW_URL(
            store.flow.id,
           data.derecha.parent_ids
          )
        );
      }
    }
  }, [dataItem, open]);


  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setDelayHelper(true);
      }, 2);
    }
  }, [open]);

  const onSuccessGetColumnsFont1 = (data: any) => {
    setColumnsFont1(data);
  };

  const onSuccessGetColumnsFont2 = (data: any) => {
    setColumnsFont2(data);
  };


  const { callApi: getColumnsFont1 } = useApi(
    undefined,
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetColumnsFont1,
    undefined,
    false
  );

  const { callApi: getColumnsFont2 } = useApi(
    undefined,
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetColumnsFont2,
    undefined,
    false
  );

 
  const onFont1Change = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    font1ChangeHandler(newValue, actionMeta);
    setColumnsToMerge1SelectedOption(undefined);
    columnsToMerge1SelectRef.current.clearValue();
    if (newValue !== null) {
      getColumnsFont1(
        NEW_GET_COLUMNS_FLOW_URL(
          store.flow.id,
          parseInt(newValue?.value as string),
        )
      );
    } else {
      setColumnsFont1([]);
    }
  };

  const onFont2Change = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    font2ChangeHandler(newValue, actionMeta);
    setColumnsToMerge2SelectedOption(undefined);
    columnsToMerge2SelectRef.current.clearValue();
    if (newValue !== null) {
      getColumnsFont2(
        NEW_GET_COLUMNS_FLOW_URL(
          store.flow.id,
          parseInt(newValue?.value as string),
        )
      );
    } else {
      setColumnsFont2([]);
    }
  };

  /******************************* VALIDACIONES FORMULARIO ********************************/

  const isFormCompleted = () => {
    return (
      columnsToMerge1SelectedOption === undefined ||
      columnsToMerge1SelectedOption?.length === 0 ||
      columnsToMerge2SelectedOption === undefined ||
      columnsToMerge2SelectedOption?.length === 0 ||
      font1SelectedOption === undefined ||
      font1SelectedOption === null ||
      font2SelectedOption === undefined ||
      font2SelectedOption === null ||
      howMerge === "" ||
      columnsToMerge1SelectedOption.length !==
        columnsToMerge2SelectedOption.length ||
      (hasIndicator && indicator.length === 0) ||
      (hasIndicator && isColumnNameRepeated()) ||
      (hasControlColumn && controlColumnSelectedOption === undefined) ||
      (hasControlColumn && controlColumnSelectedOption === null)
    );
  };

  /******************************** POST STEPPER Y POST ITEM ***********************************/

  const onSuccessGetFlow = (data: any) => {
    store.setInitialNodes(setErrorFlowNodes(nodesRef.current , data.items));
  };
  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  )

  useEffect(() => {
    store.updateIsFlowLoading(isLoadingFlow)
  }, [isLoadingFlow]);

  
   // Actualizamos el nodo insertandole el id del backend que es el que vamos a utilizar
  // cuando enviemos datos al back
  const onSuccessPostItem = (data: any) => {
    const completedInfo = {
      tipo: "merge",
      editar_detalle_item: true,
      calcular: true,
      izquierda: {
        columnas: columnsToMerge1SelectedOption?.map((column: any) => {
          return column.label;
        }),
        columnas_finales: columnsToSaveFont1SelectedOption === undefined || columnsToSaveFont1SelectedOption?.length === 0 ? [] :columnsToSaveFont1SelectedOption?.map((column: any) => {
                return column.label;
              }),
        parent_ids: font1SelectedOption?.value,
      },
      derecha: {
        columnas: columnsToMerge2SelectedOption?.map((column: any) => {
          return column.label;
        }),
        columnas_finales:columnsToSaveFont2SelectedOption === undefined || columnsToSaveFont2SelectedOption?.length === 0 ? [] : columnsToSaveFont2SelectedOption?.map((column: any) => {
                return column.label;
              }),
        parent_ids: font2SelectedOption?.value,
      },
      how_merge: howMerge,
      indicador: hasIndicator ? indicator : undefined,
      columna_control: hasControlColumn
        ? {
            parent_ids: (controlColumnSelectedOption as any).parent_ids,
            columna: controlColumnSelectedOption?.label.substring(0, controlColumnSelectedOption?.label.lastIndexOf('-')).trim(),
          }
        : undefined,
        ...nodeData.position

    };
    store.updateNode(id, {
      backendId: backendId ? backendId : data.id,
      error: data.posee_error,
      errorMessage: data.mensaje_error,
      comentario: comment,
      ...completedInfo
    });
    checkIfNodeHasPendingEdges(id, putConnection, data.id)
    getFlow(GET_FLOW_URL(parseInt(store.flow.id)));

  };
  
  const {
    isLoading: isLoadingPostMerge,
    callApi: postMerge,
    error: errorPostMerge,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutMerge,
    callApi: putMerge,
    error: errorPutMerge,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const saveMerge = () => {
    const completedInfo = {
      tipo: "merge",
      calcular: true,
      id: backendId ? backendId : undefined,
      comentario: comment,
      editar_detalle_item: true,
      parent_ids: parentIds?.map((parentId: any)=>{return getBackendId(parentId, store.nodes)}),
      izquierda: {
        columnas: columnsToMerge1SelectedOption?.map((column: any) => {
          return column.label;
        }),
        columnas_finales:
          columnsToSaveFont1SelectedOption === undefined || columnsToSaveFont1SelectedOption?.length === 0 ?  [] :columnsToSaveFont1SelectedOption?.map((column: any) => {
                return column.label;
              }),
        parent_ids: font1SelectedOption?.value,
      },
      derecha: {
        columnas: columnsToMerge2SelectedOption?.map((column: any) => {
          return column.label;
        }),
        columnas_finales:
          columnsToSaveFont2SelectedOption === undefined || columnsToSaveFont2SelectedOption?.length === 0 ?  [] :columnsToSaveFont2SelectedOption?.map((column: any) => {
                return column.label;
              }),
        parent_ids: font2SelectedOption?.value,
      },
      how_merge: howMerge,
      indicador: hasIndicator ? indicator : undefined,
      columna_control: hasControlColumn
        ? {
            parent_ids: (controlColumnSelectedOption as any).parent_ids,
            columna: controlColumnSelectedOption?.label.substring(0, controlColumnSelectedOption?.label.lastIndexOf('-')).trim(),
          }
        : undefined,
        ...nodeData.position

    };
    if (dataItem) {
      putMerge(undefined, [completedInfo]);
    } else {
      postMerge(undefined, completedInfo);
    }
    cleanAndClose();

  };

  const {
    isLoading: isLoadingPutConnection,
    callApi: putConnection,
    error: errorPutConnection,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );


  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    handleClose();
    setComment("");
    setColumnsFont1([]);
    setColumnsFont2([]);
    setColumnsToMerge1SelectedOption(undefined);
    font1SelectRef.current.clearValue();
    setColumnsToMerge2SelectedOption(undefined);
    font2SelectRef.current.clearValue();
    setColumnsToSaveFont1SelectedOption(undefined);
    columnsToSaveFont1SelectRef.current.clearValue();
    setColumnsToSaveFont2SelectedOption(undefined);
    columnsToSaveFont2SelectRef.current.clearValue();
    setControlColumSelectedOption(undefined);
    columnControlSelectRef.current.clearValue();
    columnsToSaveFont2SelectRef.current.clearValue();
    setHowMerge("");
    setDelayHelper(false);
    setIndicator("");
    setHasIndicator(false);
    if (openConfirmationModal) {
      toggleConfirmationModal();
    }
  };

  const isColumnNameRepeated = () => {
    return (
      columnsFont1?.some((col: any) => {
        return col.columna?.toLowerCase() === indicator?.toLowerCase();
      }) ||
      columnsFont2?.some((col: any) => {
        return col.columna?.toLowerCase() === indicator?.toLowerCase();
      })
    );
  };

  return (
    <>
        <BaseModal
          open={open}
          title="Merge (es necesario unir dos items para configurarlo)"
          cleanAndClose={cleanAndClose}
          openConfirmationModal={openConfirmationModal}
          toggleConfirmationModal={toggleConfirmationModal}
          handleAccept={saveMerge}
          confirmationModalMessage={
            !dataItem ? "agregar el merge" : "editar el merge"
          }
          disabledAcceptButton={isFormCompleted()}
          height="100%"
        >
          {!dataItem &&  <ShadowBox margin>
            <TextField
              id="comment"
              label={"Comentario"}
              placeholder={"Comentario"}
              size="small"
              multiline
              variant="outlined"
              error={!isFileNameValid(comment) || comment === ""}
              fullWidth
              value={comment}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setComment(event.target.value);
              }}
              FormHelperTextProps={{
                sx: {
                  color: "var(--magenta)",
                },
              }}
              required
            />
          </ShadowBox>}
         
          <Stack
            sx={{
              width: "100%",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-around",
              gap: 2,
            }}
          >
            {delayHelper && (
              <ShadowBox margin>
                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>Fuente 1 </InputLabel>
                  <Select
                    reference={font1SelectRef}
                    styles={selectStyles(font1SelectedOption)}
                    options={formatConcatAndMergeOptions(parentIds, store.nodes)}
                    name="Fuente 1"
                    onChange={onFont1Change}
                    closeMenuOnSelect
                    placeholder="Seleccionar fuente 1"
                    isClearable
                    defaultValue={font1SelectedOption}
                    isDisabled={false}
                  />
                </Box>

                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>Columnas fuente 1</InputLabel>
                  <Select
                    isMulti
                    reference={columnsToMerge1SelectRef}
                    styles={selectStyles(columnsToMerge1SelectedOption)}
                    options={formatColumns(filterIdFromColumns(columnsFont1))}
                    name="Columnas para el merge"
                    onChange={columnsToMerge1ChangeHandler}
                    closeMenuOnSelect={false}
                    placeholder="Seleccionar columnas para el merge"
                    isClearable
                    defaultValue={columnsToMerge1SelectedOption}
                    isDisabled={false}
                  />
                </Box>
              </ShadowBox>
            )}
            {delayHelper && (
              <ShadowBox margin>
                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>Fuente 2 </InputLabel>
                  <Select
                    reference={font2SelectRef}
                    styles={selectStyles(font2SelectedOption)}
                    options={formatConcatAndMergeOptions(parentIds, store.nodes)}
                    name="Fuente 2"
                    defaultValue={font2SelectedOption}
                    onChange={onFont2Change}
                    closeMenuOnSelect
                    placeholder="Seleccionar fuente 2"
                    isClearable
                    isDisabled={false}
                  />
                </Box>

                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>Columnas fuente 2</InputLabel>
                  <Select
                    isMulti
                    reference={columnsToMerge2SelectRef}
                    styles={selectStyles(columnsToMerge2SelectedOption)}
                    options={formatColumns(filterIdFromColumns(columnsFont2))}
                    name="Columnas para el merge"
                    onChange={columnsToMerge2ChangeHandler}
                    closeMenuOnSelect={false}
                    placeholder="Seleccionar columnas para el merge"
                    isClearable
                    isDisabled={false}
                    defaultValue={columnsToMerge2SelectedOption}
                  />
                </Box>
              </ShadowBox>
            )}
          </Stack>

          <Stack
            sx={{
              width: "100%",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-around",
              gap: 2,
            }}
          >
            {delayHelper && (
              <ShadowBox margin>
                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>
                    Conservar columnas fuente 1
                  </InputLabel>
                  <Select
                    reference={columnsToSaveFont1SelectRef}
                    styles={optionalSelectStyles(
                      columnsToSaveFont1SelectedOption
                    )}
                    options={formatColumns(filterIdFromColumns(columnsFont1))}
                    isMulti
                    name="Conservar columnas"
                    defaultValue={columnsToSaveFont1SelectedOption}
                    onChange={columnsToSaveFont1ChangeHandler}
                    closeMenuOnSelect={false}
                    placeholder="Todas las columnas"
                    isClearable
                    isDisabled={false}
                  />
                </Box>
              </ShadowBox>
            )}

            {delayHelper && (
              <ShadowBox margin>
                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>
                    Conservar columnas fuente 2
                  </InputLabel>
                  <Select
                    reference={columnsToSaveFont2SelectRef}
                    styles={optionalSelectStyles(
                      columnsToSaveFont2SelectedOption
                    )}
                    options={formatColumns(filterIdFromColumns(columnsFont2))}
                    name="Conservar columnas"
                    isMulti
                    defaultValue={columnsToSaveFont2SelectedOption}
                    onChange={columnsToSaveFont2ChangeHandler}
                    closeMenuOnSelect={false}

                    placeholder="Todas las columnas"
                    isClearable
                    isDisabled={false}
                  />
                </Box>
              </ShadowBox>
            )}
          </Stack>
          <ShadowBox>
            <Stack
              sx={{
                width: "100%",
                marginTop: "0px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <InputLabel
                id="format"
                sx={{
                  ...form_label,
                  color: "var(--blue)",
                }}
              >
                Incluir indicador
              </InputLabel>
              <Checkbox
                checked={hasIndicator}
                sx={{
                  color: "#172D40",
                  "& svg": { stroke: "#172D40" },
                }}
                onChange={() => {
                  setHasIndicator((prev) => !prev);
                }}
              />
            </Stack>
            <Stack sx={{ width: "100%", marginTop: "5px" }}>
              <InputLabel
                id="format"
                sx={{
                  ...form_label,
                  color: hasIndicator ? "var(--blue)" : "var(--light-grey)",
                }}
              >
                Nombre de la columna
              </InputLabel>
              <TextField
                id="columnName"
                label=""
                placeholder="Ej: Columna Merge"
                size="small"
                variant="outlined"
                error={indicator === "" || isColumnNameRepeated()}
                fullWidth
                value={indicator}
                disabled={!hasIndicator}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIndicator(event.target.value);
                }}
                helperText={
                  isColumnNameRepeated()
                    ? "Ya existe una columna con ese nombre"
                    : ""
                }
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
                required
              />
            </Stack>
          </ShadowBox>
          {delayHelper && (
            <ShadowBox>
              <Stack
                sx={{
                  width: "100%",
                  marginTop: "0px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <InputLabel
                  id="format"
                  sx={{
                    ...form_label,
                    color: "var(--blue)",
                  }}
                >
                  Incluir columna control
                </InputLabel>
                <Checkbox
                  checked={hasControlColumn}
                  sx={{
                    color: "#172D40",
                    "& svg": { stroke: "#172D40" },
                  }}
                  onChange={() => {
                    setHasControlColumn((prev) => !prev);
                  }}
                />
              </Stack>
              <Box sx={{ width: "100%", marginBottom: "10px" }}>
                <InputLabel
                  sx={{
                    ...form_label,
                    color: hasControlColumn
                      ? "var(--blue)"
                      : "var(--light-grey)",
                  }}
                >
                  Columna
                </InputLabel>
                <Select
                  reference={columnControlSelectRef}
                  styles={optionalSelectStyles(
                    columnsToSaveFont1SelectedOption
                  )}
                  options={formatMergeColumnControlOptions(
                    {
                      ...font1SelectedOption,
                      columnas: filterIdFromColumns(columnsFont1),
                    },
                    { ...font2SelectedOption, columnas: columnsFont2 }
                  )}
                  name="Columna-control"
                  defaultValue={controlColumnSelectedOption}
                  onChange={controlColumChangeHandler}
                  closeMenuOnSelect
                  placeholder="Seleccionar columna control"
                  isClearable
                  isDisabled={
                    !hasControlColumn ||
                    font1SelectedOption === undefined ||
                    font2SelectedOption === undefined
                  }
                />
              </Box>
            </ShadowBox>
          )}

          <Stack
            sx={{
              gap: "10px",
              mt: "20px",
              width: "100%",
              flexDirection: "column",
              mb: "20px",
            }}
          >
            <ShadowBox>
              <Typography sx={form_label}>Tipo de Merge:</Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <HowMerge
                  icon={
                    <JoinLeftIcon
                      sx={{
                        fontSize: "75px",
                        fontWeight: "bold",
                        color: "var(--blue-greeny)",
                      }}
                    />
                  }
                  onClick={setHowMerge}
                  mergeType={howMerge}
                  mergeValue="left"
                />
                <HowMerge
                  icon={
                    <JoinRightIcon
                      sx={{
                        fontSize: "75px",
                        fontWeight: "bold",
                        color: "var(--blue-greeny)",
                      }}
                    />
                  }
                  onClick={setHowMerge}
                  mergeType={howMerge}
                  mergeValue="right"
                />
                <HowMerge
                  icon={
                    <JoinInnerIcon
                      sx={{
                        fontSize: "75px",
                        fontWeight: "bold",
                        color: "var(--blue-greeny)",
                      }}
                    />
                  }
                  onClick={setHowMerge}
                  mergeType={howMerge}
                  mergeValue="inner"
                />
                <HowMerge
                  icon={
                    <JoinFullIcon
                      sx={{
                        fontSize: "75px",
                        fontWeight: "bold",
                        color: "var(--blue-greeny)",
                      }}
                    />
                  }
                  onClick={setHowMerge}
                  mergeType={howMerge}
                  mergeValue="outer"
                />
              </Stack>
            </ShadowBox>
          </Stack>
        </BaseModal>
      
    </>
  );
};

export default Merge;
