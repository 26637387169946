import {
    Checkbox,
    InputLabel,
    Stack,
    TextField,
    Typography,
    Tooltip,
  } from "@mui/material";
  import Select from "../../../forms/Select";
  import { form_label } from "../../../../styles/app-styles";
  import { useContext } from "react";
  import { selectStyles } from "../../../../styles/select.styles";
  import {
    fileFormatOptions,
  } from "../../../../utils/util";
  import FlowsProvider from "../../../../context/FlowsContext";
  import { texts } from "../../../../texts";
  import { formatColumns } from "../../../fileColumns/utils";
  import {
    filterIdFromColumns,
    isFileNameValid,
  } from "../../utils";
  import InfoIcon from "@mui/icons-material/Info";
  import CheckmarkSelect from "../../../forms/CheckmarkSelect";
  import ExploreFolders from "../../../dataManagement/UploadFileModal/ExploreFolders";
import { SingleValue } from "react-select";
import { IdOption } from "../../../forms/types";
  
  interface NewConfigurationGeneralTabProps {
    formatSelectRef: any
    columnSelectRef: any
    desdinamizeFile: boolean
    setDesdinamizeFile: React.Dispatch<React.SetStateAction<boolean>>
    fileName: string
    setFileName: React.Dispatch<React.SetStateAction<string>>
    columns: any
    formatSelectedOption: SingleValue<IdOption> | undefined
    formatOptionsChangeHandler: any
    columnSelectedOption:SingleValue<IdOption> | undefined
    columnOptionsChangeHandler: any
    handleChangeSelectedFolder: (selectedFolder: string) => void
    validateAccept: () => {
        result: boolean;
        resultMessage: string;
    }
    selectedFolder: string
  }
  
  const NewConfigurationGeneralTab = ({
    formatSelectRef,
    columnSelectRef,
    desdinamizeFile,
    setDesdinamizeFile,
    fileName,
    setFileName,
    columns,
    formatSelectedOption,
    formatOptionsChangeHandler,
    columnSelectedOption,
    columnOptionsChangeHandler,
    handleChangeSelectedFolder,
    validateAccept,
    selectedFolder
  }: NewConfigurationGeneralTabProps) => {
    const { flowState } =
      useContext<React.ContextType<typeof FlowsProvider>>(FlowsProvider);
    const { data_flow } = flowState;
  
    return (
                <>
                  <Stack
                    sx={{ width: "100%", marginTop: "2px", marginBottom: "15px" }}
                  >
                    <InputLabel id="format" sx={form_label}>
                      Nombre del archivo
                    </InputLabel>
                    <TextField
                      id="fileName"
                      label=""
                      placeholder="Ej: GASTOS"
                      size="small"
                      variant="outlined"
                      error={fileName === ""}
                      fullWidth
                      value={fileName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFileName(event.target.value);
                      }}
                      helperText={
                        isFileNameValid(fileName) || fileName === ""
                          ? ""
                          : texts.flows.concat.helperText.invalidName
                      }
                      FormHelperTextProps={{
                        sx: {
                          color: "var(--magenta)",
                        },
                      }}
                      required
                    />
                  </Stack>
                  <Stack
                    sx={{ width: "100%", marginTop: "2px", marginBottom: "15px" }}
                  >
                    <Stack direction="row">
                      <InputLabel id="format" sx={form_label}>
                        Ubicación del archivo
                      </InputLabel>
                      <Tooltip
                        title={`Elija la carpeta destino donde se alojará el archivo`}
                      >
                        <InfoIcon
                          sx={{
                            color: "var(--blue)",
                            fontSize: "21px",
                            cursor: "pointer",
                            marginLeft: "5px",
                            marginBottom: "2px",
                          }}
                        />
                      </Tooltip>
                    </Stack>
  
                    <Stack width="950px">
                      <ExploreFolders
                        handleChangeSelectedFolder={handleChangeSelectedFolder}
                        customFolder={selectedFolder}
                      />
                    </Stack>
                  </Stack>
                  <Stack sx={{ width: "100%", marginTop: "25px" }}>
                    <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                      Elija el formato del archivo
                    </InputLabel>
                    <Select
                      reference={formatSelectRef}
                      styles={selectStyles(formatSelectedOption)}
                      options={fileFormatOptions}
                      onChange={formatOptionsChangeHandler}
                      closeMenuOnSelect
                      placeholder="Seleccionar formato"
                      defaultValue={formatSelectedOption}
                      isClearable
                    />
                  </Stack>
                  {validateAccept().result ? (
                    <Stack
                      sx={{
                        width: "640px",
                        justifyContent: "center",
                        alignItems: "center",
                        pt: 3,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--red)",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                      >
                        {validateAccept().resultMessage}
                      </Typography>
                    </Stack>
                  ) : (
                    ""
                  )}
                  <Stack
                    sx={{
                      width: "100%",
                      marginTop: "0px",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel
                      id="format"
                      sx={{
                        ...form_label,
                        color: data_flow.desconsolidar
                          ? "var(--blue)"
                          : "var(--light-grey)",
                      }}
                    >
                      Desconsolidar archivo
                    </InputLabel>
  
                    <Checkbox
                      checked={desdinamizeFile}
                      sx={{
                        color: "#172D40",
                        "& svg": { stroke: "#172D40" },
                      }}
                      onChange={() => {
                        setDesdinamizeFile((prev) => !prev);
                      }}
                    />
                  </Stack>
  
                  <Stack sx={{ width: "100%", marginTop: "5px" }}>
                    <InputLabel
                      id="format"
                      sx={{
                        ...form_label,
                        color: desdinamizeFile
                          ? "var(--blue)"
                          : "var(--light-grey)",
                      }}
                    >
                      Selecciona la columna para desconsolidar
                    </InputLabel>
                    <Select
                      reference={columnSelectRef}
                      styles={selectStyles(columnSelectedOption)}
                      options={formatColumns(
                        filterIdFromColumns(columns)
                      )}
                      onChange={columnOptionsChangeHandler}
                      closeMenuOnSelect
                      placeholder="Seleccionar columna"
                      isClearable
                      defaultValue={columnSelectedOption}
                      isDisabled={!desdinamizeFile}
                    />
                  </Stack>
                </>
              
         
        
    );
  };
  
  export default NewConfigurationGeneralTab;
  