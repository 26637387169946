import {
  Box,
  InputLabel,
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  Stack,
} from "@mui/material";
import {  useRef, useState } from "react";
import Select from "../../../forms/Select";
import { selectStyles } from "../../../../styles/select.styles";
import { form_label } from "../../../../styles/app-styles";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import { Button as CustomButton } from "../../../buttons";
import ToggleButton from "./ToggleButton";
import Condition from "./Condition";
import ShadowBox from "../ShadowBox";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../../forms/types";

interface ConditionalColumnProps {
  columns: any;
  itemConditions?: any[];
  setConditions: React.Dispatch<React.SetStateAction<any[]>>;
  defaultCondition?: any;
  setDefaultCondition: React.Dispatch<React.SetStateAction<any>>;
  dataItem?: any;
  operatorType: string;
  setOperator: React.Dispatch<React.SetStateAction<string>>;
}

const ConditionalColumn = ({
  columns,
  itemConditions,
  setConditions,
  defaultCondition,
  setDefaultCondition,
  operatorType,
  setOperator,
}: ConditionalColumnProps) => {
  const [conditionalValue, setConditionalValue] = useState<any>(
    itemConditions
      ? itemConditions[0]?.valor.tipo === "valor"
        ? itemConditions[0]?.valor.valor_comparar
        : ""
      : ""
  );
  const [outcomeValue, setOutcomeValue] = useState<any>(
    itemConditions 
      ? itemConditions[0]?.salida?.tipo === "valor"
        ? itemConditions[0]?.salida?.valor_comparar
        : ""
      : ""
  );
  const [defaultValue, setDefaultValue] = useState<any>(
    defaultCondition
      ? defaultCondition.tipo === "valor"
        ? defaultCondition.valor_comparar
        : ""
      : ""
  );
  const [valueType, setValueType] = useState<any>(
    itemConditions ? itemConditions[0].valor.tipo : "valor"
  );
  const [outcomeType, setOutcomeType] = useState<any>(
    itemConditions ? itemConditions[0]?.salida?.tipo : "valor"
  );
  const [defaultType, setDefaultType] = useState<any>(
    defaultCondition ? defaultCondition.tipo : "valor"
  );
  const conditionalColumnRef: any = useRef(null);
  const operatorRef: any = useRef(null);
  const valueColumnRef: any = useRef(null);
  const outcomeColumnRef: any = useRef(null);
  const defaultColumnRef: any = useRef(null);

  const onCustomChange = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>,
    type: string
  ) => {
    switch (type) {
      case "conditionalColumn":
        conditionalColumnChangeHandler(newValue, actionMeta);
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            columna_condicional: newValue,
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        } else {
          const newCondition = {
            id: Math.random(),
            columna_condicional: newValue,
            operador: undefined,
            valor: {
              tipo: valueType,
              valor_comparar: undefined,
            },
            salida: {
              tipo: outcomeType,
              valor_comparar: undefined,
            },
          };
          setConditions([newCondition]);
        }
        return;
      case "operator":
        operatorChangeHandler(newValue, actionMeta);
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            operador: newValue,
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "conditionalValueColumn":
        valueColumnChangeHandler(newValue, actionMeta);
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            valor: {
              tipo: valueType,
              valor_comparar: newValue,
            },
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "outcomeColumn":
        valueColumnChangeHandler(newValue, actionMeta);
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            salida: {
              tipo: outcomeType,
              valor_comparar: newValue,
            },
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      default:
        return false;
    }
  };

  const onInputChange = (value: string, type: string) => {
    switch (type) {
      case "conditionalValue":
        setConditionalValue(value);
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            valor: {
              tipo: valueType,
              valor_comparar: value,
            },
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "outcomeValue":
        setOutcomeValue(value);
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            salida: {
              tipo: outcomeType,
              valor_comparar: value,
            },
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      default:
        return false;
    }
  };

  const onToggleChange = (value: string, type: string) => {
    switch (type) {
      case "conditionalValue":
        setValueType(value);
        if (value === "columna") {
          setConditionalValue("");
        } else {
          setValueColumnSelectedOption(undefined);
        }
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            valor: {
              tipo: value,
              valor_comparar: undefined,
            },
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "outcomeValue":
        if (value === "columna") {
          setOutcomeValue("");
        } else {
          setOutcomeColumnSelectedOption(undefined);
        }
        setOutcomeType(value);
        if (itemConditions) {
          const newCondition = {
            ...itemConditions[0],
            salida: {
              tipo: value,
              valor_comparar: undefined,
            },
          };
          setConditions(
            itemConditions.map((c: any) =>
              c.id === newCondition.id ? newCondition : c
            )
          );
        }
        return;
      case "defaultValue":
        if (value === "columna") {
          setDefaultValue("");
          setDefaultCondition({ tipo: "columna", valor_comparar: undefined });
        } else {
          setDefaultColumnSelectedOption(undefined);
          setDefaultCondition({ tipo: "valor", valor_comparar: undefined });
        }
        setDefaultType(value);
        setDefaultValue(undefined);
        return;
      default:
        return false;
    }
  };

  const [
    conditionalColumnSelectedOption,
    setConditionalColumnSelectedOption,
    conditionalColumnChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    itemConditions ? itemConditions[0].columna_condicional : undefined
  );

  const [
    operatorSelectedOption,
    setOperatorSelectedOption,
    operatorChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    itemConditions ? itemConditions[0].operador : undefined
  );

  const [
    valueColumnSelectedOption,
    setValueColumnSelectedOption,
    valueColumnChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    itemConditions
      ? itemConditions[0].valor.tipo === "columna"
        ? itemConditions[0].valor.valor_comparar
        : undefined
      : undefined
  );

  const [
    outcomeColumnSelectedOption,
    setOutcomeColumnSelectedOption,
    outcomeColumnChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    itemConditions 
      ? itemConditions[0]?.salida?.tipo === "columna"
        ? itemConditions[0]?.salida?.valor_comparar
        : undefined
      : undefined
  );

  const [
    defaultColumnSelectedOption,
    setDefaultColumnSelectedOption,
    defaultColumnChangeHandler,
  ] = useSingleSelect(
    undefined,
    undefined,
    defaultCondition
      ? defaultCondition?.tipo === "columna"
        ? defaultCondition?.valor_comparar
        : undefined
      : undefined
  );

  const conditionalColumnOperatorOptions = [
    { value: "ES_IGUAL_A", label: "es igual a" },
    { value: "NO_ES_IGUAL_A", label: "no es igual a" },
    { value: "COMIENZA_POR", label: "comienza por" },
    { value: "NO_COMIENZA_POR", label: "no comienza por" },
    { value: "TERMINA_CON", label: "termina con" },
    { value: "NO_TERMINA_CON", label: "no termina con" },
    { value: "CONTIENE", label: "contiene" },
    { value: "NO_CONTIENE", label: "no contiene" },
    { value: "ES_MAYOR_QUE", label: "es mayor que" },
    { value: "ES_MAYOR_O_IGUAL_QUE", label: "es mayor o igual que " },
    { value: "ES_MENOR_QUE", label: "es menor que" },
    { value: "ES_MENOR_O_IGUAL_QUE", label: "es menor o igual que " },
  ];

  const handleAddCondition = () => {
    itemConditions &&
      setConditions([
        ...itemConditions,
        {
          id: Math.random(),
          columna_condicional: undefined,
          operador: undefined,
          valor: {
            tipo: "valor",
            valor_comparar: undefined,
          },
          salida: {
            tipo: "valor",
            valor_comparar: undefined,
          },
        },
      ]);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <ShadowBox margin>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Typography
            sx={{
              backgroundColor: "var(--blue-greeny)",
              fontWeight: "bold",
              padding: "7px 10px 5px 10px",
              borderRadius: "5px",
              marginRight: "8px",
              marginTop: "auto",
              color: "white",
            }}
          >
            Si{" "}
          </Typography>
          <Box sx={{ marginRight: "8px", width: "25%", marginTop: "auto" }}>
            <InputLabel sx={form_label}>Columna</InputLabel>
            <Select
              reference={conditionalColumnRef}
              styles={selectStyles(conditionalColumnSelectedOption)}
              options={columns}
              name="Columna"
              onChange={(newValue: any, action: any) => {
                onCustomChange(newValue, action, "conditionalColumn");
              }}
              closeMenuOnSelect
              placeholder="Seleccionar columna"
              isClearable
              defaultValue={conditionalColumnSelectedOption}
            />
          </Box>
          <Box sx={{ marginRight: "8px", width: "20%", marginTop: "auto" }}>
            <InputLabel sx={form_label}>Operador</InputLabel>
            <Select
              reference={operatorRef}
              styles={selectStyles(operatorSelectedOption)}
              options={conditionalColumnOperatorOptions}
              name="Operador"
              onChange={(newValue: any, action: any) => {
                onCustomChange(newValue, action, "operator");
              }}
              closeMenuOnSelect
              placeholder="Operador"
              isClearable
              isDisabled={!conditionalColumnSelectedOption}
              defaultValue={operatorSelectedOption}
            />
          </Box>
          <Box sx={{ marginRight: "8px", width: "25%", marginTop: "auto" }}>
            <Box sx={{ display: "flex" }}>
              <InputLabel sx={form_label}>Valor</InputLabel>
              <ToggleButton
                value={valueType}
                setValue={onToggleChange}
                value1="columna"
                value2="valor"
                type="conditionalValue"
              />
            </Box>
            {valueType === "columna" ? (
              <Select
                reference={valueColumnRef}
                styles={selectStyles(valueColumnSelectedOption)}
                options={columns}
                name="Valor"
                onChange={(newValue: any, action: any) => {
                  onCustomChange(newValue, action, "conditionalValueColumn");
                }}
                isDisabled={!conditionalColumnSelectedOption}
                closeMenuOnSelect
                placeholder="Seleccionar columna"
                isClearable
                defaultValue={valueColumnSelectedOption}
              />
            ) : (
              <TextField
                id="value"
                label={"Valor"}
                placeholder={"Valor"}
                size="small"
                multiline
                disabled={!conditionalColumnSelectedOption}
                variant="outlined"
                error={conditionalValue === ""}
                fullWidth
                value={conditionalValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onInputChange(event.target.value, "conditionalValue");
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
                required
              />
            )}
          </Box>
          {(operatorType === "O" || itemConditions?.length === 1) && (
            <>
              <Typography
                sx={{
                  backgroundColor: "var(--blue-greeny)",
                  fontWeight: "bold",
                  padding: "9px 10px 5px 10px",
                  borderRadius: "5px",
                  marginRight: "5px",
                  marginTop: "auto",
                  color: "white",
                }}
              >
                Entonces{" "}
              </Typography>
              <Box sx={{ marginRight: "8px", width: "25%", marginTop: "auto" }}>
                <Box sx={{ display: "flex" }}>
                  <InputLabel sx={form_label}>Salida</InputLabel>
                  <ToggleButton
                    value={outcomeType}
                    setValue={onToggleChange}
                    value1="columna"
                    value2="valor"
                    type="outcomeValue"
                  />
                </Box>
                {outcomeType === "columna" ? (
                  <Select
                    reference={outcomeColumnRef}
                    styles={selectStyles(outcomeColumnSelectedOption)}
                    options={columns}
                    name="Salida"
                    isDisabled={!conditionalColumnSelectedOption}
                    onChange={(newValue: any, action: any) => {
                      onCustomChange(newValue, action, "outcomeColumn");
                    }}
                    closeMenuOnSelect
                    placeholder="Seleccionar columna"
                    isClearable
                    defaultValue={outcomeColumnSelectedOption}
                  />
                ) : (
                  <TextField
                    id="outcome"
                    label={"Valor"}
                    placeholder={"Valor"}
                    size="small"
                    multiline
                    disabled={!conditionalColumnSelectedOption}
                    variant="outlined"
                    error={outcomeValue === ""}
                    fullWidth
                    value={outcomeValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onInputChange(event.target.value, "outcomeValue");
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "var(--magenta)",
                      },
                    }}
                    required
                  />
                )}
              </Box>
            </>
          )}
        </Box>
      </ShadowBox>

      {itemConditions &&
        itemConditions.length > 1 &&
        itemConditions.slice(1).map((condition: any, i: number) => {
          return (
            <Condition
              key={condition.id}
              columns={columns}
              condition={condition}
              setConditions={setConditions}
              conditions={itemConditions}
              operatorType={operatorType}
            />
          );
        })}
      <Stack direction="row" sx={{ alignItems: "center", margin: "0" }}>
        <Typography sx={{ color: "var(--blue)", marginRight: "5px" }}>
          Tipo de operador condicional:
        </Typography>
        <FormControlLabel
          control={<Radio color="primary" sx={{ mr: "2px" }} />}
          label={
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={form_label}>O</Typography>
            </Stack>
          }
          value={"O"}
          checked={operatorType === "O"}
          onChange={(e: any) => {
            setOperator(e.target.value);
          }}
        />
        <FormControlLabel
          control={<Radio color="primary" sx={{ mr: "2px" }} />}
          label={
            <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography sx={form_label}>Y</Typography>
            </Stack>
          }
          value={"Y"}
          checked={operatorType === "Y"}
          onChange={(e: any) => {
            setOperator(e.target.value);
          }}
        />
        <Box sx={{ marginLeft: "auto" }}>
          <CustomButton
            title="Agregar condición"
            type="button"
            onClick={handleAddCondition}
            color="blue-greeny"
          />
        </Box>
      </Stack>
      <Box sx={{ width: "26.5%", marginTop: "20px" }}>
        <Box sx={{ display: "flex" }}>
          <InputLabel sx={form_label}>De lo contrario</InputLabel>
          <ToggleButton
            value={defaultType}
            setValue={onToggleChange}
            value1="columna"
            value2="valor"
            type="defaultValue"
          />
        </Box>
        {defaultType === "columna" ? (
          <Select
            reference={defaultColumnRef}
            styles={selectStyles(defaultColumnSelectedOption)}
            options={columns}
            name="default"
            onChange={(newValue: any, action: any) => {
              defaultColumnChangeHandler(newValue, action);
              setDefaultCondition({
                tipo: "columna",
                valor_comparar: newValue,
              });
            }}
            closeMenuOnSelect
            placeholder="Seleccionar columna"
            isClearable
            defaultValue={defaultColumnSelectedOption}
          />
        ) : (
          <TextField
            id="default"
            label={"Valor"}
            placeholder={"Valor"}
            size="small"
            multiline
            variant="outlined"
            error={defaultValue === ""}
            fullWidth
            value={defaultValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDefaultValue(event.target.value);
              setDefaultCondition({
                tipo: "valor",
                valor_comparar: event.target.value,
              });
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
              },
            }}
            required
          />
        )}
      </Box>
    </Box>
  );
};

export default ConditionalColumn;
