import { InputLabel, Stack, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../../hooks/useApi";
import { texts } from "../../../../texts.js";
import { Button } from "../../../buttons";
import useDialog from "../../../../hooks/useDialog";
import { ConfirmationModal } from "../../../dialog";
import InfoGrid from "../../../basicGrid/InfoGrid";
import SummaryRowTitles from "../../../summary/SummaryRowTitles";
import GroupByRowSelects from "./GroupByRowSelects";
import {
  FLOW_ITEMS,
  GET_FLOW_URL,
  NEW_GET_COLUMNS_FLOW_URL,
} from "../../../../api/axios";
import {
  checkIfNodeHasPendingEdges,
  filterIdFromColumns,
  setErrorFlowNodes,
  setInitialFlowEdges,
  setInitialFlowNodes,
  tranformDataGroupByToSend,
} from "../../utils";
import { form_label } from "../../../../styles/app-styles";
import { selectStyles } from "../../../../styles/select.styles";
import { formatColumns, formatColumnsGroupBy } from "../../../fileColumns/utils";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import RowGroupBy from "./RowGroupBy";
import { FUNCTION_LABELS } from "../../../summary/constants";
import BaseModal from "../BaseModal";
import ShadowBox from "../ShadowBox";
import MultiSelectSort from "../../../forms/DraggableMultiSelect";
import InfoIcon from "@mui/icons-material/Info";
import RenameGeneratedColumn from "./RenameGeneratedColumn";
import { useStore } from "../../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
import { getBackendId } from "../../../../utils/util";
interface AddFontProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  id: string;
  backendId?: any;
  parentIds?: any[];
  sourceItemId: string;
  nodeData?: any;
}
const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
  groupByRows: store.groupByRows,
  updateGroupByRows: store.updateGroupByRows,
  setInitialNodes: store.setInitialNodes,
  setInitialEdges: store.setInitialEdges,
  updateIsFlowLoading: store.updateIsFlowLoading
});

const GroupBy = ({
  open,
  handleClose,
  dataItem,
  id,
  backendId,
  sourceItemId,
  parentIds,
  nodeData,
}: AddFontProps) => {
  const [comment, setComment] = useState<any>("");
  const [openConfirmCreateGroupByModal, toggleConfirmCreateGroupByModal] =
    useDialog();
  const [openConfirmResetGroupByModal, toggleConfirmResetGroupByModal] =
    useDialog();
  const [message, setMessage] = useState<string>("");
  //Estado del draggable multi selct
  const [columnsSelected, setColumnsSelected] = useState<any>();
  const [columnsError, setColumnsError] = useState<any>([]);
  const store = useStore(selector, shallow);
  const nodesRef = useRef<any[]>([]);
  const flowRef = useRef<any>();
  nodesRef.current = store.nodes;
  flowRef.current = store.flow

  const groupByRows = store.groupByRows;
  /********************************* CARGA DE DATOS ***********************************/

  const {
    data: dataColumns,
    error: errorColumns,
    isLoading: isLoadingColumns,
    callApi: getColumns,
  } = useApi(
    undefined,
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if (dataItem && open) {
      setComment(dataItem.comentario);
      setColumnsSelected(dataItem?.detalle_item?.columnas_agrupar?.map((col: any)=>{return {value: col.label, label: col.label}}));      dataItem?.detalle_item?.columnas_operaciones &&
        store.updateGroupByRows(
          dataItem?.detalle_item?.columnas_operaciones?.map((item: any) => {
            return {
              column: item.columna,
              function: {
                value: item.operacion,
                label: FUNCTION_LABELS[item.operacion],
              },
              renombre: item?.renombre ? item.renombre : undefined,
            };
          })
        );
    }
  }, [dataItem, open]);

  useEffect(() => {
    if (open) {
      getColumns(
        NEW_GET_COLUMNS_FLOW_URL(
          store.flow.id,
          getBackendId(sourceItemId, store.nodes) as number
        )
      );
    }
  }, [open]);

  const handleRenameGeneratedColumns = (newName: string, id: any) => {
    store.updateGroupByRows(
      groupByRows.map((row: any) => {
        return row.column.value === id ? { ...row, renombre: newName } : row;
      })
    );
  };

  /********************************* LLAMADOS ******************************/

  const onSuccessGetFlow = (data: any) => {
    store.setInitialNodes(setErrorFlowNodes(nodesRef.current , data.items));
  };

  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  useEffect(() => {
    store.updateIsFlowLoading(isLoadingFlow)
  }, [isLoadingFlow]);


  // Actualizamos el nodo insertandole el id del backend que es el que vamos a utilizar
  // cuando enviemos datos al back
  const onSuccessPostItem = (data: any) => {
    store.updateNode(id, {
      tipo: "group_by",
      calcular: true,
      comentario: comment,
      columnas_agrupar: columnsSelected?.map((column: any) => column.label),
      columnas_operaciones:
        groupByRows.length > 0
          ? tranformDataGroupByToSend(groupByRows)
          : undefined,
      backendId: backendId ? backendId : data.id,
      error: data.posee_error,
      errorMessage: data.mensaje_error,
    });
    checkIfNodeHasPendingEdges(id, putConnection, data.id)
    getFlow(GET_FLOW_URL(parseInt(store.flow.id)));
  };

  const {
    isLoading: isLoadingPostGroupBy,
    callApi: postGroupBy,
    error: errorPostGroupBy,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutGroupBy,
    callApi: putGroupBy,
    error: errorPutGroupBy,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  

  const {
    isLoading: isLoadingPutConnection,
    callApi: putConnection,
    error: errorPutConnection,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  /******************************* POSTEAR ITEM *********************************************/

  const handleCreateGroupBy = () => {
    toggleConfirmCreateGroupByModal();
    const completedInfo = {
      tipo: "group_by",
      calcular: true,
      editar_detalle_item: true,
      comentario: comment,
      parent_ids: [getBackendId(sourceItemId, store.nodes)],
      id: backendId ? backendId : undefined,
      columnas_agrupar: columnsSelected?.map((column: any) => column.label),
      columnas_operaciones:
        groupByRows.length > 0
          ? tranformDataGroupByToSend(groupByRows)
          : undefined,
      ...nodeData.position,
    };
    if (dataItem) {
      putGroupBy(undefined, [completedInfo]);
    } else {
      postGroupBy(undefined, completedInfo);
    }
    cleanAndClose();
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const handleResetData = () => {
    setColumnsSelected(undefined);
    setMessage("");
    toggleConfirmResetGroupByModal();
  };

  const cleanAndClose = () => {
    setColumnsSelected(undefined);
    setMessage("");
    setComment("");
    handleClose();
  };


  return (
    <BaseModal
      open={open}
      title="Group By"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmCreateGroupByModal}
      toggleConfirmationModal={toggleConfirmCreateGroupByModal}
      handleAccept={handleCreateGroupBy}
      confirmationModalMessage={"crear la agrupación con estos datos"}
      disabledAcceptButton={!columnsSelected || columnsSelected.length === 0}
      height="100%"
      hasResultsTable
      source_id={getBackendId(sourceItemId, store.nodes)}
    >
      <SimpleBackdrop
        open={isLoadingColumns}
        message={texts.flows.getFlowColumns.loading}
      />
      <Stack alignItems={"center"} gap={2} sx={{ pb: "20px" }}>
        {!dataItem &&  <ShadowBox margin>
          <TextField
            id="fontName"
            label={"Comentario"}
            placeholder={"Comentario"}
            size="small"
            multiline
            variant="outlined"
            fullWidth
            value={comment}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setComment(event.target.value);
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
              },
            }}
            required
          />
        </ShadowBox>}
      
        <ShadowBox>
          <InputLabel sx={form_label}>{"Columnas"}</InputLabel>
          <MultiSelectSort
            styles={selectStyles(columnsSelected)}
            options={formatColumnsGroupBy(filterIdFromColumns(dataColumns))}
            name="Columna"
            selected={columnsSelected}
            setSelected={setColumnsSelected}
            placeholder="Seleccionar columnas"
            defaultValue={
              dataItem ? dataItem.detalle_item?.columnas_agrupar : undefined
            }
            isClearable
          />
          <Stack sx={{ flexDirection: "row", gap: 1, pt: 1 }}>
            <InfoIcon
              sx={{
                color: "var(--blue)",
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
            <Typography>
              Reordena las opciones seleccionadas para personalizar el orden de
              las columnas
            </Typography>
          </Stack>
        </ShadowBox>
        <Typography
          variant="subtitle1"
          color="error"
          sx={{ fontWeight: "bold" }}
        >
          {message}
        </Typography>
        <ShadowBox>
          <Stack
            sx={{
              pl: "10px",
              pr: "10px",
              overflow: "auto",
              height: "300px",
            }}
          >
            <InfoGrid
              info={groupByRows}
              RowTitles={SummaryRowTitles}
              RowSelects={GroupByRowSelects}
              RowInfo={RowGroupBy}
              columns={filterIdFromColumns(dataColumns)}
              setMessage={setMessage}
            />
            <Stack
              sx={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
                mt: "10px",
              }}
            >
              <Button
                title="Resetear"
                color="grey"
                type="button"
                onClick={toggleConfirmResetGroupByModal}
                disabled={!groupByRows || groupByRows.length === 0}
              />
            </Stack>
          </Stack>
        </ShadowBox>
        {groupByRows && groupByRows?.length > 0 && (
          <ShadowBox>
            <Stack
              sx={{
                pl: "10px",
                pr: "10px",
                overflow: "auto",
                height: "200px",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "700", color: "var(--blue)" }}
              >
                Renombrar columnas generadas
              </Typography>
              <Typography>
                Elegir el nombre de una columna ya existente, causará que esta
                sea reemplazada.
              </Typography>
              {groupByRows?.map((row: any) => {
                return (
                  <RenameGeneratedColumn
                    key={row.column.value}
                    row={row}
                    newObjectNames={groupByRows}
                    setColumnsError={setColumnsError}
                    setNewObjectNames={handleRenameGeneratedColumns}
                    existingColumns={dataColumns?.map((col: any) => {
                      return col.columna;
                    })}
                  />
                );
              })}
            </Stack>
          </ShadowBox>
        )}
      </Stack>
      <ConfirmationModal
        open={openConfirmResetGroupByModal}
        handleClose={toggleConfirmResetGroupByModal}
        handleAccept={handleResetData}
        title="Resetear datos"
        message="borrar todos los datos"
      />
    </BaseModal>
  );
};

export default GroupBy;
