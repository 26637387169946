import React, { useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import Button from "../buttons/Button";
import errorImage from "../../assets/ErrorBoundaryImage.png";

const ErrorBoundary = () => {
  // Estado para manejar si hubo un error
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);
  const [errorInfo, setErrorInfo] = useState(null);

  // Función para capturar los errores
  const handleError = (error: any, errorInfo: any) => {
    console.log("Error capturado:", error); // Aquí se hace log del error
    console.log("Información adicional del error:", errorInfo); // Log de la info adicional
    setHasError(true); // Marcamos que ocurrió un error
    setError(error);
    setErrorInfo(errorInfo);
  };

  // useEffect para escuchar si hay un error y capturarlo
  useEffect(() => {
    const originalConsoleError = console.error;
    console.error = (...args) => {
      if (args.length > 0 && args[0] instanceof Error) {
        // Cuando un error es lanzado, lo capturamos
        handleError(args[0], args[1] || "Sin información adicional");
      }
      originalConsoleError(...args);
    };
    return () => {
      // Restaurar el comportamiento original de console.error
      console.error = originalConsoleError;
    };
  }, []);

  const handleRedirect = () => {
    window.location.href = "/"; // Redirige usando window.location
  };

  
    return (
      <Stack
        sx={{
          height: "100vh",
          width: "100%",
          backgroundColor: "var(--very-very-light-blue)",
        }}
      >
        <img
          style={{ width: "30%", marginLeft: "auto", marginRight: "auto" }}
          src={errorImage}
          alt="logo"
        />
        <Typography
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            color: "var(--dark-blue)",
            fontWeight: "bold",
            fontSize: "36px",
          }}
        >
          Algo salió mal.
        </Typography>
        <Typography
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            color: "var(--dark-blue)",
            fontWeight: "bold",
            fontSize: "28px",
          }}
        >
          Contáctese con los desarrolladores.
        </Typography>
        <Stack
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Button
            title="Volver al inicio"
            color="blue"
            type="button"
            onClick={handleRedirect}
          />
        </Stack>
      </Stack>
    );
  }

  

export default ErrorBoundary;
