import { Box, Stack, Tooltip, Typography } from "@mui/material";
import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import CustomColumnInput from "./CustomColumnInput";
import { useContext, useEffect, useState } from "react";
import CustomColumnInputError from "./CustomColumnInputError";
import PipelinesStepperProvider from "../../../context/PipelinesStepperProvider";
interface ModifyColumnsModalProps {
  open: boolean;
  handleClose: () => void;
  handleAccept: (columns: any) => void;
  columns: string[] | undefined;
  toggleConfirmResetColumnsModal?: () => void;
  oldHeader: any[];
}

const ModifyColumnsModal = ({
  open,
  handleClose,
  handleAccept,
  columns,
  toggleConfirmResetColumnsModal,
  oldHeader,
}: ModifyColumnsModalProps) => {
  const [columnsError, setColumnsError] = useState<string[]>([]);
  const [newObjectNames, setNewObjectNames] = useState(null);
  const { pipelineState, pipelineDispatch } = useContext(
    PipelinesStepperProvider
  );
  const { isSameStructureFile } = pipelineState;

  useEffect(() => {
    if (columns) {
      const newObject: any = {};
      for (const column of columns) {
        newObject[column] = column;
      }
      setNewObjectNames(newObject);
    }
  }, [columns]);

  function saveValueInObject(object: any, param: any, value: any) {
    const newObject = { ...object };
    // Buscamos la clave (key) del objeto que coincide con el parámetro
    const clave = Object.keys(newObject).find((key) => key === param);
    // Si la clave existe en el objeto, guardamos el valor en esa clave
    if (clave) {
      newObject[clave] = value;
    }
    return newObject;
  }


  const formatNotExistentColumns = () => {
    const nonExistentColumns: any[] = [];
    oldHeader?.forEach((val: any) => {
      if (!columns?.includes(val.nombre_viejo)) {
        nonExistentColumns.push({
          oldName: val.nombre_viejo,
          actualName: val.nombre_nuevo,
        });
      }
    });
    return nonExistentColumns;
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      maxWidth="xl"
      title="Renombrar columnas"
      draggable={true}
    >
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          width: "650px",
          alignItems: "center",
          minHeight: "150px",
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: "10px",
              color: "#172D40",
              marginTop: "30px",
              fontSize: "18px",
              fontWeight: "600",
              textAlign: "center",
              alignSelf: "flex-start",
              marginLeft: "15px",
            }}
          >
            Columnas:
          </Typography>
          <Tooltip
            title="Restablece los nombres de las columnas a los originales, manteniendo la cabecera seteada"
            arrow
          >
            <span>
              <Button
                title="Restablecer columnas"
                color="blue"
                type="button"
                onClick={toggleConfirmResetColumnsModal}
                disabled={false}
              />
            </span>
          </Tooltip>
        </Box>
        {columns &&
          columns?.map((column: string) => {
            return (
              <CustomColumnInput
                key={column}
                column={column}
                setColumnsError={setColumnsError}
                newObjectNames={newObjectNames}
                setNewObjectNames={setNewObjectNames}
                saveValueInObject={saveValueInObject}
              />
            );
          })}
        {!isSameStructureFile && formatNotExistentColumns()?.length > 0 && (
          <>
            <Typography
              variant="h6"
              sx={{
                marginBottom: "10px",
                color: "#172D40",
                marginTop: "30px",
                fontSize: "18px",
                fontWeight: "600",
                textAlign: "center",
                alignSelf: "flex-start",
                marginLeft: "15px",
              }}
            >
              Columnas no existentes en fuente actual:
            </Typography>
            {formatNotExistentColumns()?.length &&
              formatNotExistentColumns()?.map((column: any) => {
                return <CustomColumnInputError column={column} key={column.actualName}/>;
              })}{" "}
          </>
        )}

        <Stack direction="row" sx={{ marginTop: "25px" }}>
          <Button
            title="Cancelar"
            type="button"
            onClick={handleClose}
            color="light-grey"
          />
          <Button
            title="Aceptar"
            type="button"
            onClick={() => {
              handleAccept(newObjectNames);
            }}
            color="blue-greeny"
            disabled={columnsError.length > 0}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ModifyColumnsModal;
