import { Handle, Position } from "@xyflow/react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import useDialog from "../../../../hooks/useDialog";
import MergeIcon from "@mui/icons-material/Merge";
import NodeWithToolbar from "../nodeToolbar/NodeToolbar";
import { useStore } from "../store/store";
import { shallow } from "zustand/shallow";
import useApi from "../../../../hooks/useApi";
import { ITEM_URL } from "../../../../api/axios";
import { texts } from "../../../../texts";
import { isEditable } from "../../../../utils/util";
import Concat from "../../Modals/Concat";
import CustomHandle from "../handles/CustomHandle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import NodeComment from "./NodeComment";
import { useEffect, useState } from "react";
import { getRandomColor } from "../../utils";

interface ConcatNodeProps {
  id: any;
  data: any;
}

const selector = (store: any) => ({
  flow: store.flow,
  nodes: store.nodes,
  currentColors: store.currentColors,
  updateColors: store.updateColors,
  updateNode: store.updateNode,
  isLoadingFlow: store.isLoadingFlow

});

export default function ConcatNode({ id, data }: ConcatNodeProps) {
  const [openConcatModal, handleToggleEditConcatModal] = useDialog();
  const [color, setColor] = useState<string | null>("");

  const store = useStore(selector, shallow);
  const node = store.nodes.find((node: any) => node.id === id);

  useEffect(() => {
    const newColor =getRandomColor(store.currentColors)
    setColor(newColor)
    store.updateColors([...store.currentColors,  newColor])
    store.updateNode(id, {...node.data, color: newColor})

 }, [])


  const onSuccessGetItem = () => {
    handleToggleEditConcatModal();
  };

  const {
    data: dataItem,
    callApi: getDataItem,
    setData: setDataItem,
    isLoading: isLoadingGetItem,
  } = useApi(
    ITEM_URL(store.flow?.id, data?.backendId),
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    onSuccessGetItem,
    undefined,
    false
  );

  const handleEdit = () => {
    if (data.backendId) {
      getDataItem();
    } else {
      handleToggleEditConcatModal();
    }
  };

  return (
    <Stack sx={{ alignItems: "center", width: "45px",height:"64px" }}>
            <SimpleBackdrop open={isLoadingGetItem} message={"Cargando datos..."} />

      <NodeWithToolbar
        handleEdit={handleEdit}
        backendId={data.backendId}
        isEditable={
          data?.parentIds?.length > 1 &&
          isEditable(data?.parentIds, store.nodes)
        }
        nodeData={node}
      />

      <CustomHandle
        type="target"
        position={Position.Left}
        id={`handle-target-2-${id}`}
        connectionCount={20}
        nodeId={id}
      />
      {data.error && (
        <WarningAmberIcon
          style={{
            fontSize: 12,
            color: "var(--red)",
            position: "absolute",
            top: -5,
            right: 5,
          }}
        />
      )}
           {store.isLoadingFlow && (
        <CircularProgress
          sx={{
            width: "10px !important",
            height: "10px !important",
            right: "0",
            position: "absolute",
          }}
        />
      )}
      <MergeIcon
        style={{
          fontSize: 40,
          color: `var(--${color})`, 

        }}
      />
      <Typography sx={{ fontSize: "8px" }}>
        Concat {data.comentario ? ": " : ""}
      </Typography>
      <NodeComment id={id} data={data}/>
      <CustomHandle
        type="source"
        position={Position.Right}
        id={`handle-source-2-${id}`}
        connectionCount={1}
        nodeId={id}
      />
      <Concat
        handleClose={handleToggleEditConcatModal}
        open={openConcatModal}
        dataItem={dataItem}
        id={id}
        backendId={data.backendId}
        parentIds={data.parentIds}
        nodeData={node}
      />
    </Stack>
  );
}
