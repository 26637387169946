import { convertArrayToText } from "../../utils/util";
import { GridColDef } from "@mui/x-data-grid";
import { prepareRowsToDelete } from "./utils";
import { selectStyles } from "../../styles/select.styles";
import { Tooltip, Typography } from "@mui/material";
import { transformData } from "../virtualizedTable/transformData";
import { useContext, useEffect, useState } from "react";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import InfoIcon from "@mui/icons-material/Info";
import MultiSelectSort from "../forms/DraggableMultiSelect";
import PipelinesStepperProvider from "../../context/PipelinesStepperProvider";
import Stack from "@mui/material/Stack";
import StepLayout from "./StepLayout";
import VirtualizatedTable from "../virtualizedTable/VirtualizedTable";

const Step3 = ({ pipelineToEdit }: any) => {
  const { pipelineState, pipelineDispatch } = useContext<
    React.ContextType<typeof PipelinesStepperProvider>
  >(PipelinesStepperProvider);
  const {
    file,
    selected_sheet,
    rows_to_delete,
    newData,
    header,
    data,
    selectedColumns: selectedColumnsContext,
    actualColumns,
    actualRows,
    replacedFile,
    isSameStructureFile
  } = pipelineState;
  const [columns, setColumns] = useState<GridColDef<any, any, any>[]>([
    {
      field: "",
      headerName: "",
      width: 900,
      renderCell: () => <></>,
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);

  const formatColumns = (data: any[], header: boolean) => {
    const result = [];

    if (header) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          result.push({
            value: data[key],
            label: data[key],
          });
        }
      }
    } else {
      for (const key in data) {
        if (data.hasOwnProperty(key) && key !== "id") {
          result.push({
            value: key,
            label: key,
          });
        }
      }
    }

    return result;
  };

  //Estado del draggable multi selct
  const [columnsSelected, setColumnsSelected] = useState<any>(
    selectedColumnsContext.length > 0
      ? selectedColumnsContext
      : header && header.renombre && header.renombre.length > 0
      ? formatColumns(header.renombre[0], true)
      : header && header.data && header.data.length > 0
      ? formatColumns(header.data[0], true)
      : formatColumns(data[0], false)
  );

  useEffect(() => {
    if (pipelineToEdit) {
      setRows(actualRows)
      setColumns(actualColumns)
    } else {
      if (newData.length > 0) {
        setRows(newData);
      } else {
        setRows(data);
      }
      if (header.data.length > 0 && header.id) {
        const row = header.data[0];
        const { columns: newColumns, rows: newRows } = transformData(
          newData,
          row
        );

        setColumns(newColumns.filter((column: any) => column.field !== "id"));
      } else {
        if (header.data.length > 0) {
          const row = header.data[0];
          const { columns: newColumns, rows: newRows } = transformData(
            data,
            row
          );
          setColumns(newColumns.filter((column: any) => column.field !== "id"));
        } else {
          const { columns, rows } = transformData(data);
          setColumns(columns.filter((column: any) => column.field !== "id"));
        }
      }
    }

    setColumnsSelected(
      selectedColumnsContext.length > 0 && isSameStructureFile
        ? selectedColumnsContext
        : header && header.renombre && header.renombre.length > 0
        ? formatColumns(header.renombre[0], true)
        : header && header.data && header.data.length > 0
        ? formatColumns(header.data[0], true)
        : formatColumns(data[0], false)
    );
    pipelineDispatch({
      type: "SET_SELECTED_COLUMNS",
      payload: columnsSelected,
    });
  }, []);

  const handleChangeColumnsSelected = (columns: any) => {
    setColumnsSelected(columns);
    pipelineDispatch({ type: "SET_SELECTED_COLUMNS", payload: columns });
  };

  return (
    <StepLayout stepTitle="Confirmar y guardar">
      <Stack width="100%" height="80vh" gap="10px" overflow="auto">
        <Stack
          sx={{
            backgroundColor: "var(--cream)",
            padding: "12px 12px !important",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Stack alignItems={"flex-start"} width="100%" pl="15px">
            <Stack direction="row" alignItems={"center"} mb={"10px"}>
              <FolderOpenOutlinedIcon
                sx={{ fontSize: 20, textAlign: "center" }}
              />
              <Typography
                variant="subtitle1"
                color="primary"
                sx={{ textAlign: "center", width: "100%", paddingLeft: "10px" }}
              >
                <span style={{ fontWeight: "bold" }}>ARCHIVO USADO:</span>{" "}
                {file.name} ({selected_sheet.label})
              </Typography>
            </Stack>
            <Stack direction="row" alignItems={"center"} mb={"10px"}>
              <CreditCardOutlinedIcon
                sx={{ fontSize: 20, textAlign: "center" }}
              />
              <Typography
                variant="subtitle1"
                color="primary"
                sx={{ textAlign: "center", paddingLeft: "10px" }}
              >
                <span style={{ fontWeight: "bold" }}>CABECERA:</span>{" "}
                {header.id !== null
                  ? `Fila ${parseInt(header.id) + 1}`
                  : "Sin modificar"}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems={"center"}>
              <DeleteOutlineOutlinedIcon
                sx={{ fontSize: 23, textAlign: "center" }}
              />
              <Typography
                variant="subtitle1"
                color="primary"
                sx={{ textAlign: "center", paddingLeft: "10px" }}
              >
                <span style={{ fontWeight: "bold" }}>FILAS A ELIMINAR:</span>{" "}
                {prepareRowsToDelete(
                  rows_to_delete?.rows_to_delete_before_header_setting,
                  rows_to_delete?.rows_to_delete_after_header_setting
                ).length > 0
                  ? convertArrayToText(
                      prepareRowsToDelete(
                        rows_to_delete?.rows_to_delete_before_header_setting,
                        rows_to_delete?.rows_to_delete_after_header_setting
                      ).map((row: number) => `Fila ${row < 0 ? row : row + 1}`)
                    )
                  : ""}
              </Typography>
            </Stack>

            <Stack width={"100%"} marginTop="25px" alignItems="flex-start">
              <Stack direction="row">
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{ textAlign: "center", width: "100%" }}
                >
                  <span style={{ fontWeight: "bold" }}>COLUMNAS:</span>{" "}
                </Typography>
              </Stack>
              <Stack direction="row" width="100%">
                <MultiSelectSort
                  styles={selectStyles(columnsSelected, "1100px")}
                  options={
                    header && header.renombre && header.renombre.length > 0
                      ? formatColumns(header.renombre[0], true)
                      : header && header.data && header.data.length > 0
                      ? formatColumns(header.data[0], true)
                      : formatColumns(data[0], false)
                  }
                  name="Columna"
                  selected={columnsSelected}
                  setSelected={handleChangeColumnsSelected}
                  placeholder="Seleccionar columnas a conservar"
                  defaultValue={
                    header && header.renombre && header.renombre.length > 0
                      ? formatColumns(header.renombre[0], true)
                      : header && header.data && header.data.length > 0
                      ? formatColumns(header.data[0], true)
                      : formatColumns(data[0], false)
                  }
                  isClearable
                />
                <Tooltip
                  title={
                    "Selecciona las columnas que desees conservar y arrastralas para reordenarlas"
                  }
                  placement={"right"}
                  sx={{
                    pointerEvents: "fill",
                  }}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      gap: 1,
                      pt: 1,
                      marginLeft: "5px",
                    }}
                  >
                    <InfoIcon
                      sx={{
                        color: "var(--blue)",
                        fontSize: "24px",
                        cursor: "pointer",
                      }}
                    />
                  </Stack>
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          sx={{
            backgroundColor: "var(--cream)",
            padding: "12px 12px !important",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <VirtualizatedTable
            columns={columns}
            rows={rows}
            rowsPerPageOptions={[5, 10, 20]}
            rowHeight={35}
            tableHeight={280}
            hideFooter={true}
            toolbar={false}
            setSelectedRows={undefined}
            checkboxSelection={false}
          />
        </Stack>
      </Stack>
    </StepLayout>
  );
};

export default Step3;
