import { InputLabel, Stack, TextField } from "@mui/material";
import Dialog from "../../../dialog/Dialog";
import Button from "../../../buttons/Button";
import Select from "../../../forms/Select";
import { form_label } from "../../../../styles/app-styles";
import { useEffect, useRef, useState } from "react";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import { selectStyles } from "../../../../styles/select.styles";
import {
  formatOptions,
  formatTemplatePhiName,
  getBackendId,
} from "../../../../utils/util";
import useApi from "../../../../hooks/useApi";
import {
  FLOW_CONFIGURATION,
  FLOW_CONFIGURATION_BY_ID,
  FLOW_ITEMS,
  NEW_GET_COLUMNS_FLOW_URL,
} from "../../../../api/axios";
import { texts } from "../../../../texts";
import ColorTabs from "../../../colorTabs/ColorTabs";
import { Tab, TabValue } from "../../../colorTabs/types";
import SaveNameModal from "../../../dialog/SaveNameModal";
import useDialog from "../../../../hooks/useDialog";
import { ActionMeta, SingleValue } from "react-select";
import { IdOption } from "../../../forms/types";
import CalculationConfigs from "./CalculationConfigs";
import NewConfigurationGeneralTab from "./NewConfigurationGeneralTab";
import PhiTemplateConfigurationTab from "./PhiTemplateConfigurationTab";
import { useStore } from "../../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";

interface FileFormatModalProps {
  open: boolean;
  handleClose: () => void;
  id: string;
  backendId?: number;
  dataItem?: any;
  sourceItemId: string;
  nodeData?: any;
}

const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
});

const FileFormatModal = ({
  open,
  handleClose,
  id,
  backendId,
  sourceItemId,
  dataItem,
  nodeData,
}: FileFormatModalProps) => {
  const formatSelectRef: any = useRef(null);
  const configurationRef: any = useRef(null);
  const columnSelectRef: any = useRef(null);
  const templatePhiNameRef: any = useRef(null);
  const monthSelectRef: any = useRef(null);
  const yearSelectRef: any = useRef(null);
  const [desdinamizeFile, setDesdinamizeFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const [columns, setColumns] = useState<any>([]);
  const [tabValue, setTabValue] = useState<TabValue>("NUEVA CONFIGURACIÓN");
  const [newConfigTabValue, setNewConfigTabValue] =
    useState<TabValue>("GENERAL");
  const [openSaveConfigModal, toggleOpenSaveConfigModal] = useDialog();
  const [editName, setEditName] = useState("");
  const [assignPeriods, setAssignPeriods] = useState("manual");
  const [conservatePeriods, setConservatePeriods] = useState("column");
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([]);
  const [overwritePeriods, setOverwritePeriods] = useState(false);
  const store = useStore(selector, shallow);
  const [comment, setComment] = useState<any>("");
  const [delayHelper, setDelayHelper] = useState<boolean>(false);

  const tabs: Tab[] = [
    {
      value: "NUEVA CONFIGURACIÓN",
      label: "NUEVA CONFIGURACIÓN",
    },
    {
      value: "CONFIGURACIONES GUARDADAS",
      label: "CONFIGURACIONES GUARDADAS",
    },
  ];

  const newConfigTabs: Tab[] = [
    {
      value: "GENERAL",
      label: "GENERAL",
    },
    {
      value: "TEMPLATE PARA PHI",
      label: "TEMPLATE PARA PHI",
    },
  ];

  const onSuccesSaveConfig = (data: any) => {
    if (tabValue === "CONFIGURACIONES GUARDADAS") {
      saveCalculate(configurationSelectedOption?.value as number);
    } else {
      saveCalculate(data);
    }
  };
  const {
    data: dataConfigs,
    isLoading: isLoadingGetConfigs,
    callApi: getConfigs,
    error: errorGetConfigs,
  } = useApi(
    FLOW_CONFIGURATION,
    "GET",
    texts.flows.getFlowConfigs.codes,
    undefined,
    undefined,
    undefined,
    false,
    undefined,
    undefined
  );

  const {
    isLoading: isLoadingPostConfig,
    callApi: postConfig,
    data: dataPostConfig,
  } = useApi(
    FLOW_CONFIGURATION,
    "POST",
    texts.flows.postFlowConfigs.codes,
    undefined,
    (data) => {
      onSuccesSaveConfig(data);
    },
    undefined,
    false
  );

  const { isLoading: isLoadingPutConfig, callApi: putConfig } = useApi(
    FLOW_CONFIGURATION,
    "PUT",
    texts.flows.postFlowConfigs.codes,
    undefined,
    (data) => {
      onSuccesSaveConfig(data);
    },
    handleClose,
    false
  );

  const {
    data: dataColumns,
    isLoading: isLoadingColumns,
    callApi: getColumns,
  } = useApi(
    undefined,
    "GET",
    texts.flows.getFlowColumns.codes,
    undefined,
    (data) => {
      onSuccessGetDataColumns(data);
    },
    undefined,
    false
  );

  const [
    formatSelectedOption,
    setFormatSelectedOption,
    formatOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    configurationSelectedOption,
    setConfigurationSelectedOption,
    configurationChangeHandler,
  ] = useSingleSelect();

  const [
    templatePhiNameSelectedOption,
    setTemplatePhiNameSelectedOption,
    templatePhiNameChangeHandler,
  ] = useSingleSelect();

  const [monthSelectedOption, setMonthSelectedOption, monthChangeHandler] =
    useSingleSelect();

  const [yearSelectedOption, setYearSelectedOption, yearChangeHandler] =
    useSingleSelect();

  useEffect(() => {
    if (open) {
      getColumns(
        NEW_GET_COLUMNS_FLOW_URL(
          store.flow.id,
          getBackendId(sourceItemId, store.nodes) as number
        )
      );
    }
    setTimeout(() => {
      setDelayHelper(true);
    }, 2);
  }, [open]);

  useEffect(() => {
    if (dataItem && open) {
      setDelayHelper(false);
      const data = dataItem.detalle_item;
      setComment(dataItem.comentario);
      setDesdinamizeFile(data.desconsolidar);
      setFileName(data.nombre_archivo);
      setColumnSelectedOption({
        value: data.columna_desconsolidar,
        label: data.columna_desconsolidar,
      });
      setSelectedFolder(data.ubicacion_archivo);
      setFormatSelectedOption({
        value: data.formato_archivo,
        label: data.formato_archivo,
      });
      setTabValue(
        data.tipo === "configuracion"
          ? "CONFIGURACIONES GUARDADAS"
          : "NUEVA CONFIGURACIÓN"
      );
      setNewConfigTabValue(data?.template_phi ? "TEMPLATE PARA PHI" : "GENERAL");
      if (data.tipo === "configuracion") {
        const config = dataConfigs?.find(
          (config: any) => config.id === data.id
        );
        setConfigurationSelectedOption({
          value: data.id,
          label: config?.nombre,
        });
      }
      if (data?.template_phi) {
        setAssignPeriods(data.desconsolidar ? "deconsolidate" : "manual");
        if (data.desconsolidar) {
          setConservatePeriods(
            data?.lista_periodos?.length === 0 ? "column" : "select-periods"
          );
          setTemplatePhiNameSelectedOption({
            value: data.nombre_archivo,
            label: data.nombre_archivo,
          });
          setSelectedPeriods(data?.lista_periodos);
        } else {
          setTemplatePhiNameSelectedOption({
            value: formatTemplatePhiName(data.nombre_archivo),
            label: formatTemplatePhiName(data.nombre_archivo),
          });
          setMonthSelectedOption({
            value:
              data.nombre_archivo.split("-")[
                data.nombre_archivo.split("-").length - 2
              ],
            label:
              data.nombre_archivo.split("-")[
                data.nombre_archivo.split("-").length - 2
              ],
          });
          setYearSelectedOption({
            value:
              data.nombre_archivo.split("-")[
                data.nombre_archivo.split("-").length - 1
              ],
            label:
              data.nombre_archivo.split("-")[
                data.nombre_archivo.split("-").length - 1
              ],
          });
          setOverwritePeriods(data.sobreescribir_periodos_existentes);
        }
      }
      setTimeout(() => {
        setDelayHelper(true);
      }, 1000);
    }
  }, [dataItem, open]);

  useEffect(() => {
    setDelayHelper(false);
    if (configurationSelectedOption) {
      const config = dataConfigs.find(
        (config: any) => config.id === configurationSelectedOption?.value
      );
      if (!config) {
        setConfigurationSelectedOption(undefined);
        configurationRef?.current.clearValue();
      } else {
        setConfigurationSelectedOption({
          value: config.id,
          label: config.nombre,
        });
      }
    }
    setTimeout(() => {
      setDelayHelper(true);
    }, 5);
  }, [dataConfigs]);

  useEffect(() => {
    if (open) {
      getConfigs(FLOW_CONFIGURATION(store.flow.id));
    }
  }, [open]);

  /******************************* POST Y PUT ITEM *********************************************/

  // Actualizamos el nodo insertandole el id del backend que es el que vamos a utilizar
  // cuando enviemos datos al back
  const onSuccessPostItem = (data: any) => {
    let completedInfo: any = {
      tipo: "calcular",
      comentario: comment,
      editar_detalle_item: true,
    };
    if (tabValue === "CONFIGURACIONES GUARDADAS" || dataPostConfig) {
      completedInfo = {
        ...completedInfo,
        configuracion:
          tabValue === "CONFIGURACIONES GUARDADAS"
            ? configurationSelectedOption?.value
            : dataPostConfig,
      };
    } else {
      if (
        newConfigTabValue === "TEMPLATE PARA PHI" ||
        (configurationSelectedOption &&
          dataConfigs.find(
            (config: any) => config.id === configurationSelectedOption?.value
          ).template_phi)
      ) {
        completedInfo = {
          ...completedInfo,
          nombre_archivo:
            assignPeriods === "manual"
              ? `${templatePhiNameSelectedOption?.label}-${monthSelectedOption?.label}-${yearSelectedOption?.label}`
              : templatePhiNameSelectedOption?.label,
          template_phi: true,
          ubicacion_archivo: `PHI/${templatePhiNameSelectedOption?.label}`,
          formato_archivo: formatSelectedOption?.value,
          desconsolidar: assignPeriods === "deconsolidate",
          columna_desconsolidar:
            assignPeriods === "deconsolidate"
              ? columnSelectedOption?.label
              : undefined,
          lista_periodos:
            assignPeriods === "deconsolidate" &&
            conservatePeriods === "select-periods"
              ? selectedPeriods
              : [],
          sobreescribir_periodos_existentes: overwritePeriods,
        };
      } else {
        completedInfo = {
          ...completedInfo,
          nombre_archivo: fileName,
          ubicacion_archivo:
            selectedFolder === "Carpeta raíz" ? "" : selectedFolder,
          formato_archivo: formatSelectedOption?.value,
          desconsolidar: desdinamizeFile || assignPeriods === "deconsolidate",
          columna_desconsolidar:
            desdinamizeFile || assignPeriods === "deconsolidate"
              ? columnSelectedOption?.label
              : undefined,
        };
      }
    }

    store.updateNode(id, {
      ...completedInfo,
      backendId: backendId ? backendId : data.id,
      error: data.posee_error,
      errorMessage: data.mensaje_error,
    });
  };

  const {
    isLoading: isLoadingPostCalculate,
    callApi: postCalculate,
    error: errorPostCalculate,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutCalculate,
    callApi: putCalculate,
    error: errorPutCalculate,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const saveCalculate = (id_config?: number) => {
    let completedInfo: any = {
      tipo: "calcular",
      comentario: comment,
      editar_detalle_item: true,
      calcular: true,
      parent_ids: [getBackendId(sourceItemId, store.nodes)],
      id: backendId ? backendId : undefined,
      ...nodeData.position,
    };
    if (id_config) {
      completedInfo = {
        ...completedInfo,
        configuracion: id_config,
      };
    } else {
      if (
        newConfigTabValue === "TEMPLATE PARA PHI" ||
        (configurationSelectedOption &&
          dataConfigs.find(
            (config: any) => config.id === configurationSelectedOption?.value
          )?.template_phi)
      ) {
        completedInfo = {
          ...completedInfo,
          nombre_archivo:
            assignPeriods === "manual"
              ? `${templatePhiNameSelectedOption?.label}-${monthSelectedOption?.label}-${yearSelectedOption?.label}`
              : templatePhiNameSelectedOption?.label,
          template_phi: true,
          ubicacion_archivo: `PHI/${templatePhiNameSelectedOption?.label}`,
          formato_archivo: formatSelectedOption?.value,
          desconsolidar: assignPeriods === "deconsolidate",
          columna_desconsolidar:
            assignPeriods === "deconsolidate"
              ? columnSelectedOption?.label
              : undefined,
          lista_periodos:
            assignPeriods === "deconsolidate" &&
            conservatePeriods === "select-periods"
              ? selectedPeriods
              : [],
          sobreescribir_periodos_existentes: overwritePeriods,
        };
      } else {
        completedInfo = {
          ...completedInfo,
          nombre_archivo: fileName,
          ubicacion_archivo:
            selectedFolder === "Carpeta raíz" ? "" : selectedFolder,
          formato_archivo: formatSelectedOption?.value,
          desconsolidar: desdinamizeFile || assignPeriods === "deconsolidate",
          columna_desconsolidar:
            desdinamizeFile || assignPeriods === "deconsolidate"
              ? columnSelectedOption?.label
              : undefined,
        };
      }
    }
    if (dataItem) {
      putCalculate(undefined, [completedInfo]);
    } else {
      postCalculate(undefined, completedInfo);
    }
    closeAndClean();
  };

  const closeAndClean = () => {
    setFormatSelectedOption(null);
    setColumnSelectedOption(undefined);
    setDesdinamizeFile(false);
    setFileName("");
    setTabValue("NUEVA CONFIGURACIÓN");
    setNewConfigTabValue("GENERAL");
    setConfigurationSelectedOption(undefined);
    setEditName("");
    setComment("");
    setMonthSelectedOption(undefined);
    setYearSelectedOption(undefined);
    handleClose();
    setDelayHelper(false);
  };

  const onSuccessGetDataColumns = (data: any) => {
    setColumns(data);
  };

  const validateAccept = () => {
    let result = false;
    const resultMessage = "";
    if (newConfigTabValue !== "TEMPLATE PARA PHI") {
      if (
        fileName === "" ||
        formatSelectedOption === null ||
        formatSelectedOption === undefined ||
        ((assignPeriods === "deconsolidate" || desdinamizeFile) &&
          (columnSelectedOption === null || columnSelectedOption === undefined))
      ) {
        result = true;
      }
    } else {
      if (
        templatePhiNameSelectedOption === undefined ||
        templatePhiNameSelectedOption === null ||
        formatSelectedOption === null ||
        formatSelectedOption === undefined ||
        ((assignPeriods === "deconsolidate" || desdinamizeFile) &&
          (columnSelectedOption === null ||
            columnSelectedOption === undefined)) ||
        (conservatePeriods === "select-periods" &&
          selectedPeriods.length === 0) ||
        (assignPeriods === "manual" &&
          (monthSelectedOption === undefined ||
            monthSelectedOption === null ||
            yearSelectedOption === undefined ||
            yearSelectedOption === null))
      ) {
        result = true;
      }
    }

    return { result, resultMessage };
  };

  const handleChangeSelectedFolder = (location: string) => {
    if (location === "") {
      setSelectedFolder("");
    } else {
      setSelectedFolder(location);
    }
  };


  const handleChangeTabs = (
    event: React.SyntheticEvent,
    newValue: TabValue
  ) => {
    setTabValue(newValue);
    if (!dataItem) {
      setFormatSelectedOption(null);
      setColumnSelectedOption(undefined);
      setDesdinamizeFile(false);
      setFileName("");
      setConfigurationSelectedOption(undefined);
      setEditName("");
      setSelectedFolder("");
      setTemplatePhiNameSelectedOption(undefined);
      templatePhiNameRef?.current?.clearValue();
      formatSelectRef?.current?.clearValue();
      setAssignPeriods("manual");
      setOverwritePeriods(false);
      setMonthSelectedOption(undefined);
      monthSelectRef?.current?.clearValue();
      setYearSelectedOption(undefined);
      yearSelectRef?.current?.clearValue();
      columnSelectRef?.current?.clearValue();
      setSelectedPeriods([]);
    }
  };

  const handleChangeNewConfigTabs = (
    event: React.SyntheticEvent,
    newValue: TabValue
  ) => {
    setNewConfigTabValue(newValue);
    if (!dataItem) {
      setFormatSelectedOption(null);
      setColumnSelectedOption(undefined);
      setDesdinamizeFile(false);
      setFileName("");
      setConfigurationSelectedOption(undefined);
      setEditName("");
      setSelectedFolder("");
    }
  };

  const handleChangeConfigSelectedOption = (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => {
    if (newValue !== null && newValue !== undefined) {
      const selectedConfig = dataConfigs.find(
        (config: any) => config.id === newValue?.value
      );
      if (selectedConfig?.template_phi) {
        if (selectedConfig.desconsolidar) {
          setTemplatePhiNameSelectedOption({
            value: selectedConfig.nombre_archivo,
            label: selectedConfig.nombre_archivo,
          });
          setConservatePeriods(
            selectedConfig?.lista_periodos?.length === 0
              ? "column"
              : "select-periods"
          );
          setSelectedPeriods(selectedConfig?.lista_periodos);
        } else {
          setTemplatePhiNameSelectedOption({
            value: formatTemplatePhiName(selectedConfig.nombre_archivo),
            label: formatTemplatePhiName(selectedConfig.nombre_archivo),
          });
          setMonthSelectedOption({
            value: selectedConfig.nombre_archivo.split("-")[1],
            label: selectedConfig.nombre_archivo.split("-")[1],
          });
          setYearSelectedOption({
            value: selectedConfig.nombre_archivo.split("-")[2],
            label: selectedConfig.nombre_archivo.split("-")[2],
          });
        }
        setOverwritePeriods(selectedConfig.sobreescribir_periodos_existentes);
        setAssignPeriods(
          selectedConfig.desconsolidar ? "deconsolidate" : "manual"
        );
      }
      setEditName(selectedConfig.nombre);
      setFileName(selectedConfig.nombre_archivo);
      setColumnSelectedOption({
        value: selectedConfig.columna_desconsolidar,
        label: selectedConfig.columna_desconsolidar,
      });
      setSelectedFolder(selectedConfig.ubicacion_archivo);
      setFormatSelectedOption({
        value: selectedConfig.formato_archivo,
        label: selectedConfig.formato_archivo,
      });
      setDesdinamizeFile(selectedConfig.desconsolidar);
    }
    setTimeout(() => {
      configurationChangeHandler(newValue, actionMeta);
    }, 5);
  };

  const handleAcceptSaveConfig = () => {
    if (tabValue === "NUEVA CONFIGURACIÓN") {
      if (newConfigTabValue === "TEMPLATE PARA PHI") {
        postConfig(FLOW_CONFIGURATION(store.flow.id), {
          nombre: editName,
          nombre_archivo:
            assignPeriods === "manual"
              ? `${templatePhiNameSelectedOption?.label}-${monthSelectedOption?.label}-${yearSelectedOption?.label}`
              : templatePhiNameSelectedOption?.label,
          template_phi: true,
          ubicacion_archivo: `PHI/${templatePhiNameSelectedOption?.label}`,
          formato_archivo: formatSelectedOption?.value,
          desconsolidar: assignPeriods === "deconsolidate",
          columna_desconsolidar:
            assignPeriods === "deconsolidate"
              ? columnSelectedOption?.label
              : undefined,
          lista_periodos:
            assignPeriods === "deconsolidate" &&
            conservatePeriods === "select-periods"
              ? selectedPeriods
              : [],
          sobreescribir_periodos_existentes: overwritePeriods,
        });
      } else {
        postConfig(FLOW_CONFIGURATION(store.flow.id), {
          nombre: editName,
          nombre_archivo: fileName,
          ubicacion_archivo:
            selectedFolder === "Carpeta raíz" ? "" : selectedFolder,
          formato_archivo: formatSelectedOption?.value,
          desconsolidar: desdinamizeFile || assignPeriods === "deconsolidate",
          columna_desconsolidar:
            desdinamizeFile || assignPeriods === "deconsolidate"
              ? columnSelectedOption?.label
              : undefined,
        });
      }
      toggleOpenSaveConfigModal();
    } else {
      const selectedConfig: any = dataConfigs.find(
        (config: any) => config.id === configurationSelectedOption?.value
      );
      if (selectedConfig?.template_phi) {
        putConfig(
          FLOW_CONFIGURATION_BY_ID(
            store.flow?.id,
            configurationSelectedOption?.value as number
          ),
          {
            nombre_archivo:
              assignPeriods === "manual"
                ? `${templatePhiNameSelectedOption?.label}-${monthSelectedOption?.label}-${yearSelectedOption?.label}`
                : templatePhiNameSelectedOption?.label,
            template_phi: true,
            ubicacion_archivo: `PHI/${templatePhiNameSelectedOption?.label}`,
            formato_archivo: formatSelectedOption?.value,
            desconsolidar: assignPeriods === "deconsolidate",
            columna_desconsolidar:
              assignPeriods === "deconsolidate"
                ? columnSelectedOption?.label
                : undefined,
            lista_periodos:
              assignPeriods === "deconsolidate" &&
              conservatePeriods === "select-periods"
                ? selectedPeriods
                : [],
            sobreescribir_periodos_existentes: overwritePeriods,
          }
        );
      } else {
        putConfig(
          FLOW_CONFIGURATION_BY_ID(
            store.flow.id,
            configurationSelectedOption?.value as number
          ),
          {
            nombre_archivo: fileName,
            ubicacion_archivo:
              selectedFolder === "Carpeta raíz" ? "" : selectedFolder,
            formato_archivo: formatSelectedOption?.value,
            desconsolidar: desdinamizeFile || assignPeriods === "deconsolidate",
            columna_desconsolidar:
              desdinamizeFile || assignPeriods === "deconsolidate"
                ? columnSelectedOption?.label
                : undefined,
          }
        );
      }
    }
  };

  const addPeriod = () => {
    if (
      !selectedPeriods.includes(
        `${monthSelectedOption?.value}-${yearSelectedOption?.value}`
      )
    ) {
      setSelectedPeriods([
        ...selectedPeriods,
        `${monthSelectedOption?.value}-${yearSelectedOption?.value}`,
      ]);
      setMonthSelectedOption(undefined);
      monthSelectRef?.current?.clearValue();
      setYearSelectedOption(undefined);
      yearSelectRef?.current?.clearValue();
    }
  };

  const deletePeriod = (period: string) => {
    setSelectedPeriods(selectedPeriods.filter((p: any) => p !== period));
  };

  return (
    <Dialog
      open={open}
      handleClose={closeAndClean}
      maxWidth="xl"
      title={"Calcular el flujo"}
    >
      <SimpleBackdrop
        open={
          isLoadingPostConfig ||
          isLoadingPutConfig ||
          isLoadingPostCalculate ||
          isLoadingPutCalculate
        }
        message={texts.flows.postFlowConfigs.loading}
      />
      <Stack
        sx={{
          margin: "0 15px 5px 15px",
          alignItems: "flex-start",
          minHeight: "250px",
          minWidth: "950px",
        }}
      >
        {!dataItem && (
          <TextField
            id="comment"
            label={"Comentario"}
            placeholder={"Comentario"}
            size="small"
            multiline
            variant="outlined"
            fullWidth
            value={comment}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setComment(event.target.value);
            }}
            FormHelperTextProps={{
              sx: {
                color: "var(--magenta)",
              },
            }}
            required
          />
        )}

        <Stack width="100%" gap={2} justifyContent={"flex-start"}>
          <ColorTabs
            value={tabValue}
            handleChange={handleChangeTabs}
            tabs={tabs}
            marginBottom="10px"
          />
        </Stack>
        {tabValue === "NUEVA CONFIGURACIÓN" && (
          <>
            <Stack width="100%" gap={2} justifyContent={"flex-start"}>
              <ColorTabs
                value={newConfigTabValue}
                handleChange={handleChangeNewConfigTabs}
                tabs={newConfigTabs}
                marginBottom="10px"
              />
            </Stack>
            {newConfigTabValue === "GENERAL" && delayHelper && (
              <NewConfigurationGeneralTab
                formatSelectRef={formatSelectRef}
                columnSelectRef={columnSelectRef}
                desdinamizeFile={desdinamizeFile}
                setDesdinamizeFile={setDesdinamizeFile}
                fileName={fileName}
                setFileName={setFileName}
                columns={columns}
                formatSelectedOption={formatSelectedOption}
                formatOptionsChangeHandler={formatOptionsChangeHandler}
                columnSelectedOption={columnSelectedOption}
                columnOptionsChangeHandler={columnOptionsChangeHandler}
                handleChangeSelectedFolder={handleChangeSelectedFolder}
                validateAccept={validateAccept}
                selectedFolder={selectedFolder}
              />
            )}

            {newConfigTabValue === "TEMPLATE PARA PHI" && delayHelper && (
              <PhiTemplateConfigurationTab
                formatSelectRef={formatSelectRef}
                columnSelectRef={columnSelectRef}
                columns={columns}
                formatSelectedOption={formatSelectedOption}
                formatOptionsChangeHandler={formatOptionsChangeHandler}
                columnSelectedOption={columnSelectedOption}
                columnOptionsChangeHandler={columnOptionsChangeHandler}
                validateAccept={validateAccept}
                templatePhiNameRef={templatePhiNameRef}
                templatePhiNameSelectedOption={templatePhiNameSelectedOption}
                templatePhiNameChangeHandler={templatePhiNameChangeHandler}
                monthSelectRef={monthSelectRef}
                monthChangeHandler={monthChangeHandler}
                monthSelectedOption={monthSelectedOption}
                yearSelectRef={yearSelectRef}
                yearSelectedOption={yearSelectedOption}
                yearChangeHandler={yearChangeHandler}
                addPeriod={addPeriod}
                deletePeriod={deletePeriod}
                assignPeriods={assignPeriods}
                setAssignPeriods={setAssignPeriods}
                conservatePeriods={conservatePeriods}
                setConservatePeriods={setConservatePeriods}
                selectedPeriods={selectedPeriods}
                overwritePeriods={overwritePeriods}
                setOverwritePeriods={setOverwritePeriods}
                configSelectedOption={configurationSelectedOption?.value}
              />
            )}
          </>
        )}
        {tabValue === "CONFIGURACIONES GUARDADAS" && (
          <Stack sx={{ width: "100%", minHeight: "450px" }}>
            <CalculationConfigs
              dataConfigs={dataConfigs}
              refetchConfigs={getConfigs}
              dataFlow={store.flow}
            />
            {delayHelper && (
              <Stack sx={{ width: "100%" }}>
                <InputLabel sx={{ ...form_label, marginBottom: "5px" }}>
                  Elija la configuración
                </InputLabel>
                <Select
                  reference={configurationRef}
                  styles={selectStyles(configurationSelectedOption)}
                  options={formatOptions(dataConfigs)}
                  onChange={handleChangeConfigSelectedOption}
                  closeMenuOnSelect
                  placeholder="Seleccionar configuración"
                  defaultValue={configurationSelectedOption}
                  isClearable
                />
              </Stack>
            )}

            {configurationSelectedOption &&
              (dataConfigs?.find(
                (config: any) =>
                  config.id === configurationSelectedOption?.value
              )?.template_phi ? (
                <PhiTemplateConfigurationTab
                  formatSelectRef={formatSelectRef}
                  columnSelectRef={columnSelectRef}
                  columns={columns}
                  formatSelectedOption={formatSelectedOption}
                  formatOptionsChangeHandler={formatOptionsChangeHandler}
                  columnSelectedOption={columnSelectedOption}
                  columnOptionsChangeHandler={columnOptionsChangeHandler}
                  validateAccept={validateAccept}
                  templatePhiNameRef={templatePhiNameRef}
                  templatePhiNameSelectedOption={templatePhiNameSelectedOption}
                  templatePhiNameChangeHandler={templatePhiNameChangeHandler}
                  monthSelectRef={monthSelectRef}
                  monthChangeHandler={monthChangeHandler}
                  monthSelectedOption={monthSelectedOption}
                  yearSelectRef={yearSelectRef}
                  yearSelectedOption={yearSelectedOption}
                  yearChangeHandler={yearChangeHandler}
                  addPeriod={addPeriod}
                  deletePeriod={deletePeriod}
                  assignPeriods={assignPeriods}
                  setAssignPeriods={setAssignPeriods}
                  conservatePeriods={conservatePeriods}
                  setConservatePeriods={setConservatePeriods}
                  selectedPeriods={selectedPeriods}
                  overwritePeriods={overwritePeriods}
                  setOverwritePeriods={setOverwritePeriods}
                  configSelectedOption={configurationSelectedOption?.value}
                />
              ) : (
                <NewConfigurationGeneralTab
                  formatSelectRef={formatSelectRef}
                  columnSelectRef={columnSelectRef}
                  desdinamizeFile={desdinamizeFile}
                  setDesdinamizeFile={setDesdinamizeFile}
                  fileName={fileName}
                  setFileName={setFileName}
                  columns={columns}
                  formatSelectedOption={formatSelectedOption}
                  formatOptionsChangeHandler={formatOptionsChangeHandler}
                  columnSelectedOption={columnSelectedOption}
                  columnOptionsChangeHandler={columnOptionsChangeHandler}
                  handleChangeSelectedFolder={handleChangeSelectedFolder}
                  validateAccept={validateAccept}
                  selectedFolder={selectedFolder}
                />
              ))}
          </Stack>
        )}
        <Stack
          direction="row"
          sx={{
            marginTop: "auto",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Button
            title="Cancelar"
            type="button"
            onClick={closeAndClean}
            color="light-grey"
          />
          {tabValue === "NUEVA CONFIGURACIÓN" && (
            <Button
              title="Guardar"
              type="button"
              onClick={() => saveCalculate()}
              disabled={validateAccept().result}
              color="blue-greeny"
            />
          )}

          <Button
            title="Guardar cálculo y configuración"
            type="button"
            onClick={
              tabValue === "NUEVA CONFIGURACIÓN"
                ? toggleOpenSaveConfigModal
                : handleAcceptSaveConfig
            }
            disabled={validateAccept().result}
            color="blue"
            width={240}
          />
        </Stack>
        <SaveNameModal
          open={openSaveConfigModal}
          handleClose={toggleOpenSaveConfigModal}
          handleAccept={handleAcceptSaveConfig}
          title={"Guardar configuración de cálculo"}
          text={"Nombre de la nueva configuración"}
          label={"Nombre de la nueva configuración"}
          placeholder={"Nombre de la nueva configuración"}
          dispatchFunction={setEditName}
          valueState={editName}
          namesList={dataConfigs}
        />
      </Stack>
    </Dialog>
  );
};

export default FileFormatModal;
