import Info from "../icons/Info";
interface InputLabelProps {
  children: React.ReactNode;
  title: string | React.ReactNode;
  width?: number | string;
  hasTooltip?: boolean;
  tooltipText?: string;
  tooltipColor?: "blue" | "white" | undefined;
  disabled?: boolean;
  transparent?: boolean;
  minWidth?: number | string;
  thinner?: boolean;
}

const InputLabel = ({
  children,
  title,
  width,
  hasTooltip,
  tooltipText,
  tooltipColor,
  disabled,
  transparent,
  minWidth,
  thinner
}: InputLabelProps) => {
  return (
    <div style={{ width: width || "100%", minHeight: thinner? "72px" : "80px",  display: "flex", flexDirection: "column", justifyContent: "space-between", minWidth: minWidth }}>
      <div
        className="cc_filter_condition"
        style={{
          backgroundColor: disabled? "#9A999E" : (transparent? "transparent" : "#32648e"),
          color: "white",
          fontWeight: "bold",
          padding: thinner ? "3px 0" : "6px 0",
          borderRadius: "5px",
        }}
      >
        {title}
        {hasTooltip && (
          <Info text={tooltipText ? tooltipText : ""} color={tooltipColor} />
        )}
      </div>
      {children}
    </div>
  );
};

export default InputLabel;
