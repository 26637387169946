import {  useEffect, useRef, useState } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts.js";
import CustomSkeleton from "../../skeleton/CustomSkeleton";
import { FLOW_ITEMS, GET_FLOW_URL, NEW_VIEW_RESULTS_URL, } from "../../../api/axios";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import SimpleTable from "../../tablesTools/SimpleTable";
import BaseModal from "./BaseModal";
import useDialog from "../../../hooks/useDialog";
import { modal_typography_title_styles } from "../../../styles/app-styles";
import { useStore } from "../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
import { getBackendId } from "../../../utils/util";
import { checkIfNodeHasPendingEdges, setErrorFlowNodes, setInitialFlowNodes } from "../utils";
interface SetHeaderProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  id: string;
  backendId?: any;
  parentIds?: any[];
  sourceItemId: string;
  nodeData?: any

}
const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
  setInitialNodes: store.setInitialNodes,
  updateIsFlowLoading: store.updateIsFlowLoading
});

const SetHeader = ({
  open,
  handleClose,
  dataItem,
  id,
  backendId,
  sourceItemId,
  nodeData
}: SetHeaderProps) => {

  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [inputValueRangeTo, setInputValueRangeTo] = useState<
    number | string | undefined
  >(undefined);
  const [tableDataState, setTableDataState] = useState<any>(undefined);
  const store = useStore(selector, shallow);
  const nodesRef = useRef<any[]>([]);
  nodesRef.current = store.nodes;
  const [comment, setComment] = useState<any>("");

  /********************************* CARGA DE DATOS ***********************************/

  const onSuccesGetData = (data: any) => {
    setTableDataState(data);
  };

  useEffect(() => {
    if (dataItem && open) {
      setInputValueRangeTo(dataItem.detalle_item.indice);
    }
  }, [dataItem, open]);

  useEffect(() => {
    if (open) {
      getTableData();
    }
  }, [open]);


  const {
    data: tableData,
    isLoading: isLoadingTableData,
    callApi: getTableData,
  } = useApi(
    NEW_VIEW_RESULTS_URL(
      store.flow.id,
      dataItem ? backendId : (getBackendId(sourceItemId, store.nodes) as number)
    ),
    "GET",
    texts.flows.viewResults.codes,
    undefined,
    onSuccesGetData,
    undefined,
    false
  );
  /********************************* VALIDACIÓN DE DATOS ******************************/

  const validateAccept = () => {
    return inputValueRangeTo === undefined || inputValueRangeTo === "";
  };

  /******************************* POSTEAR ITEM *********************************************/

  
  const onSuccessGetFlow = (data: any) => {
    store.setInitialNodes(setErrorFlowNodes(nodesRef.current , data.items));
  };


  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  useEffect(() => {
    store.updateIsFlowLoading(isLoadingFlow)
  }, [isLoadingFlow]);

  const saveSetHeader = () => {
    const completedInfo = {
      tipo: "setear_cabecera",
      indice: inputValueRangeTo,
      calcular: true,
      editar_detalle_item: true,
      parent_ids: [getBackendId(sourceItemId, store.nodes)],
      comentario: comment,
      id: backendId ? backendId : undefined,
      ...nodeData.position

    };
    toggleConfirmationModal();
    if (dataItem) {
      if (inputValueRangeTo?.toString() !== dataItem.detalle_item.indice.toString()) {
        putSetHeader(undefined, [completedInfo]);
      }
    } else {
      postSetHeader(undefined, completedInfo);
    }
    cleanAndClose();
  };

  // Actualizamos el nodo insertandole el id del backend que es el que vamos a utilizar
  // cuando enviemos datos al back
  const onSuccessPostItem = (data: any) => {
    store.updateNode(id, {
      indice: inputValueRangeTo,
      backendId: backendId ? backendId : data.id,
      error: data.posee_error,
      errorMessage: data.mensaje_error,      comentario: comment,
    });
    checkIfNodeHasPendingEdges(id, putConnection, data.id)
    getFlow(GET_FLOW_URL(parseInt(store.flow.id)));
  };

  const {
    isLoading: isLoadingPostSetHeader,
    callApi: postSetHeader,
    error: errorPostSetHeader,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutSetHeader,
    callApi: putSetHeader,
    error: errorPutSetHeader,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  
  const {
    isLoading: isLoadingPutConnection,
    callApi: putConnection,
    error: errorPutConnection,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );


  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    setInputValueRangeTo(undefined);
    setTableDataState(undefined);
    handleClose();
    setComment("")
  };

  return (
    <BaseModal
      open={open}
      title="Setear cabecera"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={saveSetHeader}
      confirmationModalMessage={"setear la cabecera"}
      disabledAcceptButton={validateAccept()}
      width="1300px"
    >
      <SimpleBackdrop
        open={isLoadingTableData}
        message={texts.flows.getFilterData.loading}
      />
      <Stack
        justifyContent={"space-between"}
        sx={{
          height: "480px",
        }}
      >
        {!dataItem &&   <TextField
          id="comment"
          label={"Comentario"}
          placeholder={"Comentario"}
          size="small"
          multiline
          variant="outlined"
          fullWidth
          value={comment}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setComment(event.target.value);
          }}
          FormHelperTextProps={{
            sx: {
              color: "var(--magenta)",
            },
          }}
          required
        />}
          
        <Stack direction={"row"} gap={8}>
          <Stack
            direction="row"
            sx={{
              alignItems: "flex-end",
              width: "100%",
              marginTop:"10px"
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  p: 0,
                  m: 0,
                  color: "var(--blue)",
                }}
              >
                Índice de la cabecera
              </Typography>

              <TextField
                type="number"
                id="rangeToRows"
                label={""}
                placeholder={"Índice de la cabecera"}
                size="small"
                multiline
                variant="outlined"
                fullWidth
                value={inputValueRangeTo}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const regex = /^[0-9\b]+$/;
                  if (
                    event.target.value === "" ||
                    regex.test(event.target.value)
                  ) {
                    setInputValueRangeTo(event.target.value);
                  }
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
              />
            </Box>
          </Stack>
        </Stack>

        <Typography sx={modal_typography_title_styles}>
          Se muestran las primeras y las últimas 500 filas del archivo
        </Typography>
        {open === false ? (
          <Box sx={{ width: "100%" }}>
            <CustomSkeleton height={5} />
          </Box>
        ) : (
          tableDataState && (
            <SimpleTable
              columns={
                transformDataSimpleTable(
                  tableDataState,
                  undefined,
                  undefined,
                  true
                ).columns
              }
              rows={
                transformDataSimpleTable(
                  tableDataState,
                  undefined,
                  undefined,
                  true
                ).rows
              }
              toolbar={false}
            />
          )
        )}
      </Stack>
    </BaseModal>
  );
};

export default SetHeader;
