import { Box, InputLabel, Stack, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../hooks/useApi";
import { texts } from "../../../texts.js";
import Select from "../../forms/Select";
import { selectStyles } from "../../../styles/select.styles";
import {
  form_label,
  modal_selects_container_styles,
} from "../../../styles/app-styles";
import { FLOW_ITEMS, GET_FLOW_URL } from "../../../api/axios";
import useMultiSelect from "../../../hooks/useMultiSelect";
import {
  checkConcatConfigAndDeleteOldParents,
  checkIfNodeHasPendingEdges,
  formatConcatAndMergeOptions,
  formatConcatOptionsFromBackend,
  isFileNameValid,
  setErrorFlowNodes,
} from "../utils";
import useDialog from "../../../hooks/useDialog";
import ShadowBox from "./ShadowBox";
import BaseModal from "./BaseModal";
import { useStore } from "../FlowDiagram/store/store";
import { shallow } from "zustand/shallow";
interface ConcatProps {
  open: boolean;
  handleClose: () => void;
  dataItem?: any;
  id: string;
  backendId?: any;
  parentIds?: any[];
  nodeData?: any;
}
const selector = (store: any) => ({
  updateNode: store.updateNode,
  flow: store.flow,
  nodes: store.nodes,
  edges: store.edges,
  setInitialNodes: store.setInitialNodes,
  setInitialEdges: store.setInitialEdges,
  updateIsFlowLoading: store.updateIsFlowLoading,
  onEdgesChange: store.onEdgesChange
});
const Concat = ({
  open,
  handleClose,
  id,
  backendId,
  parentIds,
  dataItem,
  nodeData,
}: ConcatProps) => {
  const [name, setName] = useState<any>("");
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const stepsToConcatSelectRef: any = useRef(null);
  const [delayHelper, setDelayHelper] = useState<boolean>(false);
  const store = useStore(selector, shallow);
  const nodesRef = useRef<any[]>([]);
  nodesRef.current = store.nodes;

  useEffect(() => {
    if (dataItem && open) {
      setName(dataItem.comentario);
      setstepsToConcatSelectedOption(
        formatConcatOptionsFromBackend(dataItem.parent_ids, store.nodes)
      );
    }
  }, [dataItem, open]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setDelayHelper(true);
      }, 2);
    }
  }, [open]);

  const [
    stepsToConcatSelectedOption,
    setstepsToConcatSelectedOption,
    stepsToConcatChangeHandler,
  ] = useMultiSelect();

  /*************************** POST Y PUT STEPPER Y ITEM ***************************/
  const onSuccessGetFlow = (data: any) => {
    store.setInitialNodes(setErrorFlowNodes(nodesRef.current , data.items));
  };

  const { isLoading: isLoadingFlow, callApi: getFlow } = useApi(
    undefined,
    "GET",
    texts.flows.getFlow.codes,
    undefined,
    onSuccessGetFlow,
    undefined,
    false
  );

  
  useEffect(() => {
    store.updateIsFlowLoading(isLoadingFlow)
  }, [isLoadingFlow]);

  // Actualizamos el nodo insertandole el id del backend que es el que vamos a utilizar
  // cuando enviemos datos al back
  const onSuccessPostItem = (data: any) => {
    store.updateNode(id, {
      tipo: "concat",
      comentario: name,
      parentBackendIds: stepsToConcatSelectedOption?.map((column: any) => {
        return column.value;
      }),
      calcular: true,
      backendId: backendId ? backendId : data.id,
      error: data.posee_error,
      errorMessage: data.mensaje_error,
    });
    checkIfNodeHasPendingEdges(id, putConnection, data.id)
    checkConcatConfigAndDeleteOldParents(store.edges, id, data.id, stepsToConcatSelectedOption?.map((column: any) => {
      return column.value;
    }), store.onEdgesChange)
    getFlow(GET_FLOW_URL(parseInt(store.flow.id)));
  };

  const {
    isLoading: isLoadingPostConcat,
    callApi: postConcat,
    error: errorPostConcat,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "POST",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutConcat,
    callApi: putConcat,
    error: errorPutConcat,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    onSuccessPostItem,
    undefined,
    false
  );

  const saveConcat = () => {
    const completedInfo = {
      tipo: "concat",
      calcular: true,
      editar_detalle_item: true,
      cambiar_conexiones: true,
      parent_ids: stepsToConcatSelectedOption?.map((column: any) => {
        return column.value;
      }),
      id: backendId ? backendId : undefined,
      ...nodeData.position,
      comentario: name,
    };
    if (dataItem) {
      putConcat(undefined, [completedInfo]);
    } else {
      postConcat(undefined, completedInfo);
    }
    cleanAndClose();
  };

  const {
    isLoading: isLoadingPutConnection,
    callApi: putConnection,
    error: errorPutConnection,
  } = useApi(
    FLOW_ITEMS(store.flow.id),
    "PUT",
    texts.adminPipeline.sendFileUrl.codes,
    undefined,
    undefined,
    undefined,
    false
  );

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    handleClose();
    setName("");
    setDelayHelper(false);
    setstepsToConcatSelectedOption(undefined);
    if (openConfirmationModal) {
      toggleConfirmationModal();
    }
  };

  return (
    <>
      {
        <BaseModal
          open={open}
          title="Concat (es necesario unir al menos dos items para configurarlo)"
          cleanAndClose={cleanAndClose}
          openConfirmationModal={openConfirmationModal}
          toggleConfirmationModal={toggleConfirmationModal}
          handleAccept={saveConcat}
          confirmationModalMessage={
            !dataItem ? "agregar la concatenación" : "editar la concatenación"
          }
          disabledAcceptButton={
            !stepsToConcatSelectedOption ||
            stepsToConcatSelectedOption?.length < 2 ||
            stepsToConcatSelectedOption === undefined ||
            !isFileNameValid(name)
          }
          height="400px"
        >
          {/**    <SimpleBackdrop
            open={isLoadingPostStepper}
            message={texts.flows.postItem.loading}
          />*/}
          {!dataItem && (
            <ShadowBox margin>
              <TextField
                id="fontName"
                label={"Comentario"}
                placeholder={"Comentario"}
                size="small"
                multiline
                variant="outlined"
                error={!isFileNameValid(name) || name === ""}
                fullWidth
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setName(event.target.value);
                }}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--magenta)",
                  },
                }}
                required
              />
            </ShadowBox>
          )}

          <Stack sx={modal_selects_container_styles}>
            {delayHelper && (
              <ShadowBox margin>
                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel sx={form_label}>
                    Selecciona los pasos a concatenar
                  </InputLabel>
                  <Select
                    isMulti
                    reference={stepsToConcatSelectRef}
                    styles={selectStyles(stepsToConcatSelectedOption)}
                    options={formatConcatAndMergeOptions(
                      parentIds,
                      store.nodes
                    )}
                    name="Columnas a incluir"
                    onChange={stepsToConcatChangeHandler}
                    closeMenuOnSelect={false}
                    placeholder="Seleccionar pasos a concatenar"
                    isClearable
                    defaultValue={stepsToConcatSelectedOption}
                    isDisabled={false}
                  />
                </Box>
              </ShadowBox>
            )}
          </Stack>
        </BaseModal>
      }
    </>
  );
};

export default Concat;
